import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import useAsyncStatus from '../hooks/useAsyncStatus';

export default function Confirm({ open, onClose, message, title, onOk, successMessage }) {
  const execWithFeedback = useAsyncStatus();
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" disableEscapeKeyDown>
      <DialogTitle>{title ?? 'CONFIRM'}</DialogTitle>
      <DialogContent>
        <Box mt={4}>{message}</Box>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2} justifyContent="center" width="100%">
          <Button
            variant="contained"
            onClick={() => {
              execWithFeedback(
                async () => {
                  onClose();
                  await onOk();
                },
                successMessage
              );
            }}
          >
            OK
          </Button>
          <Button variant="contained" color="error" onClick={onClose}>
            CANCEL
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export function useConfirm() {
  const [state, setState] = React.useState({ open: false });
  const onClose = () => {
    setState({ open: false });
  };
  return {
    confirmProps: state,
    openConfirm: (message, onOk, successMessage) => {
      setState({ message, onOk, open: true, onClose, successMessage });
    },
  };
}
