import React from 'react';
import {Box, Stack, Typography} from '@mui/material';
import * as yup from 'yup';
import {DateRange, useDateRange} from '../components/DateRange';
import useForm from '../hooks/useForm';
import InputField, {CompanySelect, CustomerSearchField, StandaloneInputField} from './InputField';
import FormButtons from './FormButtons';
import ClearFieldsButton from './ClearFieldsButton';
import {createTags, devLog, formatDate, formatMDRange, handleSelectCustomer, setStoreDoc} from '../utils/utils';
import useStoreId from '../hooks/useStoreId';
import DateField from './DateField';
import useRoomTypeList from '../hooks/useRoomTypeList';
import useRoomList from '../hooks/useRoomList';
import useRoomRateCalculator from '../hooks/useRoomRateCalculator';
import {depositOptions, rateTypeOptions, sexOptions} from '../utils/options';
import {useAllBookingsWithRoomNoExceptBookingId} from '../hooks/useBookingList';
import {DeleteButton} from '../components/icon-buttons';
import useCheckInList from '../hooks/useCheckInList';
import {subDays} from 'date-fns';
import { from } from 'stylis';
import Alert, { useAlert } from '../components/Alert';
import useRVRoomList from '../hooks/useRVRoomList';
import useVacantRoomList from '../hooks/useVacantRoomList';

const bookingFormSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email(),
  // checkIn: yup.number().required(),
  // checkOut: yup.number().required(),
  // adults: yup.number().integer().positive(),
  // children: yup.number().integer().positive(),
  // infants: yup.number().integer().positive(),
  // queenCount: yup.number().integer().positive(),
  // singleCount: yup.number().integer().positive(),
});

const defBookingData = {
  id: '',
  bookingId: '',
  customerId: '',
  companyId: '',
  company: '',
  name: '',
  email: '',
  phoneNo: '',
  sex: '',
  dob: '',
  city: '',
  adults: '',
  checkIn: '',
  checkOut: '',
  children: '',
  infants: '',
  licenseNo: '',
  passportNo: '',
  otherIdNo: '',
  plateNo: '',
  carInfo: '',
  carColor: '',
  note: '',
  roomNo: '',
  roomType: '',
  roomRate: '',
  roomRateOrg: '',
  roomRateDaily: '',
  totalDays: '',
  rateType: '',
  addPetFee: '',
  addLaundryFee: '',
  addRVFee: '',
  paymentType: '',
  cardNo: '',
  cardHolder: '',
  cardExp: '',
  cardCvv: '',
  paymentStatus: 'pending',
  amountPaid: '',
  paymentNote: '',
  source: 'on-site',
  bookingType: '', // 'group' / 'single'
  queenCount: '',
  singleCount: '',
  roomCount: '',
  bedType: '', // 'single' | 'queen'
  countCheckedIn: 0,
  status: 'pending', // pending / done / cancelled
  groupRooms: '',
};

const BookingForm = ({editData, editMode, onCloseDialog, isGroupBooking}) => {
  if (editMode) {
    isGroupBooking = editData.bookingType === 'group';
  }
  const [groupRooms, setGroupRooms] = React.useState(editData?.groupRooms ?? {});
  const storeId = useStoreId();
  const {checkIn, checkOut} = editData ?? {};
  const [roomTypes] = useRoomTypeList();
  const [rooms = []] = useRoomList();
  const [rvRooms = []] = useRVRoomList();
  const [vacantRooms = []] = useVacantRoomList();
  const bookingIdRef = React.useRef();
  const [bookingsWithRoomNo] = useAllBookingsWithRoomNoExceptBookingId(bookingIdRef.current);
  const [checkIns = []] = useCheckInList();
  const [enteredRooms, setEnteredRooms] = React.useState([]);

  const dateRangeProps = useDateRange([checkIn ? new Date(checkIn) : null, checkOut ? new Date(checkOut) : null]);
  const {alertProps, openAlert} = useAlert();
  
  const formik = useForm({
    editData: {...defBookingData, ...editData},
    editMode,
    validationSchema: bookingFormSchema,
    onSubmit: async (values) => {
      const [from, to] = dateRangeProps.dateRange;
      if (!(from && to)) {
        throw new Error(`Please check in and check out date!`);
      }
      const roomCnt = roomCount || 1;
      for(let i=0; i<roomCnt; i++) {
        const roomNumber = roomCount ? groupRooms?.[i][1] : roomNo;
        const res = checkDoubleBooking(openAlert, dateRangeProps.dateRange, checkIns, bookingsWithRoomNo, roomNumber);
        if (!res) {
          throw new Error(`Cannot add / edit!`);
        }
      }
      // devLog('BookingForm.onSubmit()', values);
      const tags = createTags(values);
      const {id, bookingId, ...data} = {...values, checkIn: from.getTime(), checkOut: to.getTime(), tags};
      const {company, companyId} = data;
      data['bookingType'] = isGroupBooking ? 'group' : 'single';
      data['groupRooms'] = groupRooms;
      
      if (isGroupBooking) {
        const groupCheckInFlags = {};
        for (const key of Object.keys(groupRooms)) {
          groupCheckInFlags[key] = false;
        }
        data['groupCheckInFlags'] = groupCheckInFlags;
      }
      if (!(company && companyId)) {
        data['companyData'] = {};
      }
      console.log('onSubmit: ', storeId, data, id, bookingId,);
      await setStoreDoc(storeId, 'bookings', data, id ?? bookingId);
      if (editMode) {
        onCloseDialog?.();
      }
      formik.resetForm();
    },
    onReset: () => {
      dateRangeProps.setDateRange([checkIn ? new Date(checkIn) : null, checkOut ? new Date(checkOut) : null]);
    },
    onBeforeSubmit: () => {
      if (isGroupBooking) {
        const roomNoList = Object.entries(groupRooms).map(([, [, roomNo]]) => roomNo);
        const roomNoCount = roomNoList.length;
        const roomNoSet = new Set(roomNoList);
        if (roomNoCount !== roomNoSet.size) {
          alert('Please select the different room!');
          return false;
        }
      }
      return true;
    },
    message: 'Booking information has been saved successfully.',
  });
  const {values: {roomType, roomNo, id, roomCount}} = formik;
  bookingIdRef.current = id;
  const roomOptions = useRoomOptions(dateRangeProps.dateRange, roomType, rooms, bookingsWithRoomNo, checkIns);
  const vacnatRoomNos = useVacantRoomNos(vacantRooms);
  const rvRoomOptions = useRVRoomOptions(rvRooms, vacnatRoomNos);
  useRoomRateCalculator(editMode, dateRangeProps, formik, roomTypes);
  function onCompanySelect(id, company) {
    if (id === 'N/A' || !id) {
      formik.setValues({
        ...formik.values,
        companyId: '',
        company: '',
        companyData: {},
      });
    } else {
      formik.setValues({
        ...formik.values,
        companyId: id,
        company: company.company,
        companyData: company,
      });
    }
  }
  React.useEffect(() => {
    const countRooms = [];
    for (let i = 0; i < parseInt(roomCount); ++i) {
      countRooms.push(i);
    }
    setEnteredRooms(countRooms);
  }, [roomCount]);
  if (isGroupBooking) {
    // 그룹 부킹에서 개별 부킹룸 삭제
    function removeRoom(index, removedValue) {
      let resGrouprooms = [];
      const resRooms = enteredRooms.filter((data) => {
        if (data !== index) {
          resGrouprooms.push(groupRooms?.[data]);
        }
        return (data !== index)
      });
      setEnteredRooms(resRooms);
      setGroupRooms(resGrouprooms);
      formik.setFieldValue('roomCount', roomCount - 1);
    }
    function onChange(index, roomType, roomNo) {
      setGroupRooms({
        ...groupRooms,
        [index]: [roomType, roomNo],
      });
    }
    return (
      <>
      <Alert {...alertProps} />
      <form onSubmit={formik.handleSubmit}>
        <Stack direction="column" spacing={2} pt={2}>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="flex-start">
            <InputField name="name" label="Name" formik={formik} />
            <InputField name="email" label="Email" formik={formik} />
            <InputField name="phoneNo" label="Phone No." formik={formik} />
            <ClearFieldsButton formik={formik} fields={['name', 'email', 'phoneNo']} />
          </Stack>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="flex-start">
            <CompanySelect initialId={formik.values.companyId} onChange={onCompanySelect} />
            <ClearFieldsButton formik={formik} fields={['company', 'companyId']} />
          </Stack>
          <DateRange labels={['Check In', 'Check Out']} size="small" width={200} showClearButton {...dateRangeProps} />
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="flex-start">
            <InputField name="adults" label="Adults" formik={formik} />
            <InputField name="children" label="Children" formik={formik} />
            <InputField name="infants" label="Infants" formik={formik} />
            <InputField name="roomCount" label="Rooms" formik={formik} />
            <ClearFieldsButton formik={formik} fields={['adults', 'children', 'infants', 'roomCount']} />
          </Stack>
          {enteredRooms.map((index) => {
            return (
              <RoomInputGroup
                key={index}
                index={index}
                dateRange={dateRangeProps.dateRange}
                roomTypes={roomTypes}
                rooms={rooms}
                bookingsWithRoomNo={bookingsWithRoomNo}
                onChange={onChange}
                initialValue={groupRooms?.[index]}
                checkIns={checkIns}
                removeRoom={removeRoom}
                rvRoomOptions={rvRoomOptions}
              />
            );
          })}
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="flex-start">
            <InputField name="note" label="Note" formik={formik} />
            <ClearFieldsButton formik={formik} fields={['note']} />
          </Stack>
        </Stack>
        <FormButtons formik={formik} label={'GROUP BOOKING'} editMode={editMode} onCloseDialog={onCloseDialog} />
      </form>
      </>
    );
  } else {
    return (
      <>
      <Alert {...alertProps} />
      <form onSubmit={formik.handleSubmit}>
        <Stack direction="column" spacing={2} pt={2}>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="flex-start">
            <CustomerSearchField name="name" label="Name" formik={formik} onSelectCustomer={(row) => handleSelectCustomer(formik, row)} />
            <InputField name="email" label="Email" formik={formik} />
            <InputField name="phoneNo" label="Phone No." formik={formik} />
            <ClearFieldsButton formik={formik} fields={['name', 'email', 'phoneNo']} />
          </Stack>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="flex-start">
            <CompanySelect initialId={formik.values.companyId} onChange={onCompanySelect} />
            <ClearFieldsButton formik={formik} fields={['company', 'companyId']} />
          </Stack>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="flex-start">
            <InputField name="sex" label="Sex" formik={formik} options={sexOptions} />
            <DateField name="dob" label="DOB" formik={formik} initialDate={formik.values['dob']} onChange={(date) => formik.setFieldValue('dob', formatDate(date))} />
            <InputField name="city" label="City" formik={formik} />
            <ClearFieldsButton formik={formik} fields={['sex', 'dob', 'city']} />
          </Stack>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="flex-start">
            <InputField name="licenseNo" label="Driver's License No." formik={formik} />
            <InputField name="passportNo" label="Passport No." formik={formik} />
            <InputField name="otherIdNo" label="Other ID No." formik={formik} />
            <ClearFieldsButton formik={formik} fields={['licenseNo', 'passportNo', 'otherIdNo']} />
          </Stack>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="flex-start">
            <InputField name="carInfo" label="Car Info" formik={formik} />
            <InputField name="carColor" label="Car Color" formik={formik} />
            <InputField name="plateNo" label="Plate No." formik={formik} />
            <ClearFieldsButton formik={formik} fields={['carInfo', 'carColor', 'plateNo']} />
          </Stack>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="flex-start">
            <InputField name="note" label="Note" formik={formik} />
            <ClearFieldsButton formik={formik} fields={['note']} />
          </Stack>
          <DateRange labels={['Check In', 'Check Out']} size="small" width={200} showClearButton {...dateRangeProps} />
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="flex-start">
            <InputField name="adults" label="Adults" formik={formik} />
            <InputField name="children" label="Children" formik={formik} />
            <InputField name="infants" label="Infants" formik={formik} />
            <ClearFieldsButton formik={formik} fields={['adults', 'children', 'infants']} />
          </Stack>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="flex-start">
            <InputField name="roomType" label="Room Type" formik={formik} options={roomTypes} />
            <InputField name="roomNo" label="Room No." formik={formik} options={roomType === 'RV' ? rvRoomOptions : roomOptions} readOnly={true} disabled={!(roomOptions && roomOptions.length > 0)} />
            <InputField name="rateType" label="Rate Type" formik={formik} width={'150px'} options={rateTypeOptions} />
            <InputField name="roomRate" label="Room Rate" formik={formik} width={'150px'} />
            <ClearFieldsButton formik={formik} fields={editMode ? [] : ['roomType', 'roomNo', 'rateType', 'roomRate']} />
          </Stack>
          {/*<Stack direction={{ xs: 'column', md: 'row' }} spacing={4} alignItems="flex-start" mt={2} px={2}>*/}
          {/*  <InputField name="addPetFee" label="Pet" formik={formik} checkbox />*/}
          {/*  <InputField name="addLaundryFee" label="Laundry" formik={formik} checkbox />*/}
          {/*  <InputField name="addRVFee" label="RV" formik={formik} checkbox />*/}
          {/*</Stack>*/}
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="flex-start" mt={2}>
            <InputField name="paymentType" label="Deposit Type" formik={formik} options={depositOptions} />
            {/*<InputField name="paymentStatus" label="Payment Status" formik={formik} options={paymentStatusOptions} />*/}
            <InputField name="amountPaid" label="Deposit Amount" formik={formik} />
            <ClearFieldsButton formik={formik} fields={['paymentType', 'paymentStatus', 'amountPaid']} />
          </Stack>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="flex-start" mt={2}>
            <InputField name="cardNo" label="Card No." formik={formik} />
            <InputField name="cardHolder" label="Card Holder" formik={formik} />
            <InputField name="cardExp" label="Card Exp." formik={formik} width={100} />
            <InputField name="cardCvv" label="Card CVV" formik={formik} width={100} />
            <ClearFieldsButton formik={formik} fields={['cardNo', 'cardHolder', 'cardExp', 'cardCvv']} />
          </Stack>
          {/*<Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="flex-start">*/}
          {/*  <InputField name="paymentNote" label="Payment Note" formik={formik} />*/}
          {/*  <ClearFieldsButton formik={formik} fields={['note']} />*/}
          {/*</Stack>*/}
        </Stack>
        <FormButtons formik={formik} label={'BOOKING'} editMode={editMode} onCloseDialog={onCloseDialog} />
      </form>
      </>
    );
  }
};

function RoomInputGroup({index, dateRange, roomTypes, rooms, bookingsWithRoomNo, onChange, initialValue, checkIns, removeRoom, rvRoomOptions}) {
  const [roomType, setRoomType] = React.useState(initialValue?.[0] ?? '');
  const [roomNo, setRoomNo] = React.useState(initialValue?.[1] ?? '');
  const roomOptions = useRoomOptions(dateRange, roomType, rooms, bookingsWithRoomNo, checkIns);
  React.useEffect(() => {
    onChange(index, roomType, roomNo);
  }, [index, roomType, roomNo]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="flex-center">
      <Box sx={{flex: 0.5, display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
        <Typography color={'text.disabled'}>Room Info #{index + 1}</Typography>
      </Box>
      <StandaloneInputField flex={1} label={`Room Type #${index + 1}`} options={roomTypes} value={roomType} onChange={({target: {value}}) => {setRoomType(value)}} />
      <StandaloneInputField flex={1} label={`Room No. #${index + 1}`} disabled={!(roomOptions && roomOptions.length > 0)} options={roomType === 'RV' ? rvRoomOptions : roomOptions} value={roomNo} onChange={({target: {value}}) => {setRoomNo(value)}} readOnly={true} />
      <DeleteButton
        onClick={() => {
          removeRoom(index, initialValue);
        }}
      />
    </Stack>
  );
}

/**
 * @param {Date} from
 * @param {Date} to
 * @param {number} checkIn
 * @param {number} checkOut
 * @return {boolean}
 */
function isOverlapped(from, to, checkIn, checkOut) {
  const fromTS = from.getTime();
  const toTS = to.getTime();
  const checkInTS = checkIn;
  const checkOutTS = checkOut;
  return (fromTS >= checkInTS && fromTS < checkOutTS) || (toTS > checkInTS && toTS <= checkOutTS) || (fromTS <= checkInTS && toTS >= checkOutTS);
}

function isOccupied(from, to, roomNo, checkIns) {
  for (const {roomNo: bRoomNo, checkIn, checkOut} of checkIns) {
    if (bRoomNo === roomNo && isOverlapped(from, to, checkIn, checkOut)) {
      return formatMDRange(checkIn, checkOut);
    }
  }
  return false;
}

function isBooked(from, to, roomNo, bookingsWithRoomNo) {
  for (const {roomNo: bRoomNo, checkIn, checkOut, bookingType, roomCount, groupRooms} of bookingsWithRoomNo) {
    if (bookingType !== 'group') {
      if (bRoomNo === roomNo && isOverlapped(from, to, checkIn, checkOut)) {
        return formatMDRange(checkIn, checkOut);
      }
    } else {
      for (let i = 0; i < roomCount; ++i) {
        const [, bRoomNo] = groupRooms?.[i] ?? ['', ''];
        if (bRoomNo === roomNo && isOverlapped(from, to, checkIn, checkOut)) {
          return formatMDRange(checkIn, checkOut);
        }
      }
    }
  }
  return false;
}

export function useRoomOptions([from, to], roomType, rooms, bookingsWithRoomNo, checkIns) {
  return React.useMemo(() => {
    if (from && to && roomType && rooms.length > 0) {
      // devLog({from, to, roomType, rooms, bookingsWithRoomNo});
      return rooms
        .filter((room) => room.roomType === roomType)
        .map(({roomNo}) => {
          const occupiedRes = isOccupied(from, to, roomNo, checkIns);
          if (occupiedRes) {
            return {value: roomNo, label: `${roomNo} (${occupiedRes})`, disabled: true};
          } else {
            const bookedRes = isBooked(from, to, roomNo, bookingsWithRoomNo);
            if (bookedRes) {
              return {value: roomNo, label: `${roomNo} (${bookedRes})`, disabled: true};
            }
          }
          return {value: roomNo, label: roomNo};
        });
    }
    return [];
  }, [from, to, roomType, rooms, bookingsWithRoomNo, checkIns]);
}

export function useRVRoomOptions(rvRooms, vacantRoomNos = []) {
  return React.useMemo(() => {
    if (rvRooms?.length > 0) {
      return rvRooms?.map(({roomNo}) => {
        if (vacantRoomNos?.length > 0 && vacantRoomNos?.includes(roomNo)) {
          return {value: roomNo, label: roomNo};
        } else {
          return {value: roomNo, label: `${roomNo} Occupied`, disabled: true};
        }
      });
    }
    return [];
  }, [rvRooms, vacantRoomNos]);
}

export function useVacantRoomNos(vacantRooms) {
  return React.useMemo(() => {
    if (vacantRooms?.length > 0) {
      return vacantRooms?.map(({roomNo}) => roomNo);
    }
    return [];
  }, [vacantRooms]);
}

export const checkDoubleBooking = (openAlert, dateRange, checkIns, bookingsWithRoomNo, roomNumber) => {
  const [from, to] = dateRange;
  if (from && to) {
    const occupiedRes = isOccupied(from, to, roomNumber, checkIns);
    const bookedRes = isBooked(from, to, roomNumber, bookingsWithRoomNo);
    const result = bookedRes ? bookedRes : occupiedRes;
    if (occupiedRes || bookedRes) {
      const message = `The room [ ${roomNumber} (${result}) ] is booked. Please select the different room!`;
      const title = 'Double booking Alert!';
      openAlert(message, title);
      return false;
    }
  }
  return true;
};

export default BookingForm;
