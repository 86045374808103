import React from 'react';
import { useSnackbar } from 'notistack';

export default function useAsyncStatus() {
  const snackbar = useSnackbar();
  return React.useMemo(() => {
    const defaultMessage = {
      pending: 'Please wait until we finish the request.',
      success: 'The request has been processed successfully!',
      error: 'Something went wrong! Please try it later.',
    };
    /**
     * @param {Promise<any> | function():Promise<any>} promise
     * @param {({pending, success, error} | string)?} message
     */
    async function exec(promise, message) {
      const msg = { ...defaultMessage, ...(typeof message === 'string' ? {success: message} : message) };
      const key = snackbar.enqueueSnackbar(msg.pending, { variant: 'info', persist: true });
      try {
        const res = typeof promise === 'function' ? await promise() : await promise;
        snackbar.closeSnackbar(key);
        snackbar.enqueueSnackbar(msg.success, { variant: 'success' });
        return res;
      } catch (error) {
        console.error(error);
        snackbar.closeSnackbar(key);
        snackbar.enqueueSnackbar(msg.error, { variant: 'error' });
      }
      return null;
    }
    return exec;
  }, [snackbar]);
}
