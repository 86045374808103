import React from 'react';
import { Box, Button, Card, CircularProgress, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import PageContainer from '../../components/PageContainer';
import { useStoreCol } from '../../utils/fire-v8';
import { StandaloneDateField } from '../../forms/DateField';
import dfn from '../../utils/dfn';
import { getRoomsListOfType } from './HouseKeeping';
import useCheckInList from '../../hooks/useCheckInList';
import { NextButton, PrevButton } from '../../components/icon-buttons';
import { addDays, subDays } from 'date-fns';

export default function BookingStatus() {
  const theme = useTheme();
  const [date, setDate] = React.useState(new Date());
  const [bookingList, loading1] = useBookingList(date);
  const [roomList = [], loading2] = useStoreCol('rooms', colRef => colRef.orderBy('roomNo'));
  const [roomTypeList = [], loading3] = useStoreCol('room-types', colRef => colRef.orderBy('value'));
  const [checkInList, loading4] = useCheckInListOfDate(date);
  const isLessThanSm = useMediaQuery(theme.breakpoints.down('sm'));
  const roomsListOfType = getRoomsListOfType(roomTypeList ?? [], roomList ?? [], isLessThanSm ? 2 : 3);
  const loading = loading1 || loading2 || loading3 || loading4;
  const bookedRooms = [];
  const occupiedRooms = checkInList.map(({roomNo}) => roomNo);
  for (const {bookingType, roomCount, groupRooms, roomNo} of bookingList ?? []) {
    if (bookingType !== 'group') {
      if (roomNo) {
        bookedRooms.push(roomNo);
      }
    } else {
      for (let i = 0; i < roomCount; ++i) {
        const [, roomNo] = groupRooms?.[i] ?? ['', ''];
        if (roomNo) {
          bookedRooms.push(roomNo);
        }
      }
    }
  }
  return (
    <PageContainer title="Booking Status">
      <Grid container spacing={2}>
        <Grid item sm={12} md={6} lg={5}>
          <Stack direction={'row'} spacing={2}>
            <StandaloneDateField label={'Date'} value={date} onChange={value => setDate(value)} width={250} />
            <PrevButton onClick={() => setDate(subDays(date, 1))}/>
            <Button onClick={() => setDate(new Date())}>Today</Button>
            <NextButton onClick={() => setDate(addDays(date, 1))}/>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Stack flexWrap={'wrap'} direction={'row'} justifyContent={'flex-end'} spacing={2} mt={2} mb={1}>
            <Typography variant={'overline'} color={'info.main'}>Vacant</Typography>
            <Typography variant={'overline'} color={'success.main'}>Occupied</Typography>
            <Typography variant={'overline'} color={'warning.main'}>Booked</Typography>
          </Stack>
        </Grid>
        {roomsListOfType.map((roomsList, index) => {
          return (
            <Grid item xs={12} md={6} key={index}>
              <Card sx={{p: 2}}>
                {loading && (
                  <Stack alignItems={'center'} justifyContent={'center'} minHeight={300}>
                    <CircularProgress />
                  </Stack>
                )}
                {!loading && roomsList.map((rooms, index) => {
                  const buttonWidth = '150px';
                  return (
                    <Stack direction={'column'} key={index} sx={{mt: 1}}>
                      <Stack direction={'row'} justifyContent={'center'} flexWrap={'nowrap'} spacing={1}>
                        {rooms.map((room, index) => {
                          if (room.roomType === 'empty') {
                            return <Box key={`${room.roomType}-${index}`} sx={{width: buttonWidth, minWidth: buttonWidth, height: '80px'}} />;
                          }
                          const isOccupied = occupiedRooms.includes(room.roomNo);
                          const isBooked = bookedRooms.includes(room.roomNo);
                          const color = isOccupied ? 'success' : isBooked ? 'warning' : 'info';
                          const label = isOccupied ? 'Occupied' : isBooked ? 'Booked' : 'Vacant';
                          return (
                            <Button
                              key={room.roomNo}
                              variant={'contained'}
                              color={color}
                              sx={{width: buttonWidth, minWidth: buttonWidth, height: '80px'}}
                              onClick={() => {}}
                            >
                              {room.roomNo} ({room.roomType})
                              {<br/>}
                              {label}
                            </Button>
                          );
                        })}
                      </Stack>
                    </Stack>
                  );
                })}
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </PageContainer>
  );
}

function useBookingList(date) {
  const [rows = [], loading, error] = useStoreCol('bookings', colRef => colRef.where('status', '==', 'pending'));
  return React.useMemo(() => filterRows(rows, loading, error, date), [rows, loading, error, date]);
}

function useCheckInListOfDate(date) {
  const [rows = [], loading, error] = useCheckInList();
  return React.useMemo(() => filterRows(rows, loading, error, date), [rows, loading, error, date]);
}

function filterRows(rows, loading, error, date) {
  const fromTo = dfn.fromToOfDay(date);
  if (!fromTo) {
    return [[], false, undefined];
  }
  const [from, to] = fromTo;
  return [
    (rows??[]).filter(({checkIn, checkOut}) => (from >= checkIn && to <= checkOut)),
    loading,
    error
  ];
}
