import firebase from 'firebase/app';
import 'firebase/firestore';
import {
  addDays,
  addMonths,
  addWeeks, differenceInDays,
  differenceInMonths,
  differenceInWeeks,
  endOfDay,
  format, isEqual,
  startOfDay
} from 'date-fns';
import { roundAtTwoDecimal } from './utils';
import { GST_RATE, ROOM_TAX_RATE } from './options';

/**
 * @typedef {Date | number | string | firebase.firestore.Timestamp | null | undefined} DateLike
 */

class DateFn {
  /**
   * @return {firebase.firestore.FieldValue}
   */
  serverTS() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }
  /**
   * @return {firebase.firestore.Timestamp}
   */
  now() {
    return firebase.firestore.Timestamp.now();
  }
  /**
   * @param {DateLike} dateLike
   * @param {string} [formatStr = 'yyyy-MM-dd']
   * @return {string}
   */
  format(dateLike, formatStr = 'yyyy-MM-dd') {
    return format(this.toDate(dateLike, Date.now()), formatStr);
  }
  /**
   * @param {DateLike} dateLike
   * @return {string}
   */
  formatDT(dateLike) {
    return this.format(dateLike, 'yyyy-MM-dd hh:mm:dd');
  }
  /**
   * Returns formatted date string like 'Jan 1', 'Feb 13'
   * @param {DateLike} dateLike
   * @param {boolean} fullMonthLabel - format like 'January' if true, 'Jan' if not
   * @return {string}
   */
  formatMD(dateLike, fullMonthLabel) {
    return this.format(dateLike, `${fullMonthLabel ? 'LLLL' : 'LLL'} d`);
  }
  /**
   * @param {DateLike} from
   * @param {DateLike} to
   * @param {string} separator
   * @param {boolean} fullMonthLabel
   * @return {string}
   */
  formatMDRange(from, to, separator, fullMonthLabel) {
    return `${this.formatMD(from, fullMonthLabel)}${separator}${this.formatMD(to, fullMonthLabel)}`;
  }
  /**
   * @param {DateLike} dateLike
   * @return {[number, number] | null}
   */
  fromToOfDay(dateLike) {
    try {
      const date = this.toDate(dateLike);
      return [startOfDay(date).getTime(), endOfDay(date).getTime()];
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  startOf(dateLike, startType) {

  }
  endOf(dateLike, endType) {

  }
  static addFns = {
    day: addDays,
  };
  /**
   * @param {DateLike} dateLike
   * @param {number} count
   * @param {'day' | 'week' | 'month' | 'quarter' | 'year'} addType
   */
  add(dateLike, count, addType = 'day') {
    return DateFn.addFns[addType](this.toDate(dateLike), count);
  }
  sub(dateLike, addType) {

  }
  /**
   * @param {DateLike} dateLike
   * @param {Date | number} [defDate]
   * @return {Date}
   */
  toDate(dateLike, defDate) {
    if (!Boolean(dateLike)) {
      if (defDate !== undefined) {
        return defDate;
      }
      throw new Error(`Invalid date. (${dateLike})`);
    } else if (dateLike instanceof Date) {
      return dateLike;
    } else if (typeof dateLike === 'number') {
      return new Date(dateLike);
    } else if (dateLike instanceof firebase.firestore.Timestamp) {
      return dateLike.toDate();
    } else if (typeof dateLike === 'string') {
      const date = Date.parse(dateLike);
      if (!isNaN(date)) {
        return new Date(date);
      }
    }
    throw new Error(`Invalid date. (${dateLike})`);
  }

  /**
   * @typedef {{
   *   months: [number, Date, Date];
   *   weeks: [number, Date, Date];
   *   days: [number, Date, Date];
   * }} MonthWeekDayInfo
   */

  /**
   * @typedef {{
   *   dateStr: string;
   *   net: number;
   *   gst: number;
   *   roomTax: number;
   *   gross: number;
   * }} DailySaleDetail
   */

  /**
   * @typedef {{
   *   months: [number, Date, Date];
   *   weeks: [number, Date, Date];
   *   days: [number, Date, Date];
   *   dailySales: DailySaleDetail[];
   *   totalDays: number;
   *   totalFee: number;
   *   totalGST: number;
   *   totalRoomTax: number;
   *   totalNet: number;
   *   totalGross: number;
   *   totalFeeCalculated: number;
   *   totalNetCalculated: number;
   *   totalGSTCalculated: number;
   *   totalRoomTaxCalculated: number;
   *   totalGrossCalculated: number;
   *   totalMonthFee: number;
   *   totalWeekFee: number;
   *   totalDayFee: number;
   *   hint: string;
   *   rateType: 'Monthly' | 'Weekly' | 'Daily';
   *   noTax: boolean;
   * }} RoomRateInfo
   */

  /**
   * @param {Date} from
   * @param {Date} to
   * @param {number} monthlyFee
   * @param {number} weeklyFee
   * @param {number} dailyFee
   * @param {number} adultCharge
   * @param {boolean} noTax
   * @return {RoomRateInfo}
   */
  toMWDAndFeeDetails(from, to, monthlyFee, weeklyFee, dailyFee, adultCharge, noTax = false) {
    const res = this.toMWD(from, to);
    const {months: [months, mFrom, mTo], weeks: [weeks, wFrom, wTo], days: [days, dFrom, dTo]} = res;
    /** @type DailySaleDetail[] */
    const dailySales = [];
    const totalMonthFee = monthlyFee * months;
    const totalWeekFee = weeklyFee * weeks;
    const totalDayFee = (dailyFee + adultCharge) * days;
    const totalFee = totalMonthFee + totalWeekFee + totalDayFee;
    const totalDays = differenceInDays(to, from);
    let rateType;
    if (months > 0) {
      const monthlyDetails = this.getDetailsV2(mFrom, mTo, differenceInDays(mTo, mFrom), totalMonthFee, true);
      dailySales.push(...monthlyDetails);
      rateType = 'Monthly';
    }
    if (weeks > 0) {
      const weeklyDetails = this.getDetailsV2(wFrom, wTo, differenceInDays(wTo, wFrom), totalWeekFee, noTax);
      dailySales.push(...weeklyDetails);
      if (!rateType) {
        rateType = 'Weekly';
      }
    }
    if (days > 0) {
      const dailyDetails = this.getDetailsV2(dFrom, dTo, days, totalDayFee, noTax);
      dailySales.push(...dailyDetails);
      if (!rateType) {
        rateType = 'Daily';
      }
    }
    const totalNet = totalFee;
    const totalGST = roundAtTwoDecimal(totalFee * GST_RATE);
    const totalRoomTax = roundAtTwoDecimal(totalFee * ROOM_TAX_RATE);
    const totalGross = roundAtTwoDecimal(totalNet + totalGST + totalRoomTax);
    const totalFeeCalculated = roundAtTwoDecimal(dailySales.reduce((p, c) => p + c.net, 0));
    const totalNetCalculated = roundAtTwoDecimal(dailySales.reduce((p, c) => p + c.net, 0));
    const totalGSTCalculated = roundAtTwoDecimal(dailySales.reduce((p, c) => p + c.gst, 0));
    const totalRoomTaxCalculated = roundAtTwoDecimal(dailySales.reduce((p, c) => p + c.roomTax, 0));
    const totalGrossCalculated = roundAtTwoDecimal(dailySales.reduce((p, c) => p + c.gross, 0));
    return {
      ...res,
      dailySales,
      totalDays,
      totalFee,
      totalNet,
      totalGST,
      totalRoomTax,
      totalGross,
      totalFeeCalculated,
      totalNetCalculated,
      totalGSTCalculated,
      totalRoomTaxCalculated,
      totalGrossCalculated,
      totalMonthFee,
      totalWeekFee,
      totalDayFee,
      hint: this.getHint(res, totalMonthFee, totalWeekFee, totalDayFee, monthlyFee, weeklyFee, dailyFee, adultCharge),
      rateType,
      noTax: rateType === 'Monthly' ? true : noTax,
    };
  }
  /**
   * @param {MonthWeekDayInfo} mwdInfo
   * @param {number} totalMonthFee
   * @param {number} totalWeekFee
   * @param {number} totalDayFee
   * @param {number} monthlyFee
   * @param {number} weeklyFee
   * @param {number} dailyFee
   * @param {number} adultCharge
   * @return {string}
   * */
  getHint(mwdInfo, totalMonthFee, totalWeekFee, totalDayFee, monthlyFee, weeklyFee, dailyFee, adultCharge) {
    const hint = [];
    const fee = [];
    const totalFee = totalMonthFee + totalWeekFee + totalDayFee;
    const {months: [months], weeks: [weeks], days: [days]} = mwdInfo;
    if (months > 0) {
      hint.push(`${months} Month(s) * ${monthlyFee}`);
      fee.push(totalMonthFee);
    }
    if (weeks > 0) {
      hint.push(`${weeks} Week(s) * ${weeklyFee}`);
      fee.push(totalWeekFee);
    }
    if (days > 0) {
      const adultStr = adultCharge > 0 ? ` + ${adultCharge} (+${(adultCharge / 10).toFixed(0)} Adult(s))` : '';
      hint.push(`${days} Day(s) * ${dailyFee}${adultStr}`);
      fee.push(totalDayFee);
    }
    const feeStr = fee.length > 1 ? ` (${fee.join(' + ')})` : '';
    return `${hint.join(' + ')} = ${totalFee}${feeStr}`
  }
  /**
   * @param {Date} from
   * @param {Date} to
   * @param {number} days
   * @param {number} totalFee
   * @param {boolean} noTax
   * @return {DailySaleDetail[]}
   */
  getDetailsV2(from, to, days, totalFee, noTax) {
    /** @type DailySaleDetail[] */
    const res = [];
    let cur = from;
    const totalGST = noTax ? 0 : totalFee * GST_RATE;
    const totalRoomTax = noTax ? 0 : totalFee * ROOM_TAX_RATE;
    const totalGross = totalFee + totalGST + totalRoomTax;
    const dailyFee = roundAtTwoDecimal(totalFee / days);
    const dailyGST = roundAtTwoDecimal(totalGST / days);
    const dailyRoomTax = roundAtTwoDecimal(totalRoomTax / days);
    const dailyGross = roundAtTwoDecimal(totalGross / days);
    const remains = roundAtTwoDecimal(totalFee - dailyFee * days);
    const remainsGST = roundAtTwoDecimal(totalGST - dailyGST * days);
    const remainsRoomTax = roundAtTwoDecimal(totalRoomTax - dailyRoomTax * days);
    const remainsGross = roundAtTwoDecimal(totalGross - dailyGross * days);
    while(true) {
      const dateStr = this.format(cur);
      const net = dailyFee;
      const gst = dailyGST;
      const roomTax = dailyRoomTax;
      const gross = dailyGross;
      res.push({dateStr, net, gst, roomTax, gross});
      cur = addDays(cur, 1);
      if (isEqual(cur, to)) {
        break;
      }
    }
    if (res.length > 0) {
      const lastItem = res[res.length - 1];
      lastItem.net += remains;
      lastItem.gst += remainsGST;
      lastItem.roomTax += remainsRoomTax;
      lastItem.gross += remainsGross;
      lastItem.net = roundAtTwoDecimal(lastItem.net);
      lastItem.gst = roundAtTwoDecimal(lastItem.gst);
      lastItem.roomTax = roundAtTwoDecimal(lastItem.roomTax);
      lastItem.gross = roundAtTwoDecimal(lastItem.gross);
    }
    return res;
  }
  /**
   * @param {Date} from
   * @param {Date} to
   * @return {MonthWeekDayInfo}
   */
  toMWD(from, to) {
    from = startOfDay(from);
    to = startOfDay(to);
    const months = differenceInMonths(to, from);
    if (months > 0) {
      if (from.getDate() === to.getDate()) {
        // exact month only
        return {
          months: [months, from , to],
          weeks: [0, null, null],
          days: [0, null, null],
        };
      } else {
        const monthFrom = from;
        const monthTo = addMonths(from, months);
        const weekFrom = monthTo;
        const weeks = differenceInWeeks(to, weekFrom);
        if (weeks > 0) {
          if (weekFrom.getDay() === to.getDay()) {
            // months and exact weeks
            return {
              months: [months, monthFrom , monthTo],
              weeks: [weeks, weekFrom, to],
              days: [0, null, null],
            };
          } else {
            const weekTo = addWeeks(weekFrom, weeks);
            const dayFrom = weekTo;
            const days = differenceInDays(to, dayFrom);
            // months, weeks and days
            return {
              months: [months, monthFrom , monthTo],
              weeks: [weeks, weekFrom, weekTo],
              days: [days, dayFrom, to],
            };
          }
        } else {
          // month and days only
          const dayFrom = addMonths(monthFrom, months);
          return {
            months: [months, monthFrom, monthTo],
            weeks: [0, null, null],
            days: [differenceInDays(to, dayFrom), dayFrom, to],
          };
        }
      }
    } else {
      const weeks = differenceInWeeks(to, from);
      if (weeks > 0) {
        if (to.getDay() === from.getDay()) {
          // weeks only
          return {
            months: [0, null, null],
            weeks: [weeks, from, to],
            days: [0, null, null],
          };
        } else {
          const weekTo = addWeeks(from, weeks);
          const dayFrom = weekTo;
          // weeks and days only
          return {
            months: [0, null, null],
            weeks: [weeks, from, weekTo],
            days: [differenceInDays(to, dayFrom), dayFrom, to],
          };
        }
      } else {
        // days only
        return {
          months: [0, null, null],
          weeks: [0, null, null],
          days: [differenceInDays(to, from), from, to],
        };
      }
    }
  }
  testMWD() {
    console.log(this.toMWDAndFeeDetails(new Date(2022, 0, 1), new Date(2022, 1, 1), 1500, 650, 125, 0, false));
    console.log(this.toMWDAndFeeDetails(new Date(2022, 0, 1), new Date(2022, 1, 8), 1500, 650, 125, 0, false));
    console.log(this.toMWDAndFeeDetails(new Date(2022, 0, 1), new Date(2022, 1, 6), 1500, 650, 125, 0, false));
    console.log(this.toMWDAndFeeDetails(new Date(2022, 0, 1), new Date(2022, 1, 12), 1500, 650, 125, 0, false));
    console.log(this.toMWDAndFeeDetails(new Date(2022, 0, 1), new Date(2022, 0, 8), 1500, 650, 125, 0, false));
    console.log(this.toMWDAndFeeDetails(new Date(2022, 0, 1), new Date(2022, 0, 12), 1500, 650, 125, 0, false));
    console.log(this.toMWDAndFeeDetails(new Date(2022, 0, 1), new Date(2022, 0, 4), 1500, 650, 125, 20, false));
  }
}

const dfn = new DateFn();

export default dfn;
