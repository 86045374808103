import React from 'react';
import PageContainer from '../../components/PageContainer';
import BalanceListTable from '../../data-tables/BalanceListTable';

export default function BalanceList() {
  return (
    <PageContainer title="Balance List">
      <BalanceListTable />
    </PageContainer>
  );
}
