import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  CardActionArea,
  colors,
  Drawer,
  Link,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { capitalCase } from 'change-case';
import useAuth from '../../hooks/useAuth';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import Scrollbar from '../../components/Scrollbar';
import { useStoreOptions } from '../../hooks/useStores';
import { getSidebarConfig } from './sidebarConfig';
import logoImage from '../../media/one-book-64.png';
import MHidden from '../../components/MHidden';
import MyAvatar from '../../components/MyAvatar';
import NavSection from '../../components/NavSection';
import {VERSION} from '../../../config';

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex,
    }),
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12],
}));

IconCollapse.propTypes = {
  onToggleCollapse: PropTypes.func,
  collapseClick: PropTypes.bool,
};

function IconCollapse({ onToggleCollapse, collapseClick }) {
  return (
    <Tooltip title="Mini Menu">
      <CardActionArea
        onClick={onToggleCollapse}
        sx={{
          width: 18,
          height: 18,
          display: 'flex',
          cursor: 'pointer',
          borderRadius: '50%',
          alignItems: 'center',
          color: 'text.primary',
          justifyContent: 'center',
          border: 'solid 1px currentColor',
          ...(collapseClick && {
            borderWidth: 2,
          }),
        }}
      >
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            bgcolor: 'currentColor',
            transition: (theme) => theme.transitions.create('all'),
            ...(collapseClick && {
              width: 0,
              height: 0,
            }),
          }}
        />
      </CardActionArea>
    </Tooltip>
  );
}

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

function StoreSelect() {
  const { user, updateStoreId } = useAuth();
  const [options] = useStoreOptions();
  const curStoreId = user.storeId;
  const [storeId, setStoreId] = useState(user.storeId ?? '');
  useEffect(() => {
    if (options.length <= 0) {
      return;
    }
    if (!storeId) {
      setStoreId(curStoreId ? curStoreId : options?.find((i) => i.storeId === 'demo')?.storeId);
    } else {
      const storeData = options.find((i) => i.storeId === storeId);
      updateStoreId(storeData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeId, curStoreId, options]);
  return (
    <TextField
      select
      label="Current Store"
      value={storeId ?? ''}
      onChange={(e) => {
        setStoreId(e.target.value);
      }}
    >
      {options.map(({ storeId, title }) => (
        <MenuItem value={storeId} key={storeId}>
          {title}
        </MenuItem>
      ))}
    </TextField>
  );
}

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const { user } = useAuth();
  const { isCollapse, collapseClick, collapseHover, /*onToggleCollapse,*/ onHoverEnter, onHoverLeave } =
    useCollapseDrawer();
  const sidebarConf = getSidebarConfig(user.role, user.storeId);
  // console.log({ sidebarConf, user });
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          px: 2.5,
          pt: 3,
          pb: 2,
          ...(isCollapse && {
            alignItems: 'center',
          }),
        }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
            <img src={logoImage} height={42} alt="TechCube OneBook" />
          </Box>
          <Stack direction={'column'}>
            <Typography variant={'body2'}>TECHCUBE</Typography>
            <Typography variant={'h4'} sx={{mt: '-8px', color: '#EE4E34', fontWeight: 900}}>ONEBOOK</Typography>
          </Stack>
          {/*<MHidden width="lgDown">*/}
          {/*  {!isCollapse && <IconCollapse onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />}*/}
          {/*</MHidden>*/}
          <Typography style={{marginTop: -5, color: colors.grey[500], fontSize: '0.8rem', textAlign: 'end', flex: 1}}>{VERSION}</Typography>
        </Stack>
        {/* TODO */}
        {isCollapse ? (
          <MyAvatar sx={{ mx: 'auto', mb: 2 }} />
        ) : (
          <Link underline="none" component={RouterLink} to="/">
            <AccountStyle>
              <MyAvatar />
              <Box sx={{ ml: 2 }}>
                <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                  {user?.displayName ?? user?.email}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {capitalCase(user?.role ?? '')}
                </Typography>
              </Box>
            </AccountStyle>
          </Link>
        )}
        {user?.role === 'super' && <StoreSelect />}
      </Stack>
      <NavSection navConfig={sidebarConf} isShow={!isCollapse} />
      <Box sx={{ flexGrow: 1 }} />
      {/* {!isCollapse && ( */}
      {/*  <Stack spacing={3} alignItems="center" sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center' }}> */}
      {/*    <DocIllustration sx={{ width: 1 }} /> */}

      {/*    <div> */}
      {/*      <Typography gutterBottom variant="subtitle1"> */}
      {/*        Hi, {user?.displayName} */}
      {/*      </Typography> */}
      {/*      <Typography variant="body2" sx={{ color: 'text.secondary' }}> */}
      {/*        Need help? */}
      {/*        <br /> Please check our docs */}
      {/*      </Typography> */}
      {/*    </div> */}
      {/*    <Button href={PATH_DOCS} target="_blank" variant="contained"> */}
      {/*      Documentation */}
      {/*    </Button> */}
      {/*  </Stack> */}
      {/* )} */}
    </Scrollbar>
  );
  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              ...(isCollapse && {
                width: COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) => alpha(theme.palette.background.default, 0.88),
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
