import React from 'react';
import { MenuItem, TextField } from '@mui/material';
import firebase from 'firebase/app';
import 'firebase/firestore';
import FirestoreCRUDTable from './FirestoreCRUDTable';
import useStoreId from '../hooks/useStoreId';
import RoomForm from '../forms/RoomForm';
import { formatCurrency } from '../utils/utils';
import useRoomTypeList from '../hooks/useRoomTypeList';

const RoomCRUDTable = () => {
  const [selectedRoomType, setSelectedRoomType] = React.useState('all');
  const storeId = useStoreId();
  const [roomTypes] = useRoomTypeList();
  let query = null;
  if (storeId) {
    query = firebase.firestore().collection(`stores/${storeId}/rooms`);
    if (selectedRoomType !== 'all') {
      query = query.where('roomType', '==', selectedRoomType);
    }
  }
  function roomTypeGetter({value}) {
    const roomType = roomTypes?.find((i) => i.value === value);
    if (roomType) {
      return `${roomType.value} (${roomType.label})`;
    }
    return '';
  }
  function roomRateGetter(roomType, rateName) {
    const item = roomTypes?.find((i) => i.value === roomType);
    if (!item) {
      return '';
    }
    return formatCurrency(item[rateName]);
  }
  return (
    <FirestoreCRUDTable
      query={query}
      columns={[
        { field: 'roomNo', headerName: 'Room No.', headerAlign: 'center', align: 'center' },
        { field: 'roomType', headerName: 'Room Type', valueGetter: roomTypeGetter },
        { field: 'standardRate', headerName: 'Standard Rate', valueGetter: ({row: {roomType}}) => roomRateGetter(roomType, 'standardRate'), align: 'center', headerAlign: 'center' },
        { field: 'weeklyRate', headerName: 'Weekly Rate', valueGetter: ({row: {roomType}}) => roomRateGetter(roomType, 'weeklyRate'), align: 'center', headerAlign: 'center' },
        { field: 'monthlyRate', headerName: 'Monthly Rate', valueGetter: ({row: {roomType}}) => roomRateGetter(roomType, 'monthlyRate'), align: 'center', headerAlign: 'center' },
        { field: 'corpRate', headerName: 'Corp. Rate', valueGetter: ({row: {roomType}}) => roomRateGetter(roomType, 'corpRate'), align: 'center', headerAlign: 'center' },
      ]}
      title="ROOM"
      labelKey="roomNo"
      deleteKeys={['roomId', 'roomNo']}
      deleteFunctionName="deleteRoom"
      hideEditButton
      Form={RoomForm}
      renderLeftHeader={() => (
        <TextField
          label="Select Room Type"
          select
          size="small"
          value={selectedRoomType}
          onChange={(e) => setSelectedRoomType(e.target.value)}
          sx={{ minWidth: '200px' }}
        >
          <MenuItem value="all">All</MenuItem>
          {roomTypes.map(({ value, label }) => (
            <MenuItem value={value} key={value}>{`${value} - ${label}`}</MenuItem>
          ))}
        </TextField>
      )}
    />
  );
};

export default RoomCRUDTable;
