import React from 'react';
import { Checkbox, FormControlLabel, MenuItem, TextField } from '@mui/material';
import useRoomTypeList from '../hooks/useRoomTypeList';
import { SearchButton } from '../components/icon-buttons';
import CustomerSearchDialog, { useCustomerSearchDialog } from '../dialogs/CustomerSearchDialog';
import useCompanyList from '../hooks/useCompanyList';
import { houseKeepingOptions } from '../utils/options';

const DEF_MIN_WIDTH = '120px';

const InputField = ({ name, label, formik, width, size, options, checkbox, disabled, readOnly, flex }) => {
  const { getFieldProps, touched, errors } = formik;
  if (checkbox) {
    const props = getFieldProps(name);
    return (
      <FormControlLabel control={<Checkbox {...props} checked={props.value === true} />} label={label} disabled={disabled} />
    );
  }
  if (options && options.length > 0) {
    return (
      <TextField
        label={label}
        {...getFieldProps(name)}
        error={Boolean(touched[name] && errors[name])}
        helperText={touched[name] && errors[name]}
        select
        fullWidth
        sx={{ minWidth: width ?? DEF_MIN_WIDTH, width, flex }}
        size={size ?? 'small'}
        disabled={disabled}
      >
        {options.map((i, index) => {
          const value = typeof i === 'string' ? i : i.value;
          const label = typeof i === 'string' ? i : i.label;
          const {disabled} = i;
          return (
            <MenuItem key={`${index}-${value}`} value={value} disabled={disabled}>
              {label}
            </MenuItem>
          );
        })}
      </TextField>
    );
  }
  return (
    <TextField
      label={label}
      {...getFieldProps(name)}
      error={Boolean(touched[name] && errors[name])}
      helperText={touched[name] && errors[name]}
      fullWidth
      sx={{ minWidth: width ?? DEF_MIN_WIDTH, width, flex }}
      size={size ?? 'small'}
      disabled={disabled}
      readOnly={readOnly}
      />
  );
};

export const CustomerSearchField = ({ name, label, formik, width, size, disabled, flex, onSelectCustomer }) => {
  const { getFieldProps, touched, errors } = formik;
  const {props, open} = useCustomerSearchDialog(onSelectCustomer);
  return (
    <>
      <TextField
        label={label}
        {...getFieldProps(name)}
        error={Boolean(touched[name] && errors[name])}
        helperText={touched[name] && errors[name]}
        fullWidth
        sx={{ minWidth: width ?? DEF_MIN_WIDTH, width, flex }}
        size={size ?? 'small'}
        disabled={disabled}
        InputProps={{
          endAdornment: <SearchButton onClick={() => open()}/>
        }}
      />
      <CustomerSearchDialog {...props} />
    </>
  );
};

export const StandaloneInputField = ({value, onChange, label, width, size, options, disabled, readOnly, flex, checkbox}) => {
  if (checkbox) {
    return (
      <FormControlLabel
        control={<Checkbox onChange={onChange} checked={value === true} />}
        label={label}
        disabled={disabled}
        sx={{ minWidth: width ?? DEF_MIN_WIDTH, width, flex }}
      />
    );
  }
  if (options && options.length > 0) {
    return (
      <TextField
        value={value ?? ''}
        onChange={onChange}
        label={label}
        select
        fullWidth
        sx={{ minWidth: width ?? DEF_MIN_WIDTH, width, flex }}
        size={size ?? 'small'}
        disabled={disabled}
      >
        {options.map((i, index) => {
          const value = typeof i === 'string' ? i : i.value;
          const label = typeof i === 'string' ? i : i.label;
          const {disabled} = i;
          return (
            <MenuItem key={`${index}-${value}`} value={value} disabled={disabled}>
              {label}
            </MenuItem>
          );
        })}
      </TextField>
    );
  }
  return (
    <TextField
      value={value ?? ''}
      onChange={onChange}
      label={label}
      fullWidth
      sx={{ minWidth: width ?? DEF_MIN_WIDTH, width, flex }}
      size={size ?? 'small'}
      disabled={disabled}
      readOnly={readOnly}
    />
  );
};

export const RoomTypeSelect = ({onChange, flex, width}) => {
  const [roomType, setRoomType] = React.useState('all');
  const [roomTypes] = useRoomTypeList();
  const roomOptions = roomTypes.map(({ value, label }) => ({
    value,
    label: `${value} - ${label}`,
  }));
  return (
    <StandaloneInputField
      width={width}
      flex={flex}
      options={[{value: 'all', label: 'All'}, ...roomOptions]}
      label={'Select Room Type'}
      value={roomType ?? ''}
      onChange={({target: {value}}) => {
        setRoomType(value);
        onChange(value);
      }}
    />
  );
};

export const HouseKeepingStatusSelect = ({onChange, flex, width}) => {
  const [roomType, setRoomType] = React.useState('all');
  return (
    <StandaloneInputField
      width={width}
      flex={flex}
      options={[{value: 'all', label: 'All'}, ...houseKeepingOptions]}
      label={'House Keeping Status'}
      value={roomType ?? ''}
      onChange={({target: {value}}) => {
        setRoomType(value);
        onChange(value);
      }}
    />
  );
};

export const CompanySelect = ({onChange, flex, width, initialId}) => {
  const [id, setId] = React.useState(initialId);
  const [companies, /*loading*/, error] = useCompanyList();
  if (error) {
    console.error(error);
  }
  const options = [
    {value: 'n/a', label: 'N/A'},
    ...companies.map(company => ({value: company.id, label: company.company}))
  ];
  React.useEffect(() => {
    setId(initialId);
  }, [initialId]);
  return (
    <StandaloneInputField
      width={width}
      flex={flex}
      options={options}
      label={'Select Company'}
      value={id}
      onChange={({target: {value}}) => {
        setId(value);
        onChange(value, companies.find(i => i.id === value) ?? {});
      }}
    />
  );
};

export default InputField;
