import React from 'react';
import PageContainer from '../../components/PageContainer';
import BookingCRUDTable from '../../data-tables/BookingCRUDTable';

export default function BookingList() {
  return (
    <PageContainer title="Booking List">
      <BookingCRUDTable />
    </PageContainer>
  );
}
