import React from 'react';
import { Button, Stack } from '@mui/material';

export default function FormButtons({formik, label, editMode, onCloseDialog}) {
  return (
    <Stack direction="row" spacing={2} alignItems="center" pt={4}>
      <Button type="submit" variant="contained" color="primary" disabled={formik.isSubmitting}>
        {`${editMode ? 'EDIT' : 'ADD'} ${label}`}
      </Button>
      {onCloseDialog && (
        <Button variant="contained" color="error" disabled={formik.isSubmitting} onClick={onCloseDialog}>
          CLOSE
        </Button>
      )}
    </Stack>
  );
}
