import React from 'react';
import PageContainer from '../../components/PageContainer';
import BookingRequestListTable from '../../data-tables/BookingRequestListTable';

export default function BookingRequests() {
  return (
    <PageContainer title="Booking Request">
      <BookingRequestListTable />
    </PageContainer>
  );
}
