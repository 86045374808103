import React, {useMemo, useState} from 'react';
import {Backdrop as MUIBackdrop, CircularProgress} from '@mui/material';

export function useBackdrop() {
  const [open, setOpen] = useState(false);
  return useMemo(() => {
    return {
      open: () => setOpen(true),
      close: () => setOpen(false),
      props: {
        open
      },
    };
  }, [open]);
}

export const Backdrop = ({open}) => {
  return (
    <MUIBackdrop open={open} sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
      <CircularProgress color={'inherit'} />
    </MUIBackdrop>
  );
};
