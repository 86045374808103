import React from 'react';
import { useStoreCol } from '../utils/fire-v8';
import firebase from 'firebase/app';
import { useCollection } from 'react-firebase-hooks/firestore';
import useStoreId from './useStoreId';

export default function useCheckInList() {
  return useStoreCol('check-ins', (colRef) => colRef.where('status', '==', 'checked-in'));
  // const storeId = useStoreId();
  // const db = firebase.firestore();
  // let query = storeId ? db.collection(`stores/${storeId}/check-ins`).where('status', '==', 'checked-in') : null;
  // const [snapshot, loading, error] = useCollection(query);
  // return React.useMemo(() => {
  //   return [snapshot?.docs.map(doc => ({...doc.data(), id: doc.id})) ?? [], loading, error];
  // }, [snapshot, loading, error]);
}

export function useCheckInListExceptCheckInId(checkInId) {
  const storeId = useStoreId();
  const db = firebase.firestore();
  let query = storeId ? db.collection(`stores/${storeId}/check-ins`).where('status', '==', 'checked-in') : null;
  const [snapshot, loading, error] = useCollection(query);
  return React.useMemo(() => {
    return [snapshot?.docs
      .map(doc => ({...doc.data(), id: doc.id}))
      .filter(({id}) => id != checkInId) // 패러미터(bookingId)로 넘어온 부킹 정보는 제외함
      ?? [], loading, error];
  }, [snapshot, loading, error]);
}
