import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import bellFill from '@iconify/icons-eva/bell-fill';
import { alpha } from '@mui/material/styles';
import {
  Avatar,
  Badge,
  Box,
  Divider,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography
} from '@mui/material';
import Scrollbar from '../../components/Scrollbar';
import { useStoreCol } from '../../utils/fire-v8';
import MenuPopover from '../../components/MenuPopover';
import MIconButton from '../../components/MIconButton';

export default function NotificationsPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [bookingRequests = []] = useStoreCol('booking-requests', (colRef) => colRef.where('status', '==', 'pending').orderBy('createdAt', 'desc'));
  const [inquiries = []] = useStoreCol('inquiries', (colRef) => colRef.where('status', '==', 'pending').orderBy('createdAt', 'desc'));
  const [expediaMails = []] = useStoreCol('expedia-mails', (colRef) => colRef.where('status', '==', 'pending').orderBy('createdAt', 'desc'));
  const totalUnRead = bookingRequests.length + inquiries.length + expediaMails.length;
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <MIconButton
        ref={anchorRef}
        size="large"
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 360 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
          </Box>
        </Box>
        <Divider />
        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List disablePadding>
            {bookingRequests.length > 0 && (
              <ListItemButton to={'/customer-service/booking-requests'} component={RouterLink}>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: 'background.neutral' }}><img alt={'pending booking requests'} src="/static/icons/ic_notification_package.svg" /></Avatar>
                </ListItemAvatar>
                {bookingRequests.length <= 1 && (<ListItemText>{bookingRequests.length} booking request is pending</ListItemText>)}
                {bookingRequests.length > 1 && (<ListItemText>{bookingRequests.length} booking requests are pending</ListItemText>)}
              </ListItemButton>
            )}
            {inquiries.length > 0 && (
              <ListItemButton to={'/customer-service/inquiries'} component={RouterLink}>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: 'background.neutral' }}><img alt={'pending inquiries'} src="/static/icons/ic_notification_chat.svg" /></Avatar>
                </ListItemAvatar>
                {inquiries.length <= 1 && (<ListItemText>{inquiries.length} inquiry is pending</ListItemText>)}
                {inquiries.length > 1 && (<ListItemText>{inquiries.length} inquiries are pending</ListItemText>)}
              </ListItemButton>
            )}
            {expediaMails.length > 0 && (
              <ListItemButton to={'/customer-service/expedia-mails'} component={RouterLink}>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: 'background.neutral' }}><img alt={'pending mail messages'} src="/static/icons/ic_notification_mail.svg" /></Avatar>
                </ListItemAvatar>
                {expediaMails.length <= 1 && (<ListItemText>{expediaMails.length} mail message is pending</ListItemText>)}
                {expediaMails.length > 1 && (<ListItemText>{expediaMails.length} mail messages are pending</ListItemText>)}
              </ListItemButton>
            )}
          </List>
        </Scrollbar>
      </MenuPopover>
    </>
  );
}
