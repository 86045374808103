import React from 'react';
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/lab';
import { formatDate } from '../utils/utils';

const DateField = ({ name, label, formik, width, size, disabled, flex, onChange, initialDate }) => {
  const [date, setDate] = React.useState(null);
  const [dateStr, setDateStr] = React.useState('');
  const { touched, errors } = formik;
  React.useEffect(() => {
    if (initialDate && initialDate !== dateStr) {
      setDate(new Date(initialDate + 'T00:00:00'));
      setDateStr(initialDate);
    }
  }, [initialDate, dateStr]);
  return (
    <DatePicker
      clearable={true}
      label={label}
      onChange={(newDate) => {
        setDate(newDate);
        setDateStr(formatDate(newDate));
        onChange(newDate);
      }}
      value={date}
      inputFormat={'yyyy-MM-dd'}
      mask={'____-__-__'}
      renderInput={(props) => {
        return (
          <TextField
            {...props}
            name={name}
            error={Boolean(touched[name] && errors[name])}
            helperText={touched[name] && errors[name]}
            fullWidth
            sx={{ minWidth: width ?? '120px', width, flex }}
            size={size ?? 'small'}
            disabled={disabled}
          />
        );
      }}
    />
  );
};

export const StandaloneDateField = ({ name, label, width, size, disabled, flex, value, onChange }) => {
  return (
    <DatePicker
      clearable={true}
      label={label}
      onChange={(newDate) => {
        onChange(newDate);
      }}
      value={value}
      inputFormat={'yyyy-MM-dd'}
      mask={'____-__-__'}
      renderInput={(props) => {
        return (
          <TextField
            {...props}
            name={name}
            fullWidth
            sx={{ minWidth: width ?? '120px', width, flex }}
            size={size ?? 'small'}
            disabled={disabled}
          />
        );
      }}
    />
  );
};

export default DateField;
