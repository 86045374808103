import React from 'react';
import { Container, Grid } from '@mui/material';
import useSettings from '../../hooks/useSettings';
import Page from '../../components/Page';
import {
  BookingBookedRoom,
  BookingCheckIn,
  BookingCheckOut,
  BookingRoomAvailable,
  BookingTotal,
  BookingTotalIncomes
} from '../../components/dashboard';
import useDashboard from '../../hooks/useDashboard';
import { devLog } from '../../utils/utils';
import { useDashboardReportData } from '../reports/MonthlyReport';
import { format } from 'date-fns';

export default function Dashboard() {
  const { themeStretch } = useSettings();
  const {data, error} = useDashboard();
  const [thisMonthData, /*lastMonthData*/, thisMonthTotal, lastMonthTotal] = useDashboardBalanceData();
  if (error) {
    console.error(error);
  }
  const {
    bookingCount = 0,
    bookingDoneCount = 0,
    bookingCancelledCount = 0,
    checkInCount = 0,
    checkOutCount = 0,
    //checkInOutRange = '...',
    totalRoomCount = 0,
    roomCountList = {},
  } = data;
  const thisMonthLabel = format(new Date(), 'yyyy LLLL');
  return (
    <Page title="Dashboard | TechCube OneBook - Property Management System">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <BookingTotal total={bookingCount} />
          </Grid>
          <Grid item xs={12} md={4}>
            <BookingCheckIn total={checkInCount} range={thisMonthLabel} />
          </Grid>
          <Grid item xs={12} md={4}>
            <BookingCheckOut total={checkOutCount} range={thisMonthLabel} />
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                {/*<BookingTotalIncomes totalAmount={totalAmount} range={checkInOutRange} percent={0} chartData={[{data: dailySales.map(([date, amount]) => amount)}]} />*/}
                <BookingTotalIncomes totalAmount={thisMonthTotal} lastAmount={lastMonthTotal} range={thisMonthLabel} chartData={[{data: thisMonthData.map(([dateStr, gross]) => gross)}]} />
              </Grid>
              <Grid item xs={12} md={6}>
                <BookingBookedRoom pendingCount={bookingCount} doneCount={bookingDoneCount} cancelledCount={bookingCancelledCount} />
              </Grid>
              {/*<Grid item xs={12} md={12}>*/}
              {/*  <BookingCheckInWidgets />*/}
              {/*</Grid>*/}
            </Grid>
          </Grid>

          <Grid item xs={12} md={4}>
            <BookingRoomAvailable totalRoomCount={totalRoomCount} roomCountList={roomCountList} />
          </Grid>

          {/*<Grid item xs={12} md={8}>*/}
          {/*  <BookingReservationStats />*/}
          {/*</Grid>*/}

          {/*<Grid item xs={12} md={4}>*/}
          {/*   <BookingCustomerReviews /> */}
          {/*</Grid>*/}

          {/* <Grid item xs={12}> */}
          {/*  <BookingNewestBooking /> */}
          {/* </Grid> */}

          {/* <Grid item xs={12}> */}
          {/*  <BookingDetails /> */}
          {/* </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}

function useDashboardBalanceData() {
  const [reportData, loading, salesData] = useDashboardReportData();
  if (loading || reportData?.length <= 0) {
    return [[], [], 0, 0];
  }
  const monthStart = reportData[0].from;
  const monthEnd = reportData[0].to;
  const lastMonthStart = reportData[1].from;
  const lastMonthEnd = reportData[1].to;
  const thisMonthTotal = reportData[0].gross;
  const lastMonthTotal = reportData[1].gross;
  const thisMonthSalesData = salesData.filter(({dateStr}) => dateStr >= monthStart && dateStr <= monthEnd);
  const lastMonthSalesData = salesData.filter(({dateStr}) => dateStr >= lastMonthStart && dateStr <= lastMonthEnd);
  const thisMonthData = {};
  const lastMonthData = {};
  for (const {gross, dateStr} of thisMonthSalesData) {
    if (!thisMonthData[dateStr]) {
      thisMonthData[dateStr] = 0;
    }
    thisMonthData[dateStr] += gross;
  }
  for (const {gross, dateStr} of lastMonthSalesData) {
    if (!lastMonthData[dateStr]) {
      lastMonthData[dateStr] = 0;
    }
    lastMonthData[dateStr] += gross;
  }
  devLog(thisMonthData, lastMonthData, thisMonthTotal, lastMonthTotal);
  return [Object.entries(thisMonthData), Object.entries(lastMonthData), thisMonthTotal, lastMonthTotal];
}
