import SvgIconStyle from '../../components/SvgIconStyle';
import { Icon } from '@iconify/react';
import clearFill from '@iconify/icons-ant-design/clear';
import checkOutline from '@iconify/icons-ant-design/check-circle-outline';
import questionOutline from '@iconify/icons-eva/question-mark-circle-outline';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PeopleIcon from '@mui/icons-material/People';
import ListAltIcon from '@mui/icons-material/ListAlt';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import BalanceIcon from '@mui/icons-material/Balance';

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const sidebarIcons = {
  // blog: getIcon('ic_blog'),
  // cart: getIcon('ic_cart'),
  // chat: getIcon('ic_chat'),
  // mail: getIcon('ic_mail'),
  user: <PeopleIcon />,
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  // ecommerce: getIcon('ic_ecommerce'),
  // analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  billing: <MonetizationOnIcon />,
  company: <CorporateFareIcon />,
  invoice: <ReceiptIcon />,
  housekeeping: <Icon icon={clearFill} width={32} height={32} />,
  checkIn: <Icon icon={checkOutline} width={32} height={32} />,
  inquiries: <Icon icon={questionOutline} width={32} height={32} />,
  report: <ListAltIcon />,
  chart: <InsertChartOutlinedIcon />,
  reconciliation: <ManageSearchIcon />,
  balanceList: <BalanceIcon />
};

const sidebarConfig = [
  {
    subheader: 'property management',
    items: [
      { title: 'Dashboard', path: '/property-management/dashboard', icon: sidebarIcons.dashboard },
      { title: 'Room Status', path: '/property-management/room-status', icon: sidebarIcons.housekeeping },
      { title: 'Booking Status', path: '/property-management/booking-status', icon: sidebarIcons.booking },
      { title: 'Booking List', path: '/property-management/booking-list', icon: sidebarIcons.booking },
      { title: 'Calendar View', path: '/property-management/calendar-view', icon: sidebarIcons.calendar },
      { title: 'Check-In List', path: '/property-management/check-in-list', icon: sidebarIcons.checkIn },
      { title: 'Billing List', path: '/property-management/billing-list', icon: sidebarIcons.billing },
      { title: 'Invoice Search', path: '/property-management/invoice-search', icon: sidebarIcons.invoice },
      // { title: 'Payment List', path: '/property-management/payment-list', icon: sidebarIcons.banking },
      // { title: 'On-Site Booking', path: '/property-management/onsite-booking', icon: ICONS.analytics },
    ],
  },
  {
    subheader: 'customer service',
    items: [
      { title: 'Customer List', path: '/customer-service/customer-list', icon: sidebarIcons.user },
      { title: 'Company List', path: '/customer-service/company-list', icon: sidebarIcons.company },
      { title: 'Booking Requests', path: '/customer-service/booking-requests', icon: sidebarIcons.booking },
      { title: 'Inquiries', path: '/customer-service/inquiries', icon: sidebarIcons.inquiries },
      { title: 'Expedia Mails', path: '/customer-service/expedia-mails', icon: sidebarIcons.booking },
    ],
  },
  {
    subheader: 'reports',
    items: [
      { title: 'Reconciliation', path: '/reports/reconciliation', icon: sidebarIcons.reconciliation },
      { title: 'Balance List', path: '/reports/balance-list', icon: sidebarIcons.balanceList },
      { title: 'Monthly Report', path: '/reports/monthly-report', icon: sidebarIcons.report },
      // { title: 'Monthly Chart', path: '/reports/monthly-chart', icon: sidebarIcons.chart },
      // { title: 'Sales Report', path: '/reports/sales-report', icon: sidebarIcons.report },
    ],
  },
  {
    subheader: 'settings',
    items: [
      { title: 'Invoice Items', path: '/settings/invoice-items', icon: sidebarIcons.kanban },
      { title: 'Room Type List', path: '/settings/room-type-list', icon: sidebarIcons.kanban },
      { title: 'Room List', path: '/settings/room-list', icon: sidebarIcons.kanban },
      // { title: 'Staff List', path: '/settings/staff-list', icon: ICONS.user },
    ],
  },
  // {
  //   subheader: 'store super admin',
  //   items: [
  //     { title: 'Store Management', path: '/store-list', icon: null },
  //     { title: 'Activity Logs', path: '/activity-logs', icon: null },
  //   ],
  // },
];

const sidebarConfigRidgeview = [
  {
    subheader: 'property management',
    items: [
      { title: 'Dashboard', path: '/property-management/dashboard', icon: sidebarIcons.dashboard },
      { title: 'Room Status', path: '/property-management/room-status', icon: sidebarIcons.housekeeping },
      { title: 'Booking Status', path: '/property-management/booking-status', icon: sidebarIcons.booking },
      { title: 'Booking List', path: '/property-management/booking-list', icon: sidebarIcons.booking },
      { title: 'Calendar View', path: '/property-management/calendar-view', icon: sidebarIcons.calendar },
      { title: 'Check-In List', path: '/property-management/check-in-list', icon: sidebarIcons.checkIn },
      { title: 'Billing List', path: '/property-management/billing-list', icon: sidebarIcons.billing },
      { title: 'Invoice Search', path: '/property-management/invoice-search', icon: sidebarIcons.invoice },
      // { title: 'Payment List', path: '/property-management/payment-list', icon: sidebarIcons.banking },
      // { title: 'On-Site Booking', path: '/property-management/onsite-booking', icon: ICONS.analytics },
    ],
  },
  {
    subheader: 'customer service',
    items: [
      { title: 'Customer List', path: '/customer-service/customer-list', icon: sidebarIcons.user },
      { title: 'Company List', path: '/customer-service/company-list', icon: sidebarIcons.company },
      { title: 'Booking Requests', path: '/customer-service/booking-requests', icon: sidebarIcons.booking },
      { title: 'Inquiries', path: '/customer-service/inquiries', icon: sidebarIcons.inquiries },
      // { title: 'Expedia Mails', path: '/customer-service/expedia-mails', icon: sidebarIcons.booking },
    ],
  },
  {
    subheader: 'reports',
    items: [
      { title: 'Reconciliation', path: '/reports/reconciliation', icon: sidebarIcons.reconciliation },
      { title: 'Balance List', path: '/reports/balance-list', icon: sidebarIcons.balanceList },
      { title: 'Monthly Report', path: '/reports/monthly-report', icon: sidebarIcons.report },
      // { title: 'Balance List', path: '/reports/balance-list', icon: sidebarIcons.balanceList },
      // { title: 'Daily Report', path: '/reports/daily-report', icon: sidebarIcons.report },
      // { title: 'Monthly Chart', path: '/reports/monthly-chart', icon: sidebarIcons.chart },
      // { title: 'Sales Report', path: '/reports/sales-report', icon: sidebarIcons.report },
    ],
  },
  {
    subheader: 'settings',
    items: [
      { title: 'Invoice Items', path: '/settings/invoice-items', icon: sidebarIcons.kanban },
      { title: 'Room Type List', path: '/settings/room-type-list', icon: sidebarIcons.kanban },
      { title: 'Room List', path: '/settings/room-list', icon: sidebarIcons.kanban },
      // { title: 'Staff List', path: '/settings/staff-list', icon: ICONS.user },
    ],
  },
];

const excludeMenusByRole = {
  owner: ['settings', 'store super admin'],
  manager: ['reports', 'settings', 'store super admin'],
  staff: ['reports', 'settings', 'store super admin'],
  housekeeper: ['reports', 'settings', 'store super admin'],
};

export function getSidebarConfig(role, storeId) {
  let config = storeId === 'ridgeview' ? [...sidebarConfigRidgeview] : [...sidebarConfig];
  switch (role) {
    case 'super':
      return config;
    case 'owner':
      return config.filter((i) => !excludeMenusByRole[role].includes(i.subheader));
    case 'manager':
    case 'staff':
    case 'housekeeper':
      config = config.filter((i) => !excludeMenusByRole[role].includes(i.subheader));
      config[0].items = config[0].items.filter((i) => i.title !== 'Dashboard');
      return config;
    default:
      return [];
  }
}

export function getPathTitle(currentPath) {
  // eslint-disable-next-line no-restricted-syntax
  for (const item of sidebarConfig) {
    // eslint-disable-next-line no-restricted-syntax
    for (const { title, path } of item.items) {
      if (currentPath === path) {
        return title;
      }
    }
  }
  return '';
}
