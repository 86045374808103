// material
import { Card, CardHeader, Typography, Stack, LinearProgress, Box } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';

const statusList = ['Pending', 'Cancelled', 'Done'];

const statusColors = {
  Pending: 'warning',
  Cancelled: 'error',
  Done: 'success',
};

export default function BookingBookedRoom({pendingCount, doneCount, cancelledCount}) {
  const props = {pendingCount, doneCount, cancelledCount};
  const totalCount = parseInt(pendingCount) + parseInt(doneCount) + parseInt(cancelledCount);
  const data = statusList.map(status => {
    const quantity = parseFloat(props[`${status.toLowerCase()}Count`]);
    const value = quantity / totalCount * 100;
    return {status, quantity, value};
  });
  return (
    <Card>
      <CardHeader title="Booked Room" />
      <Stack spacing={3} sx={{ px: 3, my: 5 }}>
        {data.map((progress) => (
          <LinearProgress
            variant="determinate"
            key={progress.status}
            value={progress.value}
            color={statusColors[progress.status]}
            sx={{ height: 8, bgcolor: 'grey.50016' }}
          />
        ))}
      </Stack>
      <Stack direction="row" justifyContent="space-between" sx={{ px: 3, pb: 3 }}>
        {data.map((progress) => (
          <Stack key={progress.status} alignItems="center">
            <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
              <Box
                sx={{
                  width: 12,
                  height: 12,
                  borderRadius: 0.5,
                  bgcolor: `${statusColors[progress.status]}.main`,
                }}
              />
              <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                {progress.status}
              </Typography>
            </Stack>
            <Typography variant="h6">{fShortenNumber(progress.quantity)}</Typography>
          </Stack>
        ))}
      </Stack>
    </Card>
  );
}
