import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';

export default function Alert({ open, onClose, message, title }) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" disableEscapeKeyDown>
      <DialogTitle>{title ?? 'ALERT'}</DialogTitle>
      <DialogContent>
        <Box mt={4}>{message}</Box>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2} justifyContent="center" width="100%">
          <Button variant="contained" color="error" onClick={onClose}>
            CLOSE
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export function useAlert() {
  const [state, setState] = React.useState({ open: false });
  const onClose = () => {
    setState({ open: false });
  };
  return {
    alertProps: state,
    openAlert: (message, title) => {
      setState({ message, title, open: true, onClose });
    },
  };
}
