import React from 'react';
import FirestoreListTable from './FirestoreListTable';
import { Box, Link, Stack, Typography } from '@mui/material';
import useStoreId from '../hooks/useStoreId';
import { formatCurrency, storeColRef, storeDocRef } from '../utils/utils';
import { GST_RATE, ROOM_TAX_RATE, salesOptions } from '../utils/options';
import FormDialog, { useFormDialog } from '../forms/FormDialog';
import CheckInForm from '../forms/CheckInForm';
import useAuth from '../hooks/useAuth';
import dfn from '../utils/dfn';

const SalesListTable = ({dateStr}) => {
  const storeId = useStoreId();
  const {user} = useAuth();
  const dateStartedStr = dfn.format(user['dateStarted']);
  const {props: checkInDialogProps, open: openCheckInDialog} = useFormDialog();
  function renderRoomNo({row: {roomNo, checkInId}}) {
    return (
      <Link href={'#view-check-in-info'} onClick={async () => {
        const checkInData = (await storeDocRef(storeId, 'check-ins', checkInId).get()).data();
        if (checkInData) {
          openCheckInDialog({...checkInData, id: checkInId}, true, {readOnly: true});
        }
      }}>{roomNo}</Link>
    );
  }
  const columns = [
    { field: 'roomNo', headerName: 'Room#', headerAlign: 'center', align: 'center', renderCell: renderRoomNo},
    { field: 'itemCode', headerName: 'Sales Type', valueGetter: ({value}) => salesOptions.find(i => i.value === value).label},
    { field: 'net', headerName: 'Net', headerAlign: 'center', align: 'right', renderCell: renderSalesCurrency },
    { field: 'gst', headerName: 'GST', headerAlign: 'center', align: 'right', renderCell: renderSalesCurrency },
    { field: 'roomTax', headerName: 'Room Tax', headerAlign: 'center', align: 'right', renderCell: renderSalesCurrency },
    { field: 'gross', headerName: 'Gross', headerAlign: 'center', align: 'right', renderCell: renderSalesCurrency },
  ];
  return (
    <FirestoreListTable
      query={dateStr >= dateStartedStr ? storeColRef(storeId, 'sales')?.where('dateStr', '==', dateStr).orderBy('roomNo') : null}
      columns={columns}
      renderFooter={(rows) => {
        let net = 0, gst = 0, roomTax = 0, gross = 0;
        for (const r of rows) {
          net += r.net;
          gst += r.gst;
          roomTax += r.roomTax;
          gross += r.gross;
        }
        return (
          <Stack direction={'column'} alignItems={'flex-end'} spacing={1} mt={2}>
            <Typography variant={'overline'} sx={{color: 'text.disabled'}}>Total Net Sales: <CurrencyLabel amount={net} /></Typography>
            <Typography variant={'overline'} sx={{color: 'text.disabled'}}>Total GST ({(GST_RATE * 100).toFixed()} %): <CurrencyLabel amount={gst} /></Typography>
            <Typography variant={'overline'} sx={{color: 'text.disabled'}}>Total Room Tax ({(ROOM_TAX_RATE * 100).toFixed()} %): <CurrencyLabel amount={roomTax} /></Typography>
            <Typography variant={'overline'} sx={{ fontSize: '1rem', color: 'success.main'}}>Total Gross Sales: <CurrencyLabel amount={gross} /></Typography>
            <FormDialog {...checkInDialogProps} title={'CHECK-IN'} Form={CheckInForm} readOnly={true} />
          </Stack>
        );
      }}
      doNotUseCard
      containerSx={{mt: 0, pb:2}}
      hideFooter
      disableSelectionOnClick
    />
  );
};

function renderSalesCurrency({value}) {
  if (value < 0) {
    return <Box component={'span'} sx={{color: 'warning.main'}}>{formatCurrency(value)}</Box>
  }
  return formatCurrency(value);
}

function CurrencyLabel({amount}) {
  return <Box display={'inline-block'} textAlign={'right'} width={'120px'}>{formatCurrency(amount)}</Box>;
}

export default SalesListTable;
