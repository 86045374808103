import React from 'react';
import FirestoreListTable from './FirestoreListTable';
import { cancelCheckIn, formatDateRange, formatDateTime, formatGuestLabel, renderBooker } from '../utils/utils';
import { StandaloneInputField } from '../forms/InputField';
import { DeleteButton, InvoiceButton } from '../components/icon-buttons';
import FormDialog, { useFormDialog } from '../forms/FormDialog';
import CheckInForm from '../forms/CheckInForm';
import useAuth from '../hooks/useAuth';
import { CheckInLink } from '../components/links';
import useRoomList from '../hooks/useRoomList';
import { Autocomplete, Stack } from '@mui/material';
import TextField from '@mui/material/TextField';
import fire from '../utils/fire-v8';
import Confirm, { useConfirm } from '../components/Confirm';
import useCompanyList from '../hooks/useCompanyList';

const CheckInListTable = ({status, paymentStatus}) => {
  const [search, setSearch] = React.useState('');
  const [selectedRoom, setSelectedRoom] = React.useState('allRooms');
  const [selectedCompanyId, setSelectedCompanyId] = React.useState('');
  const {user} = useAuth();
  const [rooms = []] = useRoomList();
  const [companies = []] = useCompanyList(); // company filter {id: '...', company: ''}
  const {props: checkInDialogProps, open: openCheckInDialog} = useFormDialog();
  const {confirmProps, openConfirm} = useConfirm();
  const query = fire.checkInQuery(status, paymentStatus, search, selectedRoom === 'allRooms' ? '' : selectedRoom, selectedCompanyId);
  function onCancel(row) {
    openConfirm(
      'Are you sure you want to cancel this check-in?',
      async () => cancelCheckIn(fire.storeId, row.id, row.roomNo),
      'Check-in has been cancelled!'
    );
  }
  function getColumns() {
    const actionColumn = {
      field: 'action',
      headerName: 'Action',
      renderCell: ({row}) => [
        <InvoiceButton key={'view'} onClick={() => {openCheckInDialog(row, true, {readOnly: true, isBilling: paymentStatus === 'unpaid'})}} />,
        user.role === 'super' && <DeleteButton key={'delete'} onClick={() => onCancel(row)} />,
      ],
      flex: 0, width: 100, minWidth: 100
    };
    const idColumn = { field: 'id', headerName: 'ID', renderCell: ({value}) => <CheckInLink checkInId={value} /> };
    const columns = [
      { field: 'roomNo', headerName: 'Room#', flex: 0, width: 100, minWidth: 100, align: 'center', headerAlign: 'center' },
      { field: 'name', headerName: 'Name', renderCell: renderBooker },
      { field: 'checkIn', headerName: 'Check In / Check Out', valueGetter: ({value, row}) => formatDateRange(value, row.checkOut) },
      { field: 'guests', headerName: 'Guests', valueGetter: ({row}) => formatGuestLabel(row) },
      { field: 'createdAt', headerName: 'Created At', valueGetter: ({value}) => formatDateTime(value) },
    ];
    return (user.role === 'super' && user.storeId !== 'demo') ? [actionColumn, idColumn, ...columns] : [actionColumn, ...columns];
  }
  const roomOptions = [{value: 'allRooms', label: 'All rooms'}, ...rooms.map(({roomNo, roomType}) => ({value: roomNo, label: `${roomNo} (${roomType})`}))];
  const onChangeCompany = (event, data) => {
    if (data?.id) {
      setSelectedCompanyId(data.id)
    } else {
      setSelectedCompanyId('')
    }
  };
  return (
    <FirestoreListTable
      query={query}
      columns={getColumns()}
      // mapRows={(snapshot) => snapshot?.docs.map(getPaymentData) ?? []}
      // onRowDoubleClick={({row}) => {
      //   console.log(row);
      // }}
      renderLeftHeader={() => {
        return (
          <Stack direction={'row'} spacing={2}>
            <StandaloneInputField
              label={'Search...'}
              value={search}
              onChange={({target: {value}}) => setSearch(value)}
            />
            <StandaloneInputField
              label={'Select Room'}
              value={selectedRoom}
              onChange={({target: {value}}) => setSelectedRoom(value)}
              options={roomOptions}
            />
            <Autocomplete
              onChange={(event, data) => onChangeCompany(event, data)}
              options={companies?.map(({id, company}) => ({id, label: company}))}
              renderInput={(params) => <TextField {...params} label="Company" size='small' sx={{ minWidth: '120px', width: '250px' }} />}
            />
          </Stack>
        );
      }}
      renderFooter={() => {
        return (
          <>
            <FormDialog {...checkInDialogProps} title={'CHECK-IN'} Form={CheckInForm} readOnly={true} />
            <Confirm {...confirmProps} />
          </>
        );
      }}
    />
  );
};

export default CheckInListTable;
