import useAuth from '../hooks/useAuth';
import MAvatar from './MAvatar';
import createAvatar from '../../utils/createAvatar';

export default function MyAvatar({ ...other }) {
  const { user } = useAuth();

  return (
    <MAvatar
      src={user.photoURL}
      alt={user.displayName ?? user.email}
      color={user.photoURL ? 'default' : createAvatar(user.displayName ?? user.email).color}
      {...other}
    >
      {createAvatar(user.displayName ?? user.email).name}
    </MAvatar>
  );
}
