import React from 'react';
import PageContainer from '../../components/PageContainer';
import InquiryListTable from '../../data-tables/InquiryListTable';

export default function Inquiries() {
  return (
    <PageContainer title="Inquiries">
      <InquiryListTable />
    </PageContainer>
  );
}
