import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const FormDialog = ({ open, editData, editMode, additionalProps, onClose, title, renderForm, Form, maxWidth, fullScreen, readOnly }) => (
  <Dialog open={open} onClose={onClose} fullScreen={fullScreen} fullWidth maxWidth={maxWidth ?? 'md'} disableEscapeKeyDown>
    <DialogTitle>
      {typeof title === 'function' && title(editData, editMode)}
      {!readOnly && typeof title === 'string' && `${editMode ? 'EDIT' : 'ADD'} ${title}`}
      {readOnly && typeof title === 'string' && `VIEW ${title}`}
    </DialogTitle>
    {renderForm && <DialogContent>{renderForm(editData, onClose, additionalProps)}</DialogContent>}
    {Form && (
      <DialogContent>
        <Form editData={editData} editMode={editMode} onCloseDialog={onClose} {...additionalProps} />
      </DialogContent>
    )}
  </Dialog>
);

const initialState = {
  open: false,
  editData: {},
  editMode: false,
  additionalProps: {},
};

export function useFormDialog() {
  const [state, setState] = React.useState(initialState);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  return React.useMemo(() => {
    const {open, editData, editMode, additionalProps} = state;
    const onClose = (e, reason) => {
      if (reason !== 'backdropClick') {
        setState(initialState);
      }
    };
    return {
      props: { open, onClose, editData, editMode, fullScreen, additionalProps },
      open: (editData, editMode, additionalProps) => {
        setState({open: true, editData, editMode, additionalProps});
      },
    };
  }, [state, fullScreen]);
}

export default FormDialog;
