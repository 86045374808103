import React from 'react';
import FirestoreListTable from './FirestoreListTable';
import { Box, Link, Stack, Typography } from '@mui/material';
import useStoreId from '../hooks/useStoreId';
import { formatCurrency, gridCurrencyFormatter, storeColRef, storeDocRef } from '../utils/utils';
import { transactionOptions } from '../utils/options';
import FormDialog, { useFormDialog } from '../forms/FormDialog';
import CheckInForm from '../forms/CheckInForm';
import useAuth from '../hooks/useAuth';
import dfn from '../utils/dfn';

const PaymentsListTable = ({dateStr}) => {
  const storeId = useStoreId();
  const {user} = useAuth();
  const dateStartedStr = dfn.format(user['dateStarted']);
  const {props: checkInDialogProps, open: openCheckInDialog} = useFormDialog();
  function renderRoomNo({row: {roomNo, checkInId}}) {
    return (
      <Link href={'#view-check-in-info'} onClick={async () => {
        const checkInData = (await storeDocRef(storeId, 'check-ins', checkInId).get()).data();
        if (checkInData) {
          openCheckInDialog({...checkInData, id: checkInId}, true, {readOnly: true});
        }
      }}>{roomNo}</Link>
    );
  }
  const columns = [
    { field: 'roomNo', headerName: 'Room#', headerAlign: 'center', align: 'center', renderCell: renderRoomNo},
    { field: 'itemCode', headerName: 'Payment Type', valueGetter: ({value}) => transactionOptions.find((i) => i.value === value).label},
    { field: 'amount', headerName: 'Amount', headerAlign: 'center', align: 'right', valueGetter: gridCurrencyFormatter },
  ];
  return (
    <FirestoreListTable
      query={dateStr >= dateStartedStr ? storeColRef(storeId, 'payments')?.where('dateStr', '==', dateStr).orderBy('roomNo') : null}
      columns={columns}
      renderFooter={(rows) => {
        let credit = 0, debit = 0, cash = 0, cheque = 0, paymentTotal = 0;
        for (const {itemCode, amount} of rows) {
          if (itemCode.endsWith('Credit')) {
            credit += amount;
          } else if (itemCode.endsWith('Debit')) {
            debit += amount;
          } else if (itemCode.endsWith('Cash')) {
            cash += amount;
          } else if (itemCode.endsWith('Cheque')) {
            cheque += amount;
          }
        }
        paymentTotal += credit + debit + cash + cheque;
        return (
          <Stack direction={'column'} alignItems={'flex-end'} spacing={1} mt={2}>
            <Typography variant={'overline'} sx={{color: 'text.disabled'}}>Total Credit: <CurrencyLabel amount={credit} /></Typography>
            <Typography variant={'overline'} sx={{color: 'text.disabled'}}>Total Debit: <CurrencyLabel amount={debit} /></Typography>
            <Typography variant={'overline'} sx={{color: 'text.disabled'}}>Total Cash: <CurrencyLabel amount={cash} /></Typography>
            <Typography variant={'overline'} sx={{color: 'text.disabled'}}>Total Cheque: <CurrencyLabel amount={cheque} /></Typography>
            <Typography variant={'overline'} sx={{ fontSize: '1rem', color: 'warning.main'}}>Total Payments: <CurrencyLabel amount={paymentTotal} /></Typography>
            <FormDialog {...checkInDialogProps} title={'CHECK-IN'} Form={CheckInForm} readOnly={true} />
          </Stack>
        );
      }}
      doNotUseCard
      containerSx={{mt: 0, pb:2}}
      hideFooter
      disableSelectionOnClick
    />
  );
};

function CurrencyLabel({amount}) {
  return <Box display={'inline-block'} textAlign={'right'} width={'120px'}>{formatCurrency(amount)}</Box>;
}

export default PaymentsListTable;
