import React from 'react';
import useStoreId from '../hooks/useStoreId';
import { formatDateTime, setStoreDoc } from '../utils/utils';
import { DateRange, useDateRange } from '../components/DateRange';
import StatusSelect from '../components/StatusSelect';
import StatusCell from '../components/StatusCell';
import useAsyncStatus from '../hooks/useAsyncStatus';
import { Link, Tooltip } from '@mui/material';
import { useStoreCol } from '../utils/fire-v8';
import BasicTable from './BasicTable';

const InquiryListTable = () => {
  const storeId = useStoreId();
  const [status, setStatus] = React.useState('all');
  const dateRangeProps = useDateRange('month');
  const execWithFeedback = useAsyncStatus();
  const columns = [
    { field: 'name', headerName: 'Name' },
    { field: 'email', headerName: 'Email', renderCell: renderEmail },
    { field: 'phone', headerName: 'Phone No.' },
    { field: 'message', headerName: 'Message', flex: 2, renderCell: renderMessage },
    {
      field: 'status',
      headerName: 'Status',
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ row }) => (
        <StatusCell
          status={row.status}
          onChange={(newStatus) => {
            if (row.status !== newStatus) {
              const { id: docId } = row;
              execWithFeedback(
                setStoreDoc(storeId, 'inquiries', {status: newStatus}, docId),
                'Status has been updated successfully!'
              );
            }
          }}
        />
      ),
    },
    { field: 'createdAt', headerName: 'Posted At', valueGetter: ({ value }) => formatDateTime(value) },
  ];
  const [dateFrom, dateTo] = dateRangeProps.dateRange;
  const [rows = [], loading, error] = useStoreCol('inquiries', (colRef) => {
    let query = colRef.orderBy('createdAt', 'desc');
    if (status !== 'all') {
      query = query.where('status', '==', status);
    }
    if (dateFrom) {
      query = query.where('createdAt', '>=', dateFrom);
    }
    if (dateTo) {
      query = query.where('createdAt', '<=', dateTo);
    }
    return query;
  });
  return (
    <BasicTable
      rows={rows}
      loading={loading}
      error={error}
      columns={columns}
      renderLeftHeader={() => <DateRange {...dateRangeProps} size="small" />}
      renderRightHeader={() => <StatusSelect status={status} setStatus={setStatus} />}
      containerSx={{p: 2}}
    />
  );
};

function renderMessage({value}) {
  return (
    <Tooltip title={value} arrow>
      <div>{value}</div>
    </Tooltip>
  );
}

function renderEmail({value, row: {name}}) {
  if (!value) {
    return '';
  }
  const mailTo = name ? `${name} <${value}>` : value;
  return (
    <Tooltip title={'Click to email'} arrow>
      <Link href={`mailto:${mailTo}`} target={'_blank'} rel={'noreferrer'}>
        {value}
      </Link>
    </Tooltip>
  );
}

export default InquiryListTable;
