import React from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import FirestoreCRUDTable from './FirestoreCRUDTable';
import useStoreId from '../hooks/useStoreId';
import RoomForm from '../forms/RoomForm';
import { gridCurrencyFormatter, gridTaxableFormatter } from '../utils/utils';

const InvoiceItemCRUDTable = () => {
  const storeId = useStoreId();
  let query = null;
  if (storeId) {
    query = firebase.firestore().collection(`stores/${storeId}/invoice-items`).orderBy('label');
  }
  return (
    <FirestoreCRUDTable
      query={query}
      columns={[
        { field: 'label', headerName: 'Title', align: 'center', headerAlign: 'center' },
        { field: 'fee', headerName: 'Fee', align: 'center', headerAlign: 'center', valueGetter: ({value}) => value === 0 ? 'N/S' : gridCurrencyFormatter({value}) },
        { field: 'taxable', headerName: 'Tax', align: 'center', headerAlign: 'center', valueGetter: gridTaxableFormatter },
      ]}
      title="INVOICE ITEMS"
      labelKey="label"
      deleteKeys={['roomId', 'roomNo']}
      deleteFunctionName="deleteRoom"
      Form={RoomForm}
      // renderLeftHeader={() => {}}
    />
  );
};

export default InvoiceItemCRUDTable;
