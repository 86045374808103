import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import {
  fetchCheckInData,
  fetchStoreData,
  formatCurrency,
  formatDate,
  formatDateTime,
  getBalance
} from '../utils/utils';
import { GST_RATE, ROOM_TAX_RATE } from '../utils/options';

class InvoicePrint extends React.PureComponent {
  componentDidMount() {
    const {storeId, checkInId} = this.props;
    if (storeId) {
      fetchStoreData(storeId).then(data => this.setState({storeData: data})).catch(console.error);
    }
    if (storeId && checkInId) {
      fetchCheckInData(storeId, checkInId).then(data => this.setState({checkInData: data})).catch(console.error);
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {storeId: prevStoreId, checkInId: prevCheckInId} = prevProps;
    const {storeId, checkInId} = this.props;
    if (storeId !== prevStoreId || checkInId !== prevCheckInId) {
      fetchCheckInData(storeId, checkInId).then(data => this.setState({checkInData: data})).catch(console.error);
    }
  }
  render() {
    const {storeData, checkInData} = this.state ?? {};
    if (!(storeData && checkInData)) {
      return null;
    }
    const {title, address, phoneNo, website, gstNo} = storeData;
    const {invoices} = this.props;
    const {roomNo, roomType, checkIn, checkOut, invoiceNo} = checkInData;
    const {salesTotal: {net, gst, roomTax, gross}, paymentTotal, balance} = getBalance(invoices);
    const itemList = [...invoices.filter(i => i.amount > 0), ...invoices.filter(i => i.amount < 0)];
    const borderStyle = '1px solid #000';
    const billToLines = getBillToLines(checkInData);
    const tableStyle = {
      fontSize: '.85rem',
      color: 'black',
      width: '100%',
    };
    return (
      <Box sx={{
        display: 'none',
        displayPrint: 'block',
        p: '1cm',
        color: 'black',
        width: '100vw',
        height: '100vh',
      }}>
        <Stack direction={'column'} height={'100%'}>
          <Stack direction={'column'}>
            <Typography variant={'h4'} sx={{textTransform: 'uppercase', textAlign: 'center'}}>{title}</Typography>
            <Typography variant={'caption'} sx={{textAlign: 'center'}}>{address}</Typography>
            <Typography variant={'caption'} sx={{textAlign: 'center'}}>TEL: {phoneNo}</Typography>
            {/*<Typography variant={'caption'} sx={{textAlign: 'center'}}>FAX: {fax}</Typography>*/}
            <Typography variant={'caption'} sx={{textAlign: 'center'}}>{website}</Typography>
            <Typography variant={'caption'} sx={{textAlign: 'center'}}>GST#: {gstNo}</Typography>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'} my={'0.5cm'}>
            <Stack direction={'column'}>
              <Typography variant={'body1'} sx={{fontWeight: 'bold'}}>Bill To:</Typography>
              {billToLines.map((line, index) => <Typography key={index} variant={'caption'}>{line}</Typography>)}
            </Stack>
            <Stack direction={'column'} justifyContent={'flex-end'}>
              <Typography variant={'body1'} sx={{textAlign: 'end', fontWeight: 'bold'}}>Invoice# {invoiceNo}</Typography>
              <Typography variant={'caption'} sx={{textAlign: 'end'}}>Invoice Date {formatDateTime(Date.now())}</Typography>
              <Typography variant={'caption'} sx={{textAlign: 'end'}}>Room# {roomNo} ({roomType})</Typography>
              <Typography variant={'caption'} sx={{textAlign: 'end'}}>Checked In {formatDate(checkIn)}</Typography>
              <Typography variant={'caption'} sx={{textAlign: 'end'}}>Checked Out {formatDate(checkOut)}</Typography>
            </Stack>
          </Stack>
          {(invoices && invoices.length > 0) && (
            <Box borderTop={borderStyle} borderBottom={borderStyle} flex={1}>
              <table style={tableStyle}>
                <thead>
                <tr>
                  <Cell value={'Date'} header />
                  {/*<Cell value={'Staff'} header />*/}
                  <Cell value={'Item'} header />
                  <Cell value={'Note'} header />
                  <Cell value={'Amount'} header />
                </tr>
                </thead>
                <tbody>
                {itemList.map((item) => {
                  const {
                    id, itemTitle, amount, note, invoiceDate,
                    // createdAt,
                    // updatedAt,
                  } = item;
                  return (
                    <tr key={id}>
                      <Cell value={invoiceDate} center />
                      {/*<Cell value={updatedBy ?? createdBy} />*/}
                      <Cell value={itemTitle} />
                      <Cell value={note} />
                      <Cell value={formatCurrency(amount)} end />
                    </tr>
                  );
                })}
                </tbody>
              </table>
            </Box>
          )}
          {(invoices && invoices.length > 0) && (
            <Stack direction={'column'} justifyContent={'flex-end'} mt={'0.5cm'} pr={'0.25cm'} mb={'2cm'}>
              <Stack direction={'row'}>
                <Typography variant={'body1'} sx={{flex: 1, textAlign: 'end'}}>Sub Total</Typography>
                <Typography variant={'body1'} sx={{width: '150px', textAlign: 'end'}}>{formatCurrency(net)}</Typography>
              </Stack>
              {/*{taxList.map(({name, percentage}) => {*/}
              {/*  return (*/}
              {/*    <Stack direction={'row'} key={name}>*/}
              {/*      <Typography variant={'body1'} sx={{textAlign: 'end', flex: 1}}>{name} ({percentage.toFixed()} %)</Typography>*/}
              {/*      <Typography variant={'body1'} sx={{textAlign: 'end', width: '150px'}}>{formatCurrency(taxAmounts[name])}</Typography>*/}
              {/*    </Stack>*/}
              {/*  );*/}
              {/*})}*/}
              <Stack direction={'row'}>
                <Typography variant={'body1'} sx={{textAlign: 'end', flex: 1}}>GST ({(GST_RATE * 100).toFixed()} %)</Typography>
                <Typography variant={'body1'} sx={{textAlign: 'end', width: '150px'}}>{formatCurrency(gst)}</Typography>
              </Stack>
              <Stack direction={'row'}>
                <Typography variant={'body1'} sx={{textAlign: 'end', flex: 1}}>Room Tax ({(ROOM_TAX_RATE * 100).toFixed()} %)</Typography>
                <Typography variant={'body1'} sx={{textAlign: 'end', width: '150px'}}>{formatCurrency(roomTax)}</Typography>
              </Stack>
              <Stack direction={'row'}>
                <Typography variant={'body1'} sx={{textAlign: 'end', flex: 1}}>Total</Typography>
                <Typography variant={'h6'} sx={{textAlign: 'end', width: '150px'}}>{formatCurrency(gross)}</Typography>
              </Stack>
              <Stack direction={'row'}>
                <Typography variant={'body1'} sx={{textAlign: 'end', flex: 1}}>Payment Total</Typography>
                <Typography variant={'h6'} sx={{textAlign: 'end', width: '150px'}}>{formatCurrency(paymentTotal)}</Typography>
              </Stack>
              <Stack direction={'row'}>
                <Typography variant={'body1'} sx={{textAlign: 'end', flex: 1}}>Balance</Typography>
                <Typography variant={'h6'} sx={{textAlign: 'end', width: '150px'}}>{formatCurrency(balance)}</Typography>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Box>
    );
  }
}

function getBillToLines(checkInData) {
  const billToLines = [];
  const {name, email, phoneNo, companyData, companyId} = checkInData;
  if (!companyId) {
    if (name) billToLines.push(name);
    if (email) billToLines.push(email);
    if (phoneNo) billToLines.push(phoneNo);
  } else {
    const {company, address, city, province, postalCode, poNo} = companyData;
    const address2 = [city, province, postalCode].filter(i => !!i).join(', ');
    billToLines.push(company);
    address && billToLines.push(address);
    address2 && billToLines.push(address2);
    poNo && billToLines.push(`PO# : ${poNo}`);
  }
  return billToLines;
}

function Cell({value, center, end, header}) {
  const align = center ? 'center' : end ? 'end' : undefined;
  if (header) {
    return (
      <th>
        <Box fontSize={'.85rem'} p={'4px'} textAlign={'center'} borderBottom={'1px solid #000'}>{value}</Box>
      </th>
    );
  }
  return (
    <td align={align}>
      <Box fontSize={'.85rem'} p={'4px'}>{value}</Box>
    </td>
  );
}

export default InvoicePrint;
