import React from 'react';
import PropTypes from 'prop-types';
import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
import { useTheme } from '@mui/material/styles';
import { Card, CardHeader, Stack, Box, Typography } from '@mui/material';
import { fNumber } from '../../../utils/formatNumber';
import { houseKeepingOptions, houseKeepingTextColors } from '../../utils/options';
import { BaseOptionChart } from '../charts';

// ----------------------------------------------------------------------

// const CHART_DATA = [75];
// const SOLD_OUT = 120;
// const AVAILABLE = 66;

Legend.propTypes = {
  label: PropTypes.string,
  number: PropTypes.number,
};

function Legend({ statusCode, number }) {
  const label = houseKeepingOptions.find(i => i.value === statusCode).label;
  const bgcolor = houseKeepingTextColors[statusCode];
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="row" alignItems="center" spacing={1}>
        <Box
          sx={{
            width: 16,
            height: 16,
            bgcolor,
            borderRadius: 0.75,
            // ...(label === 'Sold out' && {
            //   bgcolor: 'primary.main',
            // }),
          }}
        />
        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
          {label}
        </Typography>
      </Stack>
      <Typography variant="subtitle1">{number} Rooms</Typography>
    </Stack>
  );
}

const stateList = {
  O: {type: 'O', label: 'OCCUPIED', color: 'success'},
  V: {type: 'V', label: 'VACANT', color: 'info'},
  H: {type: 'H', label: 'HOUSEKEEPING', color: 'warning'},
};

export default function BookingRoomAvailable({totalRoomCount, roomCountList}) {
  const [state, setState] = React.useState(stateList.O);
  React.useEffect(() => {
    const id = setInterval(() => {
      setState(state.type === 'O' ? stateList.V : state.type === 'H' ? stateList.O : stateList.H);
    }, 5000);
    return () => clearInterval(id);
  }, [state]);
  const theme = useTheme();
  const chartOptions = merge(BaseOptionChart(), {
    legend: { show: false },
    grid: {
      padding: { top: -32, bottom: -32 },
    },
    fill: {
      type: 'gradient',
      gradient: {
        colorStops: [
          [
            { offset: 0, color: theme.palette[state.color].light },
            { offset: 100, color: theme.palette[state.color].main },
          ],
        ],
      },
    },
    plotOptions: {
      radialBar: {
        hollow: { size: '64%' },
        dataLabels: {
          name: { offsetY: -16 },
          value: { offsetY: 8 },
          total: {
            label: state.label,
            formatter: () => (roomCountList[state.type] ?? 0) + '/' + fNumber(totalRoomCount),
          },
        },
      },
    },
  });
  const chartData = [
    (roomCountList[state.type] ?? 0) / totalRoomCount * 100,
  ];
  return (
    <Card>
      <CardHeader title="Room Availability" sx={{mb: 2}} />
      <ReactApexChart type="radialBar" series={chartData} options={chartOptions} height={310} />
      <Stack spacing={2} sx={{ p: 4 }}>
        <Legend statusCode={'O'} number={roomCountList['O'] ?? 0} />
        <Legend statusCode={'V'} number={roomCountList['V'] ?? 0} />
        <Legend statusCode={'H'} number={roomCountList['H'] ?? 0} />
        <Legend statusCode={'D'} number={roomCountList['D'] ?? 0} />
        <Legend statusCode={'M'} number={roomCountList['M'] ?? 0} />
        <Legend statusCode={'B'} number={roomCountList['B'] ?? 0} />
      </Stack>
    </Card>
  );
}
