import React from 'react';
import FirestoreListTable from './FirestoreListTable';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import useStoreId from '../hooks/useStoreId';
import {
  defMapRows,
  deleteInvoiceItem,
  formatCurrency,
  getBalance,
  getTotalOfSalesItems,
  getTransactionListQuery,
  gridCurrencyFormatter,
  gridTaxableFormatter
} from '../utils/utils';
import { DeleteButton } from '../components/icon-buttons';
import Confirm, { useConfirm } from '../components/Confirm';
import InvoicePrint from '../print-pages/InvoicePrint';
import TransactionForm from '../forms/TransactionForm';
import { GST_RATE, itemsCannotBeDeleted, ROOM_TAX_RATE } from '../utils/options';
import useAuth from '../hooks/useAuth';
import { useStoreCol } from '../utils/fire-v8';

const TransactionListTable = ({checkInId, hideActionColumn, readOnly, isBilling}) => {
  const storeId = useStoreId();
  const {user} = useAuth();
  const {confirmProps, openConfirm} = useConfirm();
  const printRef = React.useRef();
  const handlePrint = useReactToPrint({content: () => printRef.current});
  const isSuper = user.role === 'super';
  const [salesItems = []] = useStoreCol('sales', (colRef) => colRef.where('checkInId', '==', checkInId));
  const salesTotal = getTotalOfSalesItems(salesItems);
  function getColumns() {
    const columns = [
      { field: 'invoiceDate', headerName: 'Date', flex: 0.7},
      { field: 'staff', headerName: 'Staff', valueGetter: ({row: {createdName, updatedName}}) => `${updatedName ?? createdName}`},
      { field: 'itemTitle', headerName: 'Item', flex: 1.7, valueGetter: ({row: {itemTitle, note}}) => `${itemTitle}${note ? ` (${note})` : ''}` },
      { field: 'amount', headerName: 'Amount', flex: 0.7, headerAlign: 'center', align: 'right', valueGetter: gridCurrencyFormatter },
      { field: 'taxable', headerName: 'Tax', flex: 0.7, headerAlign: 'center', align: 'center', valueGetter: gridTaxableFormatter },
    ];
    // 수퍼어드민인 경우 삭제 메뉴 항상 보여줌!
    if (user.role !== 'super' && (hideActionColumn === true || readOnly === true)) {
      return columns;
    }
    return [
      ...columns,
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 0.7,
        renderCell: ({row}) => {
          const {itemCode} = row;
          if (itemsCannotBeDeleted.includes(itemCode)) {
            return null;
          }
          return <DeleteButton onClick={() => {
            openConfirm(
              'Are sure you want to delete the invoice item?',
              () => deleteInvoiceItem(storeId, checkInId, row.id, itemCode),
              'The invoice item has been deleted successfully!'
            );
          }} />;
        },
      }
    ];
  }
  return (
    <FirestoreListTable
      query={getTransactionListQuery(storeId, checkInId)}
      columns={getColumns()}
      mapRows={(snapshot) => {
        const rows = defMapRows(snapshot);
        return [...rows.filter(i => i.amount > 0), ...rows.filter(i => i.amount < 0)];
      }}
      renderFooter={(rows) => {
        const {salesTotal: {net, gst, roomTax, gross}, paymentTotal, balance} = getBalance(rows);
        // devLog('InvoiceListTable', {net, gst, roomTax, gross, paymentTotal, balance});
        return (
          <Stack direction={'column'} spacing={2} mt={2}>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Stack>
                <Button variant={'outlined'} color={'success'} onClick={handlePrint}>
                  PRINT INVOICE
                </Button>
              </Stack>
              <Stack direction={'column'} alignItems={'flex-end'}>
                <Typography variant={'overline'} sx={{color: 'text.disabled'}}>Sub total: <CurrencyLabel amount={net} amountAlt={isSuper ? salesTotal.net : ''} /></Typography>
                <Typography variant={'overline'} sx={{color: 'text.disabled'}}>GST ({(GST_RATE * 100).toFixed()} %): <CurrencyLabel amount={gst} amountAlt={isSuper ? salesTotal.gst : ''} /></Typography>
                <Typography variant={'overline'} sx={{color: 'text.disabled'}}>Room Tax ({(ROOM_TAX_RATE * 100).toFixed()} %): <CurrencyLabel amount={roomTax} amountAlt={isSuper ? salesTotal.roomTax : ''} /></Typography>
                <Typography variant={'overline'} sx={{ fontSize: '1rem', color: 'info.main'}}>Total: <CurrencyLabel amount={gross} amountAlt={isSuper ? salesTotal.gross : ''} /></Typography>
                <Typography variant={'overline'} sx={{ fontSize: '1rem', color: 'primary.main'}}>Payment total: <CurrencyLabel amount={paymentTotal} /></Typography>
                <Typography variant={'overline'} sx={{ fontSize: '1rem', color: balance === 0 ? 'success.main' : 'error.main'}}>Balance: <CurrencyLabel amount={balance} /></Typography>
              </Stack>
            </Stack>
            {(!readOnly || isBilling || isSuper) && <TransactionForm checkInId={checkInId} />}
            <Confirm {...confirmProps} />
            <InvoicePrint ref={printRef} storeId={storeId} checkInId={checkInId} invoices={rows} />
          </Stack>
        );
      }}
      doNotUseCard
      containerSx={{mt: 0, pb:2}}
      hideFooter
      disableSelectionOnClick
    />
  );
};

function CurrencyLabel({amount, amountAlt}) {
  return <Box display={'inline-block'} textAlign={'right'} width={amountAlt ? '240px' : '120px'}>{formatCurrency(amount)}{amountAlt ? ` (${formatCurrency(amountAlt)})` : ''}</Box>;
}

export default TransactionListTable;
