import { useStoreCol } from '../utils/fire-v8';

export default function useRoomList() {
  return useStoreCol('rooms');
  // const storeId = useStoreId();
  // const query = storeId ? firebase.firestore().collection(`stores/${storeId}/rooms`) : null;
  // const [snapshot, loading, error] = useCollection(query);
  // return React.useMemo(() => {
  //   return [snapshot?.docs.map((doc) => ({ ...doc.data(), id: doc.id })) ?? [], loading, error];
  // }, [snapshot, loading, error]);
}
