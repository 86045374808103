import firebase from 'firebase/app';
import 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import useAuth from './useAuth';

export default function useRoomTypeList() {
  const { user } = useAuth();
  const query = user?.storeId ? firebase.firestore().collection(`stores/${user.storeId}/room-types`) : null;
  const [snapshot, loading, error] = useCollection(query);
  return [snapshot?.docs.map((doc) => ({ ...doc.data() })) ?? [], loading, error];
}
