import React from 'react';
import PageContainer from '../../components/PageContainer';
import CompanyCRUDTable from '../../data-tables/CompanyCRUDTable';

export default function CompanyList() {
  return (
    <PageContainer title="Company List">
      <CompanyCRUDTable />
    </PageContainer>
  );
}
