import React from 'react';
import { useStoreCol } from '../utils/fire-v8';
import StatusSelect from '../components/StatusSelect';
import StatusCell from '../components/StatusCell';
import { setStoreDoc } from '../utils/utils';
import useAsyncStatus from '../hooks/useAsyncStatus';
import useStoreId from '../hooks/useStoreId';
import BasicTable from './BasicTable';

const ExpediaMailListTable = () => {
  const storeId = useStoreId();
  const [status, setStatus] = React.useState('all');
  const execWithFeedback = useAsyncStatus();
  const columns = [
    { field: 'date', headerName: 'Date' },
    { field: 'from', headerName: 'From' },
    { field: 'subject', headerName: 'Subject', flex: 3 },
    {
      field: 'status',
      headerName: 'Status',
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ row }) => (
        <StatusCell
          status={row.status}
          onChange={(newStatus) => {
            if (row.status !== newStatus) {
              const { id: docId } = row;
              execWithFeedback(
                setStoreDoc(storeId, 'expedia-mails', {status: newStatus}, docId),
                'Status has been updated successfully!'
              );
            }
          }}
        />
      ),
    },
  ];
  const [rows = [], loading, error] = useStoreCol('expedia-mails', (colRef) => {
    let query = colRef.orderBy('createdAt', 'desc');
    if (status !== 'all') {
      query = query.where('status', '==', status);
    }
    return query;
  });
  return (
    <BasicTable
      rows={rows}
      loading={loading}
      error={error}
      columns={columns}
      // renderLeftHeader={() => <DateRange {...dateRangeProps} size="small" />}
      renderRightHeader={() => <StatusSelect status={status} setStatus={setStatus} />}
      containerSx={{p: 2}}
    />
  );
};

export default ExpediaMailListTable;
