import React from 'react';
import RoomCRUDTable from '../../data-tables/RoomCRUDTable';
import PageContainer from '../../components/PageContainer';

export default function RoomList() {
  return (
    <PageContainer title="Room List">
      <RoomCRUDTable />
    </PageContainer>
  );
}
