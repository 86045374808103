const enLocales = {
  // ==========================================================================
  techcubePMS: 'TechCube PMS',
  pmsTitle: 'TechCube PMS - Property Management System',
  // ==========================================================================
  login: 'Login',
  email: 'Email address',
  password: 'Password',
  or: 'OR',
  loginToPMS: 'Sign in to TechCube PMS',
  loginWithGoogle: 'Login with Google',
  loginSuccess: 'You have been signed in successfully!',
  failedToLogin: 'Failed to sign in to TechCube PMS!',
  needValidEmail: 'Please enter a valid email address',
  needEmail: 'Email is required',
  needPassword: 'Password is required',
  // ==========================================================================
  settings: 'Settings',
  mode: 'Mode',
  color: 'Color',
  stretch: 'Stretch',
  fullscreen: 'Fullscreen',
  exitFullscreen: 'Exit Fullscreen',
  // ==========================================================================
  last: '',
};

export default enLocales;
