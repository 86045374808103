import React from 'react';
import { MenuItem, TextField } from '@mui/material';
import { statusOptions } from '../utils/options';

export default function StatusSelect({ status, setStatus }) {
  return (
    <TextField
      size="small"
      label="Status"
      sx={{ mr: 2, minWidth: '200px' }}
      select
      value={status}
      onChange={(e) => {
        setStatus(e.target.value);
      }}
    >
      <MenuItem value="all">All</MenuItem>
      {statusOptions.map(({ value, label }) => (
        <MenuItem value={value} key={value}>
          {label}
        </MenuItem>
      ))}
    </TextField>
  );
}
