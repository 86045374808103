import React from 'react';
import FirestoreCRUDTable from './FirestoreCRUDTable';
import { formatDateTime, getStoreQuery } from '../utils/utils';
import { DeleteButton } from '../components/icon-buttons';
import useStoreId from '../hooks/useStoreId';
import { StandaloneInputField } from '../forms/InputField';
import CustomerForm from '../forms/CustomerForm';
import fire from '../utils/fire-v8';
import Confirm, { useConfirm } from '../components/Confirm';

const CustomerCRUDTable = () => {
  const {confirmProps, openConfirm} = useConfirm();
  const tableRef = React.useRef();
  const [search, setSearch] = React.useState('');
  const storeId = useStoreId();
  let query = storeId ? getStoreQuery(storeId, 'customers') : null;
  if (query && search.length >= 2) {
    query = query.where('tags', 'array-contains', search.toLowerCase());
  }
  if (query) {
    query = query.orderBy('updatedAt', 'desc').limit(100);
  }
  function onDelete(row) {
    openConfirm(
      'Are you sure you want to delete?',
      () => fire.storeDoc('customers', row.id).delete(),
      'Customer has been deleted successfully!'
    );
  }
  return (
    <FirestoreCRUDTable
      ref={tableRef}
      query={query}
      columns={[
        { field: 'name', headerName: 'Name' },
        { field: 'email', headerName: 'Email' },
        { field: 'phoneNo', headerName: 'Phone No.' },
        { field: 'sex', headerName: 'Sex' },
        // { field: 'dob', headerName: 'DOB' },
        { field: 'city', headerName: 'City' },
        {
          field: 'createdAt',
          headerName: 'Updated At',
          valueGetter: ({ row }) => formatDateTime(row.updatedAt ?? row.createdAt),
        },
      ]}
      title="CUSTOMER"
      Form={CustomerForm}
      renderLeftHeader={() => {
        return (
          <StandaloneInputField
            label={'Search Customer'}
            value={search}
            onChange={({target: {value}}) => setSearch(value)}
          />
        );
      }}
      hideDeleteButton
      // hideEditButton
      formDialogMaxWidth={'md'}
      renderActionButtons={(row) => {
        return [
          <DeleteButton key={'delete'} onClick={() => onDelete(row)} />,
        ];
      }}
      renderFooter={() => (
        <>
          <Confirm {...confirmProps} />
        </>
      )}
      placeActionColumnFirst
      actionColDef={{minWidth: 160}}
    />
  );
};

export default CustomerCRUDTable;
