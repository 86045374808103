import React, { useRef } from 'react';
import { MenuItem, Stack } from '@mui/material';
import { sentenceCase } from 'change-case';
import { useTheme } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import moreVerticalFill from '@iconify/icons-eva/edit-fill';
import { statusColors, statusOptions } from '../utils/options';
import Label from './Label';
import MIconButton from './MIconButton';
import MenuPopover from './MenuPopover';

export default function StatusCell({ status, onChange }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = useRef(null);
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const handleChange = (value) => {
    setOpen(false);
    onChange(value);
  };
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Label variant={isLight ? 'ghost' : 'filled'} color={statusColors[status]} sx={{ mr: 1 }}>
        {sentenceCase(status)}
      </Label>
      <MIconButton ref={anchorRef} onClick={() => setOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </MIconButton>
      <MenuPopover open={open} onClose={() => setOpen(false)} anchorEl={anchorRef.current} sx={{ py: 1 }}>
        {statusOptions.map(({ value, label }) => (
          <MenuItem key={value} value={value} selected={value === status} onClick={() => handleChange(value)}>
            {label}
          </MenuItem>
        ))}
      </MenuPopover>
    </Stack>
  );
}
