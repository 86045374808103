import React from 'react';
import { getRoomRateInfo } from '../utils/utils';

export default function useRoomRateCalculator(editMode, dateRangeProps, formik, roomTypes) {
  const [calcHint, setCalcHint] = React.useState('');
  const [showApplyCorpRate, setShowApplyCorpRate] = React.useState(false);
  const [from, to] = dateRangeProps.dateRange;
  const {roomType, rateType, adults, applyCorpRate, noTax} = formik.values;
  React.useEffect(() => {
    const roomTypeData = roomTypes?.find(i => i.value === roomType);
    if (!roomTypeData) {
      return;
    }
    const {
      roomRateInfo,
      hint = '',
      totalDays,
      newRateType,
      newRoomRate,
      showApplyCorpRate,
      dailyRate,
    } = getRoomRateInfo(from, to, roomTypeData, rateType === 'Corporate', adults, applyCorpRate, noTax);
    setCalcHint(hint);
    setShowApplyCorpRate(showApplyCorpRate);
    formik.setValues({
      ...formik.values,
      roomRateInfo,
      roomRate: newRoomRate,
      rateType: newRateType,
      roomRateDaily: dailyRate,
      totalDays,
    });
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [editMode, from, to, roomType, rateType, adults, applyCorpRate, noTax]);
  return [calcHint, showApplyCorpRate];
}
