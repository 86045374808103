import React from 'react';
import { Stack } from '@mui/material';
import * as yup from 'yup';
import InputField, { CustomerSearchField } from './InputField';
import {
  createTags,
  devLog,
  formatDate,
  getData,
  getDocId,
  getFormikValues,
  handleSelectCustomer,
  setStoreDoc
} from '../utils/utils';
import ClearFieldsButton from './ClearFieldsButton';
import DateField from './DateField';
import useForm from '../hooks/useForm';
import useStoreId from '../hooks/useStoreId';
import FormButtons from './FormButtons';
import { sexOptions } from '../utils/options';

const customerSchema = yup.object().shape({
  name: yup.string().required('Please enter the name.'),
  email: yup.string().email('Please enter the valid email.'),
});

const defCustomerData = {
  id: '',
  name: '',
  email: '',
  phoneNo: '',
  sex: '',
  dob: '',
  city: '',
  licenseNo: '',
  passportNo: '',
  otherIdNo: '',
  carInfo: '',
  carColor: '',
  plateNo: '',
  cardNo: '',
  cardHolder: '',
  cardExp: '',
  cardCvv: '',
  note: '',
};

const customerKeys = Object.keys(defCustomerData);

const CustomerForm = ({editData, editMode, onCloseDialog}) => {
  const storeId = useStoreId();
  const formik = useForm({
    editData: {...defCustomerData, ...getCustomerData(editData, false)},
    editMode,
    validationSchema: customerSchema,
    onSubmit: async (values) => {
      devLog('CustomerForm.onSubmit()', values);
      const docId = getDocId(editData, 'customerId');
      const data = getCustomerData(values, false);
      if (editMode && !docId) {
        throw new Error();
      }
      data['tags'] = createTags(data);
      await setStoreDoc(storeId, 'customers', data, docId);
      if (editMode) {
        onCloseDialog?.();
      }
    },
    message: 'Customer information has been saved successfully.',
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <CustomerFields formik={formik} minHeight={250} hideNote useInputFieldForName />
      <FormButtons formik={formik} label={'CUSTOMER'} editMode={editMode} onCloseDialog={onCloseDialog} />
    </form>
  );
};

export const CheckInCustomerForm = ({formik, minHeight = 400, hide = false}) => {
  return <CustomerFields formik={formik} minHeight={minHeight} hide={hide} />;
};

const CustomerFields = ({formik, minHeight, hide, hideNote, useInputFieldForName}) => {
  return (
    <Stack direction="column" spacing={2} pt={2} minHeight={minHeight} display={hide ? 'none' : 'flex'}>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="flex-start">
        {useInputFieldForName && <InputField name="name" label="Name" formik={formik} />}
        {!useInputFieldForName && <CustomerSearchField name="name" label="Name" formik={formik} onSelectCustomer={(row) => handleSelectCustomer(formik, row)} />}
        <InputField name="email" label="Email" formik={formik} />
        <InputField name="phoneNo" label="Phone No." formik={formik} />
        <ClearFieldsButton formik={formik} fields={['name', 'email', 'phoneNo', 'id', 'customerId']} />
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="flex-start">
        <InputField name="sex" label="Sex" formik={formik} options={sexOptions} />
        <DateField name="dob" label="DOB" formik={formik} initialDate={formik.values['dob']} onChange={(date) => formik.setFieldValue('dob', formatDate(date))} />
        <InputField name="city" label="City" formik={formik} />
        <ClearFieldsButton formik={formik} fields={['sex', 'dob', 'city']} />
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="flex-start">
        <InputField name="licenseNo" label="Driver's License No." formik={formik} />
        <InputField name="passportNo" label="Passport No." formik={formik} />
        <InputField name="otherIdNo" label="Other ID No." formik={formik} />
        <ClearFieldsButton formik={formik} fields={['licenseNo', 'passportNo', 'otherIdNo']} />
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="flex-start">
        <InputField name="carInfo" label="Car Info" formik={formik} />
        <InputField name="carColor" label="Car Color" formik={formik} />
        <InputField name="plateNo" label="Plate No." formik={formik} />
        <ClearFieldsButton formik={formik} fields={['carInfo', 'carColor', 'plateNo']} />
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="flex-start" mt={2}>
        <InputField name="cardNo" label="Card No." formik={formik} />
        <InputField name="cardHolder" label="Card Holder" formik={formik} />
        <InputField name="cardExp" label="Card Exp." formik={formik} width={100} />
        <InputField name="cardCvv" label="Card CVV" formik={formik} width={100} />
        <ClearFieldsButton formik={formik} fields={['cardNo', 'cardHolder', 'cardExp', 'cardCvv']} />
      </Stack>
      {!hideNote && (
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="flex-start">
          <InputField name="note" label="Note" formik={formik} />
          <ClearFieldsButton formik={formik} fields={['note']} />
        </Stack>
      )}
    </Stack>
  );
};

function getCustomerData(values, includeDocId, nameOfDocId) {
  const data = getData(values, customerKeys);
  if (!includeDocId) {
    return data;
  }
  const docId = getDocId(values, nameOfDocId);
  return [docId, data];
}

export function useCustomerForm(editData, editMode) {
  const formik = useForm({
    editData: {...defCustomerData, ...getCustomerData(editData, false)},
    editMode,
    validationSchema: customerSchema,
    onSubmit: async (values) => {
      const [docId, customerData] = getCustomerData(values, true, 'customerId');
      devLog('CustomerInfoForm.onSubmit()', docId, customerData);
    },
    message: 'Customer information has been saved successfully.',
  });
  return {
    formik,
    getData: () => getFormikValues(formik, defCustomerData, true),
  };
}

export function useCheckInCustomerForm(checkInData, editMode) {
  const {customerId} = checkInData;
  const formik = useForm({
    editData: {...defCustomerData, ...getCustomerData(checkInData, false), customerId},
    editMode,
    validationSchema: customerSchema,
    onSubmit: async (values) => {
      const customerData = getCustomerData(values, false);
      devLog('CustomerInfoForm.onSubmit()', customerId, customerData);
    },
    message: 'Customer information has been saved successfully.',
  });
  return {
    formik,
    getData: () => getFormikValues(formik, defCustomerData, true),
  };
}

export default CustomerForm;
