import React from 'react';
import { Box, Card, Stack } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { mapDefColDef } from '../utils/utils';

/** @typedef {import('@mui/material/Box').BoxProps} BoxProps */
/** @typedef {import('@mui/material/Box').CardProps} CardProps */
/** @typedef {import('@mui/x-data-grid').DataGridProps} DataGridProps */
/** @typedef {Partial<Omit<DataGridProps, 'columns' | 'rows' | 'loading' | 'error'>>} OtherDataGridProps */
/** @typedef {(rows: DataGridProps['rows']) => JSX.Element} BasicTableRenderFunc */

/**
 * @typedef {{
 *   columns: DataGridProps['columns'] | (() => DataGridProps['columns']),
 *   rows: Record<string, any>[],
 *   loading: boolean,
 *   error?: Error | firebase.FirebaseError,
 *   renderHeader?: BasicTableRenderFunc,
 *   renderLeftHeader?: BasicTableRenderFunc,
 *   renderRightHeader?: BasicTableRenderFunc,
 *   renderFooter?: BasicTableRenderFunc,
 *   renderLeftFooter?: BasicTableRenderFunc,
 *   renderRightFooter?: BasicTableRenderFunc,
 *   doNotUseCard?: boolean,
 *   containerSx?: BoxProps['sx'] | CardProps['sx'],
 * }} BasicTableProps
 */

/**
 * @param {BasicTableProps & OtherDataGridProps} props
 * @return {JSX.Element}
 * @constructor
 */
const BasicTable = (props) => {
  const {
    columns,
    rows,
    loading,
    error,
    renderHeader,
    renderLeftHeader,
    renderRightHeader,
    renderFooter,
    renderLeftFooter,
    renderRightFooter,
    doNotUseCard,
    containerSx,
    ...otherGridProps
  } = props;
  /** @type {DataGridProps} */
  const gridProps = {
    columns: (typeof columns === 'function' ? columns() : columns).map(mapDefColDef),
    rows,
    loading,
    error,
    density: 'compact',
    autoHeight: true,
    rowsPerPageOptions: [60],
    pageSize: 60,
    ...otherGridProps,
  };
  const Container = doNotUseCard ? Box : Card;
  return (
    <Container sx={containerSx}>
      {renderHeader?.(rows)}
      {(renderLeftHeader || renderRightHeader) && (
        <Stack direction="row" justifyContent="space-between" spacing={2} p={1}>
          <Stack direction="row">{renderLeftHeader?.(rows)}</Stack>
          <Stack direction="row" justifyContent="flex-end">{renderRightHeader?.(rows)}</Stack>
        </Stack>
      )}
      <DataGrid {...gridProps} />
      {renderFooter?.(rows)}
      {(renderLeftFooter || renderRightFooter) && (
        <Stack direction="row" justifyContent="space-between" spacing={2} p={1}>
          <Stack direction="row">{renderLeftFooter?.(rows)}</Stack>
          <Stack direction="row" justifyContent="flex-end">{renderRightFooter?.(rows)}</Stack>
        </Stack>
      )}
    </Container>
  );
};

export default BasicTable;
