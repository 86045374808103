import React, { useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react'; // => request placed at the top
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import interactionPlugin from '@fullcalendar/interaction';
import { Icon } from '@iconify/react';
import loginIcon from '@iconify/icons-ant-design/check-circle-outline';
import logoutIcon from '@iconify/icons-ant-design/minus-circle-outline';
import formIcon from '@iconify/icons-ant-design/info-circle-outline';
import { useTheme } from '@mui/material/styles';
import { Box, Card, CircularProgress, MenuItem, Stack, Typography, useMediaQuery } from '@mui/material';
import { addDays, addMonths, endOfDay, endOfWeek, startOfDay, startOfWeek, subMonths } from 'date-fns';
import { CalendarStyle, CalendarToolbar } from '../../components/calendar';
import useCheckInList from '../../hooks/useCheckInList';
import useBookingList from '../../hooks/useBookingList';
import './CalendarView.css';
import PageContainer from '../../components/PageContainer';
import moreVerticalFill from '@iconify/icons-eva/more-horizontal-fill';
import MenuPopover from '../../components/MenuPopover';
import FormDialog, { useFormDialog } from '../../forms/FormDialog';
import Confirm, { useConfirm } from '../../components/Confirm';
import CheckInForm from '../../forms/CheckInForm';
import { fetchBookingData, fetchCheckInData, setStoreDoc } from '../../utils/utils';
import useStoreId from '../../hooks/useStoreId';
import BookingForm from '../../forms/BookingForm';
import MIconButton from '../../components/MIconButton';

export default function CalendarView() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const calendarRef = useRef(null);
  const [date, setDate] = useState(new Date());
  const [view, setView] = useState(isMobile ? 'listWeek' : 'dayGridMonth');
  const { from, to } = getFromTo(date, view);
  const [checkInList = [], checkInLoading, checkInError] = useCheckInList(from, to);
  const [bookingList, bookingLoading, bookingError] = useBookingList(from, to, true);
  const {confirmProps, openConfirm} = useConfirm();
  const {props: checkInDialogProps, open: openCheckInDialog} = useFormDialog();
  const {props: bookingDialogProps, open: openBookingDialog} = useFormDialog();
  // const {props: invoiceDialogProps, open: openInvoiceDialog} = useInvoiceDialog();
  // const {props: checkOutConfirmDialogProps, open: openCheckOutConfirmDialog} = useCheckoutConfirmDialog();
  const events = toEventList(checkInList, bookingList, {
    openConfirm, openCheckInDialog, openBookingDialog,
  });
  if (checkInError) {
    console.error(checkInError);
  }
  if (bookingError) {
    console.error(bookingError);
  }
  useEffect(() => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      const newView = isMobile ? 'listWeek' : 'dayGridMonth';
      calendarApi.changeView(newView);
      setView(newView);
    }
  }, [isMobile]);

  const handleClickToday = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.today();
      setDate(calendarApi.getDate());
    }
  };

  const handleChangeView = (newView) => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.changeView(newView);
      setView(newView);
    }
  };

  const handleClickDatePrev = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  };

  const handleClickDateNext = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  };

  if (checkInLoading || bookingLoading) {
    return (
      <PageContainer title={'Calendar View'}>
        <Card sx={{minHeight: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <CircularProgress />
        </Card>
      </PageContainer>
    );
  }

  return (
    <PageContainer title={'Calendar View'}>
      <Card>
        <Stack direction={'row'} spacing={'4px'} py={1} pl={2} alignItems={'center'}>
          <Icon icon={formIcon} width={18} />
          <Typography variant={'body2'}>Booking</Typography>
          <Typography variant={'body2'}>|</Typography>
          <Icon icon={loginIcon} width={18} />
          <Typography variant={'body2'}>Check In</Typography>
          <Typography variant={'body2'}>|</Typography>
          <Icon icon={logoutIcon} width={18} />
          <Typography variant={'body2'}>Check Out</Typography>
        </Stack>
        <CalendarStyle>
          <CalendarToolbar
            date={date}
            view={view}
            onNextDate={handleClickDateNext}
            onPrevDate={handleClickDatePrev}
            onToday={handleClickToday}
            onChangeView={handleChangeView}
          />
          <FullCalendar
            weekends
            // editable
            // droppable
            // selectable
            events={events}
            ref={calendarRef}
            rerenderDelay={10}
            initialDate={date}
            initialView={view}
            dayMaxEventRows={500}
            eventDisplay="block"
            headerToolbar={false}
            allDayMaintainDuration
            eventResizableFromStart
            // select={handleSelectRange}
            // eventDrop={handleDropEvent}
            // eventClick={handleSelectEvent}
            // eventResize={handleResizeEvent}
            height={isMobile ? 'auto' : 'auto'}
            plugins={[listPlugin, dayGridPlugin, timelinePlugin, timeGridPlugin, interactionPlugin]}
            eventContent={renderEventContent}
          />
        </CalendarStyle>
      </Card>
      <Confirm {...confirmProps} />
      <FormDialog {...checkInDialogProps} maxWidth={'md'} title={'CHECK-IN'} Form={CheckInForm} />
      <FormDialog {...bookingDialogProps} maxWidth={'md'} title={'BOOKING'} Form={BookingForm} />
    </PageContainer>
  );
}

/**
 * @param {EventContentArg} eventInfo
 */
function renderEventContent(eventInfo) {
  const title = eventInfo.event.title;
  const id = eventInfo.event.id;
  const groupId = eventInfo.event.groupId;
  const view = eventInfo.view.type;
  const {type, bgcolor, icon, helpers, bookingType} = eventInfo.event.extendedProps;
  return <EventItem {...{groupId, id, type, title, bgcolor, icon, view, helpers, bookingType}} />;
}

function EventItem({groupId, id, type, title, icon, bgcolor, view, helpers, bookingType}) {
  const storeId = useStoreId();
  const [open, setOpen] = React.useState(false);
  const anchorRef = useRef(null);
  const isListWeek = view === 'listWeek';
  const bookId = bookingType === 'group' ? groupId : id;
  async function onEditCheckIn() {
    setOpen(false);
    const checkInData = await fetchCheckInData(storeId, id);
    helpers.openCheckInDialog(checkInData, true);
  }
  async function onEditBooking() {
    setOpen(false);
    const bookingData = await fetchBookingData(storeId, bookId);
    helpers.openBookingDialog(bookingData, true);
  }
  async function onDeleteBooking() {
    const doublebookingDelete = (bookingData) => {
      return (
        helpers.openConfirm(
          'Are you sure you want to cancel this booking?',
          () => setStoreDoc(storeId, 'bookings', bookingData, groupId),
          'Booking has been cancelled!'
        )
      );
    };
    const singleBookingDelete = () => {
      return (
        helpers.openConfirm(
          'Are you sure you want to cancel this booking?',
          () => setStoreDoc(storeId, 'bookings', {status: 'cancelled'}, bookId),
          'Booking has been cancelled!'
        )
      );
    };
    if (bookingType === 'group') {
      const bookingData = await fetchBookingData(storeId, groupId);
      const {groupRooms, roomCount} = bookingData;
      if (roomCount === '1') {
        singleBookingDelete();
      } else {
        let resGroupRooms = [];
        for (let i=0; i<roomCount; i++) {
          if (i !== parseInt(id)) {
            resGroupRooms.push(groupRooms?.[i]);
          }
        }
        let newGrouprooms = {};
        for (let i=0; i<resGroupRooms.length; i++) {
          newGrouprooms = {...newGrouprooms, [i]: resGroupRooms[i]};
        }
        const groupCheckInFlags = {};
        for (const key of Object.keys(newGrouprooms)) {
          groupCheckInFlags[key] = false;
        }
        bookingData.groupCheckInFlags = groupCheckInFlags;
        bookingData.roomCount = (roomCount - 1).toString();
        bookingData.groupRooms = newGrouprooms;
        doublebookingDelete(bookingData);
      }
    } else {
      singleBookingDelete();
    }
  }
  async function onCheckIn() {
    setOpen(false);
    const bookingData = await fetchBookingData(storeId, bookId);
    const bookingId = bookingData.id;
    const checkInData = {
      ...bookingData,
      id: '',
      status: 'checked-in',
      bookingId
    };
    helpers.openCheckInDialog(checkInData, false);
  }
  // async function onCheckOut() {
  //   setOpen(false);
  //   const checkInData = await fetchCheckInData(storeId, id);
  //   helpers.openCheckOutConfirmDialog(checkInData);
  //   // helpers.openConfirm(
  //   //   'Are you sure to check out?',
  //   //   () => setStoreDoc(storeId, 'check-ins', {status: 'checked-out'}, id),
  //   //   'Checked out successfully!'
  //   // );
  // }
  // async function onInvoice() {
  //   setOpen(false);
  //   const checkInData = await fetchCheckInData(storeId, id);
  //   helpers.openInvoiceDialog(checkInData);
  // }
  async function onDeleteCheckIn() {
    helpers.openConfirm(
      'Are you sure you want to delete this check-in?',
      () => setStoreDoc(storeId, 'check-ins', {status: 'cancelled'}, id),
      'Check-in has been deleted!'
    );
  }
  return (
    <Stack direction={'row'} bgcolor={bgcolor} px={'4px'} alignItems={'center'}>
      <Icon icon={icon} color={'white'} width={18} height={18} />
      <Typography
        variant={'body2'}
        sx={{
          pl: '4px',
          fontSize: '.8rem',
          color: 'white',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {title}
      </Typography>
      <Box sx={{
        position: 'absolute',
        right: isListWeek ? 16 : 0,
        zIndex: 99999,
      }}>
        <MIconButton ref={anchorRef} onClick={() => setOpen(true)}>
          <Icon icon={moreVerticalFill} width={18} height={18} color={'white'} />
        </MIconButton>
        {(type === 'checkIn' || type === 'occupied') && (
          <MenuPopover open={open} onClose={() => setOpen(false)} anchorEl={anchorRef.current} sx={{py: 1}}>
            <MenuItem sx={{fontSize: '.8rem'}} onClick={onEditCheckIn}>View / Edit</MenuItem>
            {/*<MenuItem sx={{fontSize: '.8rem'}} onClick={onInvoice}>Invoice</MenuItem>*/}
            <MenuItem sx={{fontSize: '.8rem'}} onClick={onDeleteCheckIn}>Delete</MenuItem>
          </MenuPopover>
        )}
        {type === 'checkOut' && (
          <MenuPopover open={open} onClose={() => setOpen(false)} anchorEl={anchorRef.current} sx={{py: 1}}>
            <MenuItem sx={{fontSize: '.8rem'}} onClick={onEditCheckIn}>View / Edit</MenuItem>
            {/*<MenuItem sx={{fontSize: '.8rem'}} onClick={onInvoice}>Invoice</MenuItem>*/}
            {/*<MenuItem sx={{fontSize: '.8rem'}} onClick={onCheckOut}>Check Out</MenuItem>*/}
            <MenuItem sx={{fontSize: '.8rem'}} onClick={onDeleteCheckIn}>Delete</MenuItem>
          </MenuPopover>
        )}
        {type === 'booking' && (
          <MenuPopover open={open} onClose={() => setOpen(false)} anchorEl={anchorRef.current} sx={{py: 1}}>
            <MenuItem sx={{fontSize: '.8rem'}} onClick={onEditBooking}>View / Edit</MenuItem>
            <MenuItem sx={{fontSize: '.8rem'}} onClick={onCheckIn}>Check In</MenuItem>
            <MenuItem sx={{fontSize: '.8rem'}} onClick={onDeleteBooking}>Delete</MenuItem>
          </MenuPopover>
        )}
      </Box>
    </Stack>
  );
}

const eventBgColors = {
  checkIn: '#0288d1', // '#29b6f6',
  occupied: '#0288d1', //
  checkOut: '#0288d1', // '#f57c00', //'#ffa726',
  booking: '#388e3c', //'#66bb6a',
};

const eventTypeIcons = {
  checkIn: loginIcon,
  occupied: loginIcon,
  checkOut: logoutIcon,
  booking: formIcon,
};

// function toEvent(data, eventType, helpers) {
//   const eventDate = new Date(eventType === 'checkOut' ? data.checkOut : data.checkIn);
//   const {name, roomNo} = data;
//   const title = roomNo ? `#${roomNo}, ${name}` : name;
//   return {
//     id: data.id,
//     allDay: true,
//     title,
//     start: eventDate,
//     end: eventDate,
//     type: eventType,
//     bgcolor: eventBgColors[eventType],
//     icon: eventTypeIcons[eventType],
//     helpers,
//   };
// }

function _toEvent(data, eventDateFrom, eventDateTo, eventType, helpers) {
  const {name, roomNo, roomType, bookingType, roomCount, groupRooms} = data;
  let title = '';
  if (eventType === 'booking') {
    if (bookingType !== 'group') {
      title = `#${roomNo} (${roomType}), ${name}`;
      return [
        {
          id: data.id,
          allDay: true,
          title,
          start: eventDateFrom,
          end: eventDateTo,
          type: eventType,
          bgcolor: eventBgColors[eventType],
          icon: eventTypeIcons[eventType],
          helpers,
          bookingType,
        }
      ];
    }
    const events = [];
    for (let i = 0; i < roomCount; ++i) {
      const [roomType, roomNo] = groupRooms?.[i] ?? ['', ''];
      // title = bookingType === 'single' ? `${roomType}, ${name}` : `GROUP (${roomCount} ROOMS), ${name}`;
      if (roomNo && roomType) {
        title = `#${roomNo} (${roomType}), ${name} (Group ${i + 1}/${roomCount})`;
      } else {
        title = `${name} (Group ${i + 1}/${roomCount})`;
      }
      events.push({
        id: i,
        allDay: true,
        title,
        start: eventDateFrom,
        end: eventDateTo,
        type: eventType,
        bgcolor: eventBgColors[eventType],
        icon: eventTypeIcons[eventType],
        helpers,
        groupId: data.id,
        bookingType,
      });
    }
    return events;
  } else {
    title = `#${roomNo} (${roomType}), ${name}`;
    return [
      {
        id: data.id,
        allDay: true,
        title,
        start: eventDateFrom,
        end: eventDateTo,
        type: eventType,
        bgcolor: eventBgColors[eventType],
        icon: eventTypeIcons[eventType],
        helpers,
        bookingType,
      }
    ];
  }
}

function toEventArray(data, helpers, isBooking = false) {
  const {checkIn, checkOut} = data;
  const from = startOfDay(checkIn);
  const to = addDays(startOfDay(checkOut), 1);
  // const toStr = formatDate(to);
  const events = [];
  events.push(..._toEvent(data, from, to, isBooking ? 'booking' : 'checkIn', helpers));
  // let dayToAdd = 1;
  // while (true) {
  //   const d = addDays(from, dayToAdd++);
  //   const dStr = formatDate(d);
  //   if (dStr === toStr) {
  //     break;
  //   }
  //   events.push(_toEvent(data, d, isBooking ? 'booking' : 'occupied', helpers));
  // }
  // events.push(_toEvent(data, to, isBooking ? 'booking' : 'checkOut', helpers));
  return events;
}

function toEventList(rows, bookingList, helpers) {
  const events = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const row of rows) {
    events.push(...toEventArray(row, helpers, false));
  }
  for (const booking of bookingList) {
    events.push(...toEventArray(booking, helpers, true));
  }
  return events;
}

function getFromTo(date, view) {
  switch (view) {
    case 'listWeek':
      return { from: startOfWeek(date), to: endOfWeek(date) };
    case 'timeGridWeek':
      return { from: startOfWeek(date), to: endOfWeek(date) };
    case 'timeGridDay':
      return { from: startOfDay(date), to: endOfDay(date) };
    case 'dayGridMonth':
      return { from: subMonths(date, 1), to: addMonths(date, 1) };
    default:
      throw new Error();
  }
}
