import axios from 'axios';
import {v4} from 'uuid';
import {devLog} from './utils';
import dfn from './dfn';

/**
 * @typedef {{
 *   appName: string,
 *   appVersion: string,
 *   userId: string,
 *   email: string,
 *   pin: string,
 *   vendorId: string,
 *   pgEnv: 'DEMO' | 'PRODUCTION',
 *   merchantId: string,
 * }} CWSCredential
 */

/**
 * @typedef {{
 *   method: string,
 *   requestId: string,
 *   targetType: string;
 *   version: string;
 *   parameters: Record<string, any>;
 * }} CWSRequest
 */

/**
 * @typedef {{
 *   requestId: string,
 *   data: Record<string, any>,
 *   statusDetails: string,
 * }} CWSResponse
 */

/**
 * @typedef {{
 *   paymentGatewayCommand: {
 *     eventQueue: {
 *       timeStamp: string,
 *       statusDetails: string,
 *     }[],
 *     completed: boolean,
 *     chanId: string,
 *   }
 * }} CWSStartTransactionResponseData
 */

class CWS {
  /**
   * @private
   * @type {string}
   */
  apiUrl = 'https://localhost:9790/rest/command';
  /**
   * @private
   * @type {CWSCredential}
   */
  credential = {
    appName: 'TechCube 1Book',
    appVersion: '1.0.0',
    userId: 'apiUser',
    email: 'admin@techcube.ca',
    pin: 'OLGMXWS8DO8MZZ323YQB48A4L51OUC0W73D55N0865I15OINXPNEMREAQHBS9T4L',
    vendorId: 'techcub1',
    pgEnv: 'DEMO',
    merchantId: '0027333',
  };
  /**
   * @private
   * @type {string | null}
   */
  pgId = null;
  /**
   * @private
   * @type {Date | null}
   */
  pgIdUpdatedAt = null;
  /**
   * @private
   * @type {CWSStartTransactionResponseData | null}
   */
  startTxData = null;
  /**
   * @param {CWSCredential} credential
   */
  setCredential(credential) {
    this.credential = credential;
  }
  /**
   * @param {string} checkInId
   * @param {string} invoiceNumber
   * @param {number} baseAmount
   * @param {number} taxAmount
   * @param {'CAD' | 'USD'} [currencyCode = 'CAD']
   * @returns {Promise<[string, string[]]>}
   */
  async startPayment(checkInId, invoiceNumber, baseAmount, taxAmount, currencyCode = 'CAD') {
    if (baseAmount <= 0 || taxAmount < 0) {
      throw new Error(`Invalid base amount or tax amount! (base: ${baseAmount}, tax: ${taxAmount})`);
    }
    devLog('starting payment...');
    const pgId = await this.openPaymentGateway(); // it returns pgId without api call, if opened already
    devLog(`paymentGatewayId: ${pgId}`);
    const chanId = await this.startPaymentTransaction(pgId, currencyCode, checkInId, invoiceNumber, baseAmount, taxAmount);
    devLog(`payment started! (chanId: ${chanId})`);
    while (true) {
      await this.waitFor(1000);
      const [completed, result, errors, cardType, cardScheme, authCode, maskedPan, firstName, lastName, id] = await this.getPaymentTransactionStatus(pgId, chanId); // pulling payment status every 1000 ms until completed: true
      devLog('payment status', {completed, result, errors});
      if (!completed) { // if transaction status is not completed, keep pulling
        continue;
      }
      return [result, errors, cardType, cardScheme, authCode, maskedPan, firstName, lastName, id];
    }
  }
  async getEnvironmentInfo() {
    return this.post({
      'method' : 'getEnvironmentInfo',
      'requestId' : this.reqId,
      'targetType' : 'api',
      'version' : '1.0',
      'parameters' : {}
    });
  }
  /**
   * @param {string} pgId
   * @param {'CAD' | 'USD'} currencyCode
   * @param {string} checkInId
   * @param {string} invoiceNumber
   * @param {number} baseAmount
   * @param {number} taxAmount
   * @returns {Promise<void>}
   */
  async startPaymentTransaction(pgId, currencyCode, checkInId, invoiceNumber, baseAmount, taxAmount) {
    const requestData = {
      method : 'startPaymentTransaction',
      requestId : this.reqId,
      targetType : 'paymentGatewayConverge',
      version : '1.0',
      parameters : {
        tokenizedCardNumber : null,
        tenderedAmount : {
          value : 110,
          currencyCode : 'USD'
        },
        paymentGatewayId : pgId,
        originalTransId : null,
        generateToken : true,
        gratuityFlowEnabled : true,
        cardType : null,
        discount : null,
        partialApprovalAllowed : true,
        baseTransactionAmount : {
          value : Math.floor(baseAmount * 100),
          currencyCode
        },
        addToken :false,
        manual : true,
        gratuityQuickSelections : null,
        transactionType : 'SALE',
        merchantTransactionReference : checkInId,
        invoiceNumber,
        avsFields : {
          CARDHOLDER_ADDRESS : null,
          CARDHOLDER_ZIP : null
        },
        isTaxInclusive : false,
        taxAmounts : {
          value : Math.floor(taxAmount * 100),
          currencyCode
        },
        gratuityCustomAmountEntryAllowed : true,
        tenderType : 'CARD',
        note : null,
        forceTransaction : false
      }
    };
    devLog(requestData);
    const res = await this.post(requestData);
    /** @type {CWSStartTransactionResponseData} */
    const {data} = res;
    this.startTxData = data;
    return data.paymentGatewayCommand.chanId;
  }
  async getPaymentTransactionStatus(paymentGatewayId, chanId) {
    const {
      data: {
        paymentGatewayCommand: {
          completed,
          paymentTransactionData: {
            result,
            errors = [],
            cardType, // 'CREDIT' or DEBIT
            cardScheme,
            authCode,
            maskedPan,
            firstName,
            lastName,
            id,
          } = {}
        }
      }
    } = await this.post({
      method: 'getPaymentTransactionStatus',
      requestId: this.reqId,
      targetType: 'paymentGatewayConverge',
      version: '1.0',
      parameters: {
        paymentGatewayId,
        chanId,
      }
    });
    return [completed, result, errors, cardType, cardScheme, authCode, maskedPan, firstName, lastName, id];
  }
  async openPaymentGateway() {
    if (this.pgId) {
      if (this.pgIdUpdatedAt) {
        const updateStr = dfn.format(this.pgIdUpdatedAt);
        const todayStr = dfn.format(Date.now());
        if (updateStr === todayStr) {
          return this.pgId;
        }
      }
    }
    const {
      userId,
      email,
      pin,
      appName: vendorAppName,
      appVersion: vendorAppVersion,
      vendorId,
      pgEnv: paymentGatewayEnvironment,
      merchantId,
    } = this.credential;
    const requestId = this.reqId;
    const requestData = {
      method: 'openPaymentGateway',
      requestId,
      targetType: 'paymentGatewayConverge',
      version: '1.0',
      parameters: {
        app: 'VMM',
        email,
        pin,
        userId,
        vendorId,
        vendorAppName,
        vendorAppVersion,
        handleDigitalSignature: false,
        retrieveAccountInfo: true,
        paymentGatewayEnvironment,
        merchantId,
      }
    };
    const {
      data: {
        paymentGatewayCommand: {
          openPaymentGatewayData: {
            paymentGatewayId
          }
        }
      }
    } = await this.post(requestData);
    this.pgId = paymentGatewayId ?? null;
    this.pgIdUpdatedAt = new Date();
    return paymentGatewayId;
  }
  /**
   * @private
   * @param {CWSRequest} requestData
   * @returns {Promise<CWSResponse>}
   */
  async post(requestData) {
    // devLog(requestData);
    const {data: {requestId, data, statusDetails}} = await axios.post(this.apiUrl, requestData);
    devLog({requestId, data, statusDetails});
    return {requestId, data, statusDetails};
  }

  /**
   * @private
   * @returns {string}
   */
  get reqId() {
    return v4();
  }

  /**
   * @private
   * @param {number} ms
   * @returns {Promise<void>}
   */
  waitFor(ms) {
    return new Promise((resolve) => {
      setTimeout(() => resolve(), ms);
    });
  }
}

const cws = new CWS();

export default cws;
