import React from 'react';
import FirestoreCRUDTable from './FirestoreCRUDTable';
import { DeleteButton } from '../components/icon-buttons';
import CompanyForm from '../forms/CompanyForm';
import Confirm, { useConfirm } from '../components/Confirm';
import fire from '../utils/fire-v8';

const CompanyCRUDTable = () => {
  const {confirmProps, openConfirm} = useConfirm();
  const tableRef = React.useRef();
  function onDelete(row) {
    openConfirm(
      'Are you sure you want to delete?',
      () => fire.storeDoc('companies', row.id).delete(),
      'Company has been deleted successfully!'
    );
  }
  return (
    <FirestoreCRUDTable
      ref={tableRef}
      query={fire.companyQuery()}
      columns={[
        { field: 'company', headerName: 'Company' },
        { field: 'pic', headerName: 'Person In Charge' },
        { field: 'email', headerName: 'Email' },
        { field: 'phoneNo', headerName: 'Phone No.' },
      ]}
      title="COMPANY"
      Form={CompanyForm}
      renderLeftHeader={null}
      hideDeleteButton
      // hideEditButton
      formDialogMaxWidth={'md'}
      renderActionButtons={(row) => {
        return [
          <DeleteButton key={'delete'} onClick={() => onDelete(row)} />,
        ];
      }}
      renderFooter={() => (
        <>
          <Confirm {...confirmProps} />
        </>
      )}
      placeActionColumnFirst
      actionColDef={{minWidth: 160}}
    />
  );
};

export default CompanyCRUDTable;
