import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const ResponsiveDialog = ({ title, open, onClose, maxWidth, fullScreen, children }) => (
  <Dialog open={open} onClose={onClose} fullScreen={fullScreen} fullWidth maxWidth={maxWidth ?? 'md'} disableEscapeKeyDown>
    <DialogTitle>
      {title}
    </DialogTitle>
    <DialogContent>
      {children}
    </DialogContent>
  </Dialog>
);

export function useResponsiveDialog() {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const onClose = (e, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };
  return {
    props: { open, onClose, fullScreen },
    open: () => {
      setOpen(true);
    },
    close: () => {
      setOpen(false);
    },
  };
}

export default ResponsiveDialog;
