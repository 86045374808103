import React from 'react';
import PageContainer from '../../components/PageContainer';
import CheckInListTable from '../../data-tables/CheckInListTable';

export default function InvoiceSearch() {
  return (
    <PageContainer title="Invoice Search">
      <CheckInListTable status={'checked-out'} paymentStatus={'paid'} />
    </PageContainer>
  );
}
