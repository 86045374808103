import React from 'react';
import useStoreId from './useStoreId';
import { devLog, httpsCall } from '../utils/utils';

const defData = {
  bookingCount: 0,
  bookingDoneCount: 0,
  bookingCancelledCount: 0,
  checkInCount: 0,
  checkOutCount: 0,
  checkInOutRange: '...',
  totalRoomCount: 0,
  roomCountList: {},
};

export default function useDashboard() {
  const storeId = useStoreId();
  const [state, setState] = React.useState({data: defData, loading: true, error: null});
  React.useEffect(() => {
    if (storeId) {
      (async () => {
        try {
          const {data} = await httpsCall('getDashboard', {storeId});
          const {
            bookingCount = 0,
            bookingDoneCount = 0,
            bookingCancelledCount = 0,
            checkInCount = 0,
            checkOutCount = 0,
            checkInOutFrom = '',
            checkInOutTo = '',
            totalRoomCount = 0,
            roomCountList = {},
          } = data;
          const stateData = {
            bookingCount,
            bookingDoneCount,
            bookingCancelledCount,
            checkInCount,
            checkOutCount,
            checkInOutRange: `${checkInOutFrom} ~ ${checkInOutTo}`,
            totalRoomCount,
            roomCountList,
          };
          setState({loading: false, data: stateData, error: null});
          devLog(data);
        } catch (error) {
          setState({loading: false, data: defData, error});
        }
      })();
    }
  }, [storeId]);
  return state;
}
