import React from 'react';
import FirestoreCRUDTable from './FirestoreCRUDTable';
import useStoreId from '../hooks/useStoreId';
import {
  cancelCheckIn,
  devLog,
  formatDate,
  formatDateRange,
  formatDateTime,
  formatGuestLabel,
  getStoreQuery,
  renderBooker
} from '../utils/utils';
import CheckInForm from '../forms/CheckInForm';
import { CheckOutButton, CopyButton, DeleteButton } from '../components/icon-buttons';
import Confirm, { useConfirm } from '../components/Confirm';
import useAuth from '../hooks/useAuth';
import { CheckInLink } from '../components/links';

const CheckInCRUDTable = () => {
  const storeId = useStoreId();
  const {user} = useAuth();
  const tableRef = React.useRef();
  const {confirmProps, openConfirm} = useConfirm();
  let query = null;
  if (storeId) {
    query = getStoreQuery(storeId, 'check-ins')
      .where('status', '==', 'checked-in')
      .orderBy('createdAt', 'desc');
  }
  function onCheckOut(row) {
    // openCheckoutDialog(row);
    tableRef.current.openFormDialog(row, true);
  }
  function onCopy(row) {
    devLog('onCopy', row);
    const checkInData = {
      ...row,
      id: '',
      adults: '',
      children: '',
      infants: '',
      roomNo: '',
      roomType: '',
      roomRate: '',
      roomRateOrg: '',
      roomRateDaily: '',
      totalDays: '',
      rateType: '',
      addPetFee: '',
      addLaundryFee: '',
      addRVFee: '',
      noTax: '',
    };
    tableRef.current.openFormDialog(checkInData);
  }
  function onCancel(row) {
    openConfirm(
      'Are you sure you want to cancel this check-in?',
      async () => cancelCheckIn(storeId, row.id, row.roomNo),
      'Check-in has been cancelled!'
    );
  }
  function getColumns() {
    const columns = [
      // { field: 'source', headerName: 'Source', flex: 0, width: 100, minWidth: 100, align: 'center', headerAlign: 'center', renderCell: renderSource },
      { field: 'roomNo', headerName: 'Room#', flex: 0, width: 100, minWidth: 100, align: 'center', headerAlign: 'center' },
      { field: 'name', headerName: 'Name', renderCell: renderBooker },
      { field: 'checkIn', headerName: 'Check In / Check Out', valueGetter: ({value, row}) => formatDateRange(value, row.checkOut) },
      { field: 'guests', headerName: 'Guests', valueGetter: ({row}) => formatGuestLabel(row) },
      { field: 'createdAt', headerName: 'Created At', valueGetter: ({value}) => formatDateTime(value) },
    ];
    const idColumn = { field: 'id', headerName: 'ID', renderCell: ({value}) => <CheckInLink checkInId={value} /> };
    return (user.role === 'super' && user.storeId !== 'demo') ? [idColumn, ...columns] : columns;
  }
  return (
    <FirestoreCRUDTable
      ref={tableRef}
      query={query}
      columns={getColumns()}
      title={(editData, editMode) => editMode ? `ROOM#${editData.roomNo} (${editData.roomType}), ${editData.name}, ${formatDate(editData.checkIn)} ~ ${formatDate(editData.checkOut)}` : 'ADD CHECK-IN'}
      Form={CheckInForm}
      renderLeftHeader={null}
      hideDeleteButton
      // hideEditButton
      formDialogMaxWidth={'md'}
      renderActionButtons={(row) => {
        return [
          <DeleteButton key={'delete'} onClick={() => onCancel(row)} />,
          <CopyButton key={'copy'} onClick={() => onCopy(row)} />,
          // <InvoiceButton key={'invoice'} onClick={() => onInvoice(row)} />,
          <CheckOutButton key={'checkOut'} onClick={() => onCheckOut(row)} />,
          // <Button key={'checkOut'} size="small" onClick={() => onCheckOut(row)}>CHECK-OUT</Button>,
        ];
      }}
      renderFooter={() => (
        <>
          <Confirm {...confirmProps} />
        </>
      )}
      placeActionColumnFirst
      actionColDef={{minWidth: 160, width: 160, flex: 0}}
    />
  );
};

export default CheckInCRUDTable;
