import React from 'react';
import { Button, Stack } from '@mui/material';
import { postRoom } from '../utils/utils';
import InputField from './InputField';
import useRoomTypeList from '../hooks/useRoomTypeList';
import useStoreId from '../hooks/useStoreId';
import useForm from '../hooks/useForm';
import * as yup from 'yup';

const roomSchema = yup.object().shape({
  roomId: yup.string(),
  roomNo: yup.number().integer().positive().required('Please enter the room number.'),
  roomType: yup.string().required('Please enter the room type.'),
  // unitPrice: yup.number().required('Please enter the unit price.').typeError('Please enter a number'),
});

const defRoomData = {
  roomId: '',
  roomNo: '',
  roomType: '',
  // unitPrice: '',
};

const RoomForm = ({ editData, editMode, onCloseDialog }) => {
  const storeId = useStoreId();
  const formik = useForm({
    editData: {...defRoomData, ...editData},
    editMode,
    validationSchema: roomSchema,
    onSubmit: async (values) => {
      const res = await postRoom(storeId, values);
      onCloseDialog?.();
      return res;
    },
    message: (values) => ({ success: `Room ${values.roomNo} has been saved successfully.` }),
  });
  const [roomTypes] = useRoomTypeList();
  const roomOptions = roomTypes.map(({ value, label }) => ({
    value,
    label: `${value} - ${label}`,
  }));
  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack direction='column' spacing={2} pt={2}>
        <InputField name='roomNo' label='Room No.' formik={formik} disabled={editMode} />
        <InputField name='roomType' label='Room Type' formik={formik} options={roomOptions} disabled={editMode} />
        {/*<InputField name='unitPrice' label='Unit Price' formik={formik} />*/}
      </Stack>
      <Stack direction='row' spacing={2} mt={4} justifyContent='center'>
        <Button type='submit' variant='contained' disabled={formik.isSubmitting}>
          {editMode ? 'EDIT' : 'ADD'} ROOM
        </Button>
        {onCloseDialog && (
          <Button variant='contained' color='error' disabled={formik.isSubmitting} onClick={onCloseDialog}>
            CLOSE
          </Button>
        )}
      </Stack>
    </form>
  );
};

export default RoomForm;
