import React from 'react';
import FirestoreListTable from './FirestoreListTable';
import { formatDateTime, getStoreQuery } from '../utils/utils';
import useStoreId from '../hooks/useStoreId';
import { StandaloneInputField } from '../forms/InputField';

const CustomerListTable = ({doNotQueryIfEmpty = false, simpleList = false, onSelect, doNotUseCard}) => {
  const [search, setSearch] = React.useState('');
  const storeId = useStoreId();
  let query = storeId ? getStoreQuery(storeId, 'customers') : null;
  if (query && search.length >= 2) {
    query = query.where('tags', 'array-contains', search.toLowerCase());
  } else {
    if (doNotQueryIfEmpty) {
      query = null;
    }
  }
  if (query) {
    query = query.orderBy('updatedAt', 'desc').limit(100);
  }
  function getColumns() {
    if (simpleList) {
      return [
        { field: 'name', headerName: 'Name' },
        { field: 'email', headerName: 'Email' },
        { field: 'phoneNo', headerName: 'Phone No.' },
        { field: 'sex', headerName: 'Sex' },
        // { field: 'dob', headerName: 'DOB' },
        { field: 'city', headerName: 'City' },
      ];
    }
    return [
      { field: 'name', headerName: 'Name' },
      { field: 'email', headerName: 'Email' },
      { field: 'phoneNo', headerName: 'Phone No.' },
      { field: 'sex', headerName: 'Sex' },
      // { field: 'dob', headerName: 'DOB' },
      { field: 'city', headerName: 'City' },
      {
        field: 'createdAt',
        headerName: 'Updated At',
        valueGetter: ({ row }) => formatDateTime(row.updatedAt ?? row.createdAt),
      },
    ];
  }
  return (
    <FirestoreListTable
      query={query}
      columns={getColumns()}
      // mapRows={(snapshot) => snapshot?.docs.map(getPaymentData) ?? []}
      onRowDoubleClick={({row}) => {
        onSelect?.(row);
      }}
      renderLeftHeader={() => {
        return (
          <StandaloneInputField
            label={'Search Customer'}
            value={search}
            onChange={({target: {value}}) => setSearch(value)}
          />
        );
      }}
      doNotUseCard={doNotUseCard}
    />
  );
};

export default CustomerListTable;
