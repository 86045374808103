import firebase from 'firebase/app';
import 'firebase/functions';
import { addDays, endOfDay, format, isAfter, isBefore, isValid, startOfDay, subDays } from 'date-fns';
import { Tooltip } from '@mui/material';
import useStoreId from '../hooks/useStoreId';
import {
  GST_RATE,
  houseKeepingOptions,
  itemsCannotBeDeleted,
  itemsForAdjustment,
  PET_FEE,
  RATE_CORPORATE,
  RATE_MONTHLY,
  ROOM_TAX_RATE,
  RV_FEE,
  transactionOptions
} from './options';
import dfn from './dfn';
import fire from './fire-v8';

export function toInt(value) {
  const val = parseInt(value);
  return Number.isNaN(val) ? undefined : val;
}

export function toFloat(value) {
  const val = parseFloat(value);
  return Number.isNaN(val) ? undefined : val;
}

export function formatGuestLabel(data) {
  const items = [];
  if (data.adults > 0) {
    items.push((data.adults === 1 || data.adults === '1') ? '1 Adult' : `${data.adults} Adults`);
  }
  if (data.children > 0) {
    items.push((data.children === 1 || data.children === '1') ? '1 Child' : `${data.children} Children`);
  }
  if (data.infants > 0) {
    items.push((data.infants === 1 || data.infants === '1') ? '1 Infant' : `${data.infants} Infants`);
  }
  return items.join(', ');
}

export function formatBookingInfo(data) {
  const guests = formatGuestLabel(data);
  const items = [guests];
  if (data.bookingType === 'group') {
    if (data.roomCount) {
      items.push(`${data.roomCount} Rooms`);
    }
    // if (data.singleCount) {
    //   items.push(`Single Bed: ${data.singleCount}`);
    // }
    // if (data.queenCount) {
    //   items.push(`Queen Bed: ${data.queenCount}`);
    // }
  } else {
    if (data.bedType) {
      items.push(`Bed Type: ${data.bedType}`);
    }
  }
  return items.join(', ');
}

function _formatDate(date, formatStr) {
  if (!date) {
    return '';
  } else if ((date instanceof Date && isValid(date)) || typeof date === 'number') {
    return format(date, formatStr);
  } else if (typeof date === 'string') {
    return format(Date.parse(date), formatStr);
  } else if (date?.toDate) {
    return format(date.toDate(), formatStr);
  }
  return '';
}

export function formatMD(date) {
  return _formatDate(date, 'MM-dd');
}

export function formatMDRange(from, to) {
  return `${formatMD(from)} ~ ${formatMD(to)}`;
}

export function formatDate(date) {
  return _formatDate(date, 'yyyy-MM-dd');
}

export function formatDateTime(date) {
  return _formatDate(date, 'yyyy-MM-dd hh:mm:ss');
}

export function formatDateRange(from, to) {
  return `${formatDate(from)} ~ ${formatDate(to)}`;
}

export function formatCurrency(value) {
  return roundAtTwoDecimal(value).toLocaleString('en-CA', {
    style: 'currency',
    currency: 'CAD',
  }).replace('-$0.00', '$0.00');
}

export function gridCurrencyFormatter({ value }) {
  return formatCurrency(value);
}

const taxableLabels = {
  [true]: 'YES',
  [false]: 'NO',
  GST: 'GST Only',
  PST: 'PST Only',
};
export function gridTaxableFormatter({value}) {
  return taxableLabels[value] ?? 'NO';
}

export function getRoomData(serverData) {
  const { roomId, roomNo, roomType } = serverData ?? {};
  return {
    roomId,
    roomNo,
    roomType,
  };
}

export async function httpsCall(name, data) {
  return firebase.functions().httpsCallable(name)(data);
}

export async function postRoom(storeId, { roomId, roomNo, roomType }) {
  return firebase.functions().httpsCallable('postRoom')({
    storeId,
    roomId,
    roomNo,
    roomType,
  });
}

export async function deleteRoom(storeId, { roomId, roomNo }) {
  return firebase.functions().httpsCallable('deleteRoom')({
    storeId,
    roomId,
    roomNo,
  });
}

export function getRoomTypeData(serverData) {
  const { id, label, value, standardRate, weeklyRate, monthlyRate, corpRate } = serverData ?? {};
  return { id, label, value, standardRate, weeklyRate, monthlyRate, corpRate };
}

export async function postRoomType(storeId, { id, value, label, standardRate, weeklyRate, monthlyRate, corpRate }) {
  return firebase.functions().httpsCallable('postRoomType')({
    storeId, id, value, label,
    standardRate: parseFloat(standardRate),
    weeklyRate: parseFloat(weeklyRate),
    monthlyRate: parseFloat(monthlyRate),
    corpRate: parseFloat(corpRate),
  });
}

export async function deleteRoomType(storeId, { id, value }) {
  return firebase.functions().httpsCallable('deleteRoomType')({ storeId, id, value });
}

export function getUserPostingQuery(type, storeId, status, dateFrom, dateTo) {
  if (!storeId) {
    return null;
  }
  const db = firebase.firestore();
  let query = db.collection(`stores/${storeId}/${type}`).orderBy('createdAt', 'desc');
  if (status !== 'all') {
    query = query.where('status', '==', status);
  }
  if (dateFrom) {
    query = query.where('createdAt', '>=', dateFrom);
  }
  if (dateTo) {
    query = query.where('createdAt', '<=', dateTo);
  }
  return query;
}

export function getInvoiceListQuery(storeId, checkInId) {
  if (!(storeId && checkInId)) {
    return null;
  }
  const db = firebase.firestore();
  return db.collection(`stores/${storeId}/check-ins/${checkInId}/invoices`)
    .orderBy('invoiceDate')
    .orderBy('amount', 'desc');
}

export function getTransactionListQuery(storeId, checkInId) {
  if (!(storeId && checkInId)) {
    return null;
  }
  const db = firebase.firestore();
  return db.collection(`stores/${storeId}/check-ins/${checkInId}/invoices`)
    .orderBy('invoiceDate')
    .orderBy('amount', 'desc');
}

function getPaymentItem(checkInId, invItemId, dateStr, itemCode, amount, roomNo) {
  amount = Math.abs(parseFloat(amount));
  return {checkInId, invItemId, dateStr, itemCode, amount, roomNo};
}

function getSalesItem(checkInId, invItemId, dateStr, itemCode, amount, taxable = false, roomNo) {
  if (itemsForAdjustment.includes(itemCode)) {
    const net = -Math.abs(parseFloat(amount));
    const roomTax = taxable === true ? net * ROOM_TAX_RATE : 0;
    const gst = taxable !== false ? net * GST_RATE : 0;
    const gross = net + roomTax + gst;
    return {checkInId, invItemId, dateStr, itemCode, net, roomTax, gst, gross, roomNo};
  }
  const net = parseFloat(amount);
  const roomTax = taxable === true ? net * ROOM_TAX_RATE : 0;
  const gst = taxable !== false ? net * GST_RATE : 0;
  const gross = net + roomTax + gst;
  return {checkInId, invItemId, dateStr, itemCode, net, roomTax, gst, gross, roomNo};
}

export async function setInvoiceItem(storeId, checkInId, {id, itemTitle, itemCode, amount, note, taxable = false, invoiceDate}) {
  const batch = getBatch();
  const dateStr = invoiceDate ?? formatDate(Date.now());
  const data = {
    itemTitle,
    itemCode: itemCode ? itemCode : 'etcFee',
    amount: parseFloat(amount),
    note,
    taxable,
    invoiceDate: dateStr,
  };
  const checkInData = (await storeDocRef(storeId, 'check-ins', checkInId).get()).data();
  const newRef = setStoreDocBatch(batch, storeId, `check-ins/${checkInId}/invoices`, data, id);
  if (isPayment({itemCode})) {
    const paymentsRef = storeColRef(storeId, 'payments');
    batch.set(paymentsRef.doc(), getPaymentItem(checkInId, newRef.id, dateStr, itemCode, amount, checkInData.roomNo));
  } else {
    const salesRef = storeColRef(storeId, 'sales');
    batch.set(salesRef.doc(), getSalesItem(checkInId, newRef.id, dateStr, itemCode, amount, taxable, checkInData.roomNo));
  }
  return batch.commit();
}

export function setInvoiceItemBatch(batch, storeId, checkInId, {id, itemTitle, itemCode, amount, note, taxable, invoiceDate}) {
  return setStoreDocBatch(batch, storeId, `check-ins/${checkInId}/invoices`, {itemTitle, itemCode, amount, note, taxable, invoiceDate}, id);
}

export async function deleteInvoiceItem(storeId, checkInId, invItemId, itemCode) {
  const batch = getBatch();
  const invItemRef = storeDocRef(storeId, `check-ins/${checkInId}/invoices`, invItemId);
  batch.delete(invItemRef);
  if (isPayment({itemCode})) {
    const paymentsItemSnapshot = await storeColRef(storeId, 'payments').where('invItemId', '==', invItemId).get();
    paymentsItemSnapshot.forEach((doc) => batch.delete(doc.ref));
  } else {
    const salesItemSnapshot = await storeColRef(storeId, 'sales').where('invItemId', '==', invItemId).get();
    salesItemSnapshot.forEach((doc) => batch.delete(doc.ref));
  }
  return batch.commit();
}

const defColDef = {
  flex: 1,
  minWidth: 100,
  sortable: false,
  disableColumnMenu: true,
  // resizable: true,
};

export function mapDefColDef(col) {
  return { ...defColDef, ...col };
}

export function defMapRows(snapshot) {
  return snapshot?.docs.map((doc) => ({ ...doc.data(), id: doc.id })) ?? [];
}

function buildDataToSet(data) {
  const dataToSet = {};
  for (const [name, value] of Object.entries(data)) {
    if (value !== undefined) {
      dataToSet[name] = value;
    }
  }
  return dataToSet;
}

export function storeColRef(storeId, path) {
  if (!storeId) {
    return null;
  }
  return firebase.firestore().collection(`stores/${storeId}/${path}`);
}

export function storeDocRef(storeId, collectionPath, docId) {
  if (!storeId) {
    return null;
  }
  if (docId) {
    return firebase.firestore().doc(`stores/${storeId}/${collectionPath}/${docId}`);
  } else {
    return firebase.firestore().collection(`stores/${storeId}/${collectionPath}`).doc();
  }
}

export function getBatch() {
  return firebase.firestore().batch();
}

export function getCreateInfo() {
  const {uid, displayName, email} = firebase.auth().currentUser ?? {};
  return {
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    createdBy: uid,
    createdName: displayName ?? email,
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    updatedBy: uid,
    updatedName: displayName ?? email,
  };
}

export function getUpdateInfo() {
  const {uid, displayName, email} = firebase.auth().currentUser ?? {};
  return {
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    updatedBy: uid,
    updatedName: displayName ?? email,
  };
}

export async function setStoreDoc(storeId, collectionPath, data, docId) {
  const dataToSet = buildDataToSet(data);
  const docRef = storeDocRef(storeId, collectionPath, docId);
  const doc = await docRef.get();
  if (doc.exists) {
    await docRef.update({...getUpdateInfo(), ...dataToSet});
  } else {
    await docRef.set({...getCreateInfo(), ...dataToSet});
  }
  return docRef;
}

export function setStoreDocBatch(batch, storeId, collectionPath, data, docId) {
  const dataToSet = buildDataToSet(data);
  const docRef = storeDocRef(storeId, collectionPath, docId);
  if (docId) {
    batch.update(docRef, {...getUpdateInfo(), ...dataToSet});
  } else {
    batch.set(docRef, {...getCreateInfo(), ...dataToSet});
  }
  return docRef;
}

export async function updateHousekeepingStatus(storeId, roomNo, statusCode) {
  if (Array.isArray(roomNo)) {
    for (const no of roomNo) {
      await updateHousekeepingStatus(storeId, no, statusCode);
    }
  } else {
    const statusLabel = houseKeepingOptions.find(i => i.value === statusCode).label;
    return setStoreDoc(storeId, 'house-keeping', {statusCode, statusLabel}, roomNo);
  }
}

export function updateHousekeepingStatusBatch(batch, storeId, roomNo, statusCode, rvRoomNo) { // NOTE: 다른 참조와의 충돌을 방지하기 위해 추가된 패러미터를 가장 마직막으로 옮김
  if (Array.isArray(roomNo)) {
    for (const no of roomNo) {
      updateHousekeepingStatusBatch(batch, storeId, no, statusCode);
    }
  } else {
    const statusLabel = houseKeepingOptions.find(i => i.value === statusCode).label;
    setStoreDocBatch(batch, storeId, 'house-keeping', {statusCode, statusLabel}, roomNo);
    if (rvRoomNo) {
      setStoreDocBatch(batch, storeId, 'house-keeping', {statusCode, statusLabel}, rvRoomNo);
    }
  }
}

// export async function setCheckInDoc(storeId, data, docId, invoiceItems) {
//   const res = await setStoreDoc(storeId, 'check-ins', data, docId);
//   if (!docId) {
//     docId = res.id;
//     const {roomNo, roomType, roomRate, addPetFee, addLaundryFee, addRVFee} = data;
//     {
//       const {label, value, taxable} = invoiceItems.find(i => i.value === 'roomFee');
//       await setInvoiceItem(storeId, docId, {
//         itemTitle: label,
//         itemCode: value,
//         amount: parseFloat(roomRate),
//         note: `Room #${roomNo}(${roomType})`,
//         taxable
//       });
//     }
//     if (addPetFee === true) {
//       const {label, value, fee, taxable} = invoiceItems.find(i => i.value === 'petFee');
//       await setInvoiceItem(storeId, docId, {
//         itemTitle: label,
//         itemCode: value,
//         amount: parseFloat(fee),
//         note: '',
//         taxable
//       });
//     }
//     if (addLaundryFee === true) {
//       const {label, value, fee, taxable} = invoiceItems.find(i => i.value === 'laundryFee');
//       await setInvoiceItem(storeId, docId, {
//         itemTitle: label,
//         itemCode: value,
//         amount: parseFloat(fee),
//         note: '',
//         taxable
//       });
//     }
//     if (addRVFee === true) {
//       const {label, value, fee, taxable} = invoiceItems.find(i => i.value === 'rvFee');
//       await setInvoiceItem(storeId, docId, {
//         itemTitle: label,
//         itemCode: value,
//         amount: parseFloat(fee),
//         note: '',
//         taxable
//       });
//     }
//     await updateHousekeepingStatus(storeId, roomNo, 'O');
//   }
// }

function deleteInvoiceItemsBatch(batch, storeId, checkInId, invoiceItems) {
  for (const {itemCode, id} of invoiceItems) {
    if (itemCode === 'roomFee' || itemCode === 'petFee' || itemCode === 'rvFee') {
      const docRef = storeDocRef(storeId, `check-ins/${checkInId}/invoices`, id);
      batch.delete(docRef);
    }
  }
}

function getInvoiceItems(itemType, checkInData, taxable) {
  const {value: itemCode, label: itemTitle, fee: itemFee} = itemType;
  const {checkOut, roomNo, roomType, rateType, roomRateInfo} = checkInData;
  const items = [];
  if (rateType === 'Monthly') {
    if (itemCode === 'roomFee') {
      const {months: [/* months */, /* mFrom */, mTo], weeks: [weeks, /* wFrom */, wTo], days: [days, /* dFrom */, dTo], totalMonthFee, totalWeekFee, totalDayFee} = roomRateInfo;
      items.push({itemCode, itemTitle, amount: totalMonthFee, note: `Room #${roomNo}(${roomType}) Monthly Fee`, taxable: false, invoiceDate: dfn.format(mTo)});
      weeks > 0 && items.push({itemCode, itemTitle, amount: totalWeekFee, note: `Room #${roomNo}(${roomType}) Weekly Fee`, taxable: false, invoiceDate: dfn.format(wTo)});
      days > 0 && items.push({itemCode, itemTitle, amount: totalDayFee, note: `Room #${roomNo}(${roomType}) Daily Fee`, taxable: false, invoiceDate: dfn.format(dTo)});
    } else {
      const {totalDays} = roomRateInfo;
      items.push({itemCode, itemTitle, amount: itemFee * totalDays, note: `${itemFee} * ${totalDays} Day(s)`, taxable: false, invoiceDate: dfn.format(checkOut)});
    }
  } else if (rateType === 'Weekly') {
    if (itemCode === 'roomFee') {
      const {weeks: [/* weeks */, /* wFrom */, wTo], days: [days, /* dFrom */, dTo], totalWeekFee, totalDayFee} = roomRateInfo;
      items.push({itemCode, itemTitle, amount: totalWeekFee, note: `Room #${roomNo}(${roomType}) Weekly Fee`, taxable, invoiceDate: dfn.format(wTo)});
      days > 0 && items.push({itemCode, itemTitle, amount: totalDayFee, note: `Room #${roomNo}(${roomType}) Daily Fee`, taxable, invoiceDate: dfn.format(dTo)});
    } else {
      const {totalDays} = roomRateInfo;
      items.push({itemCode, itemTitle, amount: itemFee * totalDays, note: `${itemFee} * ${totalDays} Day(s)`, taxable, invoiceDate: dfn.format(checkOut)});
    }
  } else {
    if (itemCode === 'roomFee') {
      /** @type DailySaleDetail[] */
      const dailySales = roomRateInfo.dailySales;
      for (const saleInfo of dailySales) {
        const invoiceDate = saleInfo.dateStr;
        const amount = saleInfo.net;
        items.push({itemCode, itemTitle, amount, note: `Room #${roomNo}(${roomType})`, taxable, invoiceDate});
      }
    } else {
      /** @type DailySaleDetail[] */
      const dailySales = roomRateInfo.dailySales;
      for (const saleInfo of dailySales) {
        const invoiceDate = saleInfo.dateStr;
        items.push({itemCode, itemTitle, amount: itemFee, note: `${itemFee} per day`, taxable, invoiceDate});
      }
    }
  }
  return items;
}

function setInvoiceItemsBatchEx(batch, storeId, checkInId, itemType, amount, checkInData, taxable) {
  const items = getInvoiceItems(itemType, checkInData, taxable);
  for (const item of items) {
    setInvoiceItemBatch(batch, storeId, checkInId, item);
  }
}

export function setCheckInDocBatch(batch, storeId, data, docId, invoiceItemTypes, invoiceItems, depositAmount, depositType) {
  // 태그 생성
  const tags = createTags(data);
  // 고객정보 저장
  const customerDoc = setCustomerDocBatch(batch, storeId, data, tags, data.customerId);
  const customerId = customerDoc.id;
  // 체크인 정보 저장
  const docRef = setStoreDocBatch(batch, storeId, 'check-ins', {...data, tags, customerId}, docId);
  docId = docRef.id;
  // 기존의 모든 인보이스 아이템 삭제 (존재하면...)
  deleteInvoiceItemsBatch(batch, storeId, docId, invoiceItems);
  // 인보이스 아이템 재생성
  const {roomNo, roomRateDaily, rateType, addPetFee, addRVFee, noTax, rvRoomNo} = data;
  const taxable = !(rateType === 'Monthly' || noTax === true);
  {
    const itemType = invoiceItemTypes.find(i => i.value === 'roomFee');
    setInvoiceItemsBatchEx(batch, storeId, docId, itemType, roomRateDaily, data, taxable, invoiceItems);
  }
  if (addPetFee === true) {
    const itemType = invoiceItemTypes.find(i => i.value === 'petFee');
    setInvoiceItemsBatchEx(batch, storeId, docId, itemType, undefined, data, taxable ? 'GST' : false, invoiceItems);
  }
  if (addRVFee === true) {
    const itemType = invoiceItemTypes.find(i => i.value === 'rvFee');
    setInvoiceItemsBatchEx(batch, storeId, docId, itemType, undefined, data, taxable ? 'GST' : false, invoiceItems);
  }
  // 디파짓이 있으면 디파짓도 인보이스 아이템으로 생성해줌
  if (depositType && depositAmount) {
    setInvoiceItemBatch(batch, storeId, docId, {
      itemTitle: transactionOptions.find(i => i.value === depositType).label,
      itemCode: depositType,
      amount: parseFloat(depositAmount > 0 ? `-${depositAmount}` : depositAmount),
      taxable: false,
      invoiceDate: formatDate(Date.now()),
    });
  }
  // 체크인으로 상태가 설정되어 있으면 마지막으로 하우스 키핑 상태를 `O`로 변경
  if (data.status === 'checked-in') {
    updateHousekeepingStatusBatch(batch, storeId, roomNo, 'O', rvRoomNo); // NOTE: 다른 참조와의 충돌을 방지하기 위해 추가된 패러미터를 가장 마직막으로 옮김
  }
  return docId;
}

export async function getDailyRoomSales(storeId, checkInId, includeRef = false) {
  const snapshot = await storeColRef(storeId, 'sales')
    .where('checkInId', '==', checkInId)
    .where('itemCode', 'in', ['roomFee', 'petFee', 'rvFee'])
    .get();
  if (includeRef === true) {
    return snapshot.docs.map((doc) => ({...doc.data(), id: doc.id, ref: doc.ref}));
  }
  return snapshot.docs.map((doc) => ({...doc.data(), id: doc.id}));
}

export async function deleteDailyRoomSalesBatch(batch, storeId, checkInId) {
  const rows = await getDailyRoomSales(storeId, checkInId, true);
  for (const {ref} of rows) {
    batch.delete(ref);
  }
}

export async function deleteAllDailyRoomSalesBatch(batch, storeId, checkInId) {
  const snapshot = await storeColRef(storeId, 'sales')
    .where('checkInId', '==', checkInId)
    .get();
  for (const doc of snapshot.docs) {
    batch.delete(doc.ref);
  }
}

export async function deleteAllPaymentsBatch(batch, storeId, checkInId) {
  const snapshot = await storeColRef(storeId, 'payments')
    .where('checkInId', '==', checkInId)
    .get();
  for (const doc of snapshot.docs) {
    batch.delete(doc.ref);
  }
}

function getPetFeeSalesItem(checkInId, dateStr, noTax, rateType, roomNo) {
  // TODO: 펫 요금이 GST 온리 인지 확인 요망!
  const taxable = !(noTax === true || rateType === RATE_MONTHLY);
  const net = PET_FEE;
  const roomTax = 0;
  const gst = taxable ? net * GST_RATE : 0;
  const gross = net + roomTax + gst;
  return {checkInId, dateStr, itemCode: 'petFee', net, roomTax, gst, gross, roomNo};
}

function getRVFeeSalesItem(checkInId, dateStr, noTax, rateType, roomNo) {
  // TODO: RV 요금이 GST 온리 인지 확인 요망!
  const taxable = !(noTax === true || rateType === RATE_MONTHLY);
  const net = RV_FEE;
  const roomTax = 0;
  const gst = taxable ? net * GST_RATE : 0;
  const gross = net + roomTax + gst;
  return {checkInId, dateStr, itemCode: 'rvFee', net, roomTax, gst, gross, roomNo};
}

export function setDailyRoomSalesBatch(batch, storeId, checkInId, checkInData) {
  const {/** @type RoomRateInfo */ roomRateInfo, noTax, rateType, addPetFee, addRVFee, roomNo} = checkInData
  const {dailySales} = roomRateInfo;
  const dailySalesRef = storeColRef(storeId, 'sales');
  for (const item of dailySales) {
    batch.set(dailySalesRef.doc(), {...item, itemCode: 'roomFee', checkInId, roomNo});
    if (addPetFee) {
      batch.set(dailySalesRef.doc(), getPetFeeSalesItem(checkInId, item.dateStr, noTax, rateType, roomNo));
    }
    if (addRVFee) {
      batch.set(dailySalesRef.doc(), getRVFeeSalesItem(checkInId, item.dateStr, noTax, rateType, roomNo));
    }
  }
}

export async function cancelCheckIn(storeId, checkInId, roomNo) {
  const batch = getBatch();
  await deleteAllDailyRoomSalesBatch(batch, storeId, checkInId);
  await deleteAllPaymentsBatch(batch, storeId, checkInId);
  setStoreDocBatch(batch, storeId, 'check-ins', {status: 'cancelled'}, checkInId);
  updateHousekeepingStatusBatch(batch, storeId, roomNo, 'V');
  return batch.commit();
}

export async function getInvoiceNo(storeId) {
  let invoiceNumber;
  const db = firebase.firestore();
  await db.runTransaction(async (tx) => {
    const docRef = storeDocRef(storeId, 'settings', 'serial-numbers');
    const doc = await tx.get(docRef);
    if (doc.exists) {
      invoiceNumber = doc.data()?.invoiceNumber ?? 1;
    } else {
      invoiceNumber = 1;
    }
    await tx.set(docRef, {invoiceNumber: invoiceNumber + 1}, {merge: true});
  });
  const invStr = ('00000000' + invoiceNumber).slice(-7);
  const today = _formatDate(new Date(), 'yyyyMMdd');
  return `INV${today}${invStr}`;
}

export function createTags(checkInData) {
  const {name, email, phoneNo, sex, dob, city, plateNo} = checkInData;
  const names = (name && name.split(/(\s+)/).filter(s => s.trim().length > 0)) || [];
  const emailId = (email && email.split('@')[0]) || '';
  const phone = (phoneNo && phoneNo.replace(/[^a-zA-Z0-9]/g, '')) || '';
  const phoneLast4 = phone?.slice(-4) ?? null;
  const cities = (city && city.split(/(\s+)/).filter(s => s.trim().length > 0)) || [];
  const partials = [];
  for (const str of [...names, emailId]) {
    if (str.length > 3) {
      partials.push(str.slice(0, 3));
    }
    if (str.length > 4) {
      partials.push(str.slice(0, 4));
    }
  }
  const all = [...partials, ...names, email, emailId, phoneNo, phoneLast4, sex, formatDate(dob), ...cities, plateNo].filter(s => s?.length > 0).map(s => s.toLowerCase());
  return [...new Set(all)];
}

function setCustomerDocBatch(batch, storeId, checkInData, tags, customerId) {
  const {
    name, email, phoneNo, sex, dob, city,
    licenseNo, passportNo, otherIdNo,
    plateNo, carInfo, carColor,
    cardNo, cardHolder, cardExp, cardCvv,
  } = checkInData;
  const customerData = {
    name, email, phoneNo, sex, dob, city,
    licenseNo, passportNo, otherIdNo,
    plateNo, carInfo, carColor,
    cardNo, cardHolder, cardExp, cardCvv,
    tags,
  };
  return setStoreDocBatch(batch, storeId, 'customers', customerData, customerId);
}

export function handleSelectCustomer(formik, customerData) {
  const {
    name, email, phoneNo, sex, dob, city,
    licenseNo, passportNo, otherIdNo,
    plateNo, carInfo, carColor,
    cardNo, cardHolder, cardExp, cardCvv,
    id: customerId,
  } = customerData;
  formik.setValues({
    ...formik.values,
    name, email, phoneNo, sex, dob, city,
    licenseNo, passportNo, otherIdNo,
    plateNo, carInfo, carColor, customerId,
    cardNo, cardHolder, cardExp, cardCvv,
  });
}

export async function setUserPostingStatus(type, storeId, postingUid, docId, status) {
  return setStoreDoc(storeId, type, {uid: postingUid, status}, docId);
}

export function getStoreQuery(storeId, collectionName) {
  return firebase.firestore().collection(`stores/${storeId}/${collectionName}`);
}

export function useStoreQuery(collectionName) {
  const storeId = useStoreId();
  if (!storeId) {
    return null;
  }
  return firebase.firestore().collection(`stores/${storeId}/${collectionName}`);
}

export function getStartAndEndOfDay(date) {
  return [startOfDay(date), endOfDay(date)];
}

export function renderBooker({row}) {
  const tooltips = [];
  row.email && tooltips.push(`Email: ${row.email}`);
  (row.phone ?? row.phoneNo) && tooltips.push(`Phone: ${row.phone ?? row.phoneNo}`);
  return (
    <Tooltip title={tooltips.join(', ')} arrow>
      <div>{row.name}</div>
    </Tooltip>
  );
}

export async function fetchCheckInData(storeId, checkInId) {
  const res = await firebase.firestore().doc(`stores/${storeId}/check-ins/${checkInId}`).get();
  return {...res.data(), id: res.id};
}

export async function fetchStoreData(storeId) {
  const res = await firebase.firestore().doc(`stores/${storeId}`).get();
  return res.data();
}

export async function fetchBookingData(storeId, bookingId) {
  const res = await firebase.firestore().doc(`stores/${storeId}/bookings/${bookingId}`).get();
  return {...res.data(), id: res.id};
}

export function getOccupiedRooms(checkIns) {
  // 체크인 한 날부터 체크아웃 할날 사이에 있으면 렌트중인 룸으로 간주함!
  const today = new Date();
  return checkIns
    .filter(({checkIn, checkOut}) => {
      const startDate = startOfDay(checkIn);
      const endDate = startOfDay(checkOut);
      return isAfter(today, startDate) && isBefore(today, endDate);
    })
    .map(({roomNo}) => roomNo);
}

function isPayment({itemCode}) {
  return transactionOptions.find(i => i.value === itemCode)?.isPayment ?? false;
}

/**
 * @param {number} num
 * @return {number}
 */
export function roundAtTwoDecimal(num) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

export function getTotalOfSalesItems(salesItems) {
  const hasComplimentary = salesItems.find(({itemCode}) => itemCode === 'complimentary');
  const res = salesItems?.filter((item) => item.itemCode !== 'complimentary').map(({itemCode, net, gst, roomTax, gross}) => {
    if (hasComplimentary && itemCode === 'roomFee') {
      return {net: 0, gst: 0, roomTax: 0, gross: 0};
    }
    return {net, gst, roomTax, gross};
  });
  const salesTotal = {net: 0, gst: 0, roomTax: 0, gross: 0};
  if (res) {
    for (const {net, gst, roomTax, gross} of res) {
      salesTotal.net += net;
      salesTotal.gst += gst;
      salesTotal.roomTax += roomTax;
      salesTotal.gross += gross;
    }
  }
  return salesTotal;
}

export function getBalance(txItems) {
  const res = txItems?.filter((item) => !isPayment(item)).map(({itemCode, amount, taxable = false}) => {
    if (itemsForAdjustment.includes(itemCode)) {
      const net = -Math.abs(amount);
      const gst = taxable !== false ? net * GST_RATE : 0;
      const roomTax = taxable === true ? net * ROOM_TAX_RATE : 0;
      const gross = net + gst + roomTax;
      return {net, gst, roomTax, gross};
    }
    const net = amount;
    const gst = taxable !== false ? net * GST_RATE : 0;
    const roomTax = taxable === true ? net * ROOM_TAX_RATE : 0;
    const gross = net + gst + roomTax;
    return {net, gst, roomTax, gross};
  });
  const salesTotal = {net: 0, gst: 0, roomTax: 0, gross: 0};
  if (res) {
    for (const {net, gst, roomTax, gross} of res) {
      salesTotal.net += net;
      salesTotal.gst += gst;
      salesTotal.roomTax += roomTax;
      salesTotal.gross += gross;
    }
  }
  const paymentTotal = txItems?.reduce((p, i) => isPayment(i) ? p + parseFloat(i.amount) : p, 0) ?? 0;
  const balance = roundAtTwoDecimal(salesTotal.gross - Math.abs(paymentTotal));
  return {salesTotal, paymentTotal, balance};
}

export function getDocId(data, key) {
  if (data) {
    return data.id ? data.id : data[key] ? data[key] : undefined;
  }
  return undefined;
}

export function getData(values, keys) {
  const data = {};
  for (const key of keys) {
    data[key] = values?.[key] ?? '';
  }
  return data;
}

/**
 * 폼에서 사용자 입력 값을 얻어옴, 폼 밸리데이션도 수행함!
 */
export async function getFormikValues(formik, defData, checkEmailOrPhone) {
  const keys = Object.keys(defData);
  const touched = {};
  for (const key of keys) {
    touched[key] = true;
  }
  const errors = await formik.setTouched(touched, true);
  if (Object.keys(errors).length > 0) {
    return undefined;
  }
  if (checkEmailOrPhone) {
    const {email, phoneNo} = formik.values;
    if (!(email || phoneNo)) {
      alert('Please enter the email or phone number!');
      return undefined;
    }
  }
  return formik.values;
}

export function devLog(...args) {
  (process.env.NODE_ENV === 'development') && console.log(...args);
}

export function getOption(options, value) {
  return options.find(i => i.value === value);
}

export function getConsoleURL(...items) {
  return `https://console.firebase.google.com/u/0/project/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/firestore/data/${items.join('~2F')}`;
}

export function getCheckInConsoleURL(storeId, checkInId) {
  return getConsoleURL('stores', storeId, 'check-ins', checkInId);
}

export function getInvoiceConsoleURL(storeId, checkInId, invoiceId) {
  return getConsoleURL('stores', storeId, 'check-ins', checkInId, 'invoices', invoiceId);
}

export function getRoomRateInfo(checkIn, checkOut, roomTypeData, isCorp, adults, applyCorpRate, noTax) {
  if (checkIn && checkOut && roomTypeData) {
    const {standardRate, weeklyRate, monthlyRate, corpRate} = roomTypeData;
    const adultCount = parseInt(adults);
    const adultCharge = adultCount > 2 ? (adultCount - 2) * 10 : 0;
    const dailyRate = (isCorp || applyCorpRate ? corpRate : standardRate);
    const roomRateInfo = dfn.toMWDAndFeeDetails(checkIn, checkOut, monthlyRate, weeklyRate, dailyRate, adultCharge, noTax);
    const isDaily = roomRateInfo.rateType === 'Daily';
    const newRateType = isDaily ? (isCorp ? 'Corporate' : 'Standard') : roomRateInfo.rateType;
    const {days: [days], totalFee: newRoomRate, hint, totalDays} = roomRateInfo;
    const showApplyCorpRate = !isDaily && days > 0;
    return {
      roomRateInfo,
      hint,
      totalDays,
      newRateType,
      newRoomRate,
      showApplyCorpRate,
      dailyRate: isDaily ? dailyRate + adultCharge : '',
    };
  }
  return {
    roomRateInfo: {},
    hint: '',
    totalDays: '',
    newRateType: '',
    newRoomRate: '',
    showApplyCorpRate: false,
    dailyRate: '',
  };
}

export async function buildRoomRateInfo(storeId, checkInId, roomTypes) {
  const checkInRef = storeDocRef(storeId, 'check-ins', checkInId);
  const checkInData = (await checkInRef.get()).data();
  const {checkIn, checkOut, roomType, rateType, adults, applyCorpRate, noTax} = checkInData;
  const roomTypeData = roomTypes.find(i => i.value === roomType);
  const isCorp = rateType === RATE_CORPORATE;
  const roomRateInfo = getRoomRateInfo(checkIn, checkOut, roomTypeData, isCorp, adults, applyCorpRate, noTax);
  console.log('buildRoomRateInfo', {checkIn, checkOut, roomTypeData, isCorp, adults, applyCorpRate, noTax}, roomRateInfo);
}

export async function buildAllRoomRateInfo(storeId) {
  {
    const batch = getBatch();
    (await storeColRef(storeId, 'sales').get()).forEach((doc) => batch.delete(doc.ref));
    await batch.commit();
  }
  {
    const batch = getBatch();
    (await storeColRef(storeId, 'payments').get()).forEach((doc) => batch.delete(doc.ref));
    await batch.commit();
  }
  const roomTypes = (await storeColRef(storeId, 'room-types').get()).docs.map(doc => doc.data());
  const checkIns = (await storeColRef(storeId, 'check-ins').where('status', '!=', 'cancelled').get()).docs.map(doc => [doc.id, doc.data()]);
  for (const [checkInId, checkInData] of checkIns) {
    const {checkIn, checkOut, roomType, rateType, adults, applyCorpRate, noTax, roomNo, name} = checkInData;
    const roomTypeData = roomTypes.find(i => i.value === roomType);
    const isCorp = rateType === RATE_CORPORATE;
    const {roomRateInfo} = getRoomRateInfo(checkIn, checkOut, roomTypeData, isCorp, adults, applyCorpRate, noTax);
    console.log('===============================================');
    console.log('buildRoomRateInfo: params', {checkInId, roomNo, name, checkIn, checkOut, roomTypeData, isCorp, adults, applyCorpRate, noTax});
    console.log('===============================================');
    console.log('buildRoomRateInfo: result', roomRateInfo);
    const invItems = (await storeColRef(storeId, `check-ins/${checkInId}/invoices`).get()).docs.map(doc => ({...doc.data(), id: doc.id}));
    console.log('invoiceItems', invItems);
    const invItemsFiltered = invItems.filter(({itemCode}) => !itemsCannotBeDeleted.includes(itemCode));
    console.log('invoiceItemsFiltered', invItemsFiltered);
    const salesItemsFromInvItems = invItemsFiltered.filter(({itemCode}) => !isPayment({itemCode}));
    const paymentItemsFromInvItems = invItemsFiltered.filter(({itemCode}) => isPayment({itemCode}));
    console.log('salesItemsFromInvItems', salesItemsFromInvItems);
    console.log('paymentItemsFromInvItems', paymentItemsFromInvItems);
    {
      const batch = getBatch();
      const paymentsRef = storeColRef(storeId, 'payments');
      const salesRef = storeColRef(storeId, 'sales');
      setDailyRoomSalesBatch(batch, storeId, checkInId, {...checkInData, roomRateInfo});
      salesItemsFromInvItems.forEach(({id, itemCode, amount, taxable, invoiceDate}) => {
        batch.set(salesRef.doc(), getSalesItem(checkInId, id, invoiceDate, itemCode, amount, taxable, roomNo));
      });
      paymentItemsFromInvItems.forEach(({id, itemCode, amount, invoiceDate}) => {
        batch.set(paymentsRef.doc(), getPaymentItem(checkInId, id, invoiceDate, itemCode, amount, roomNo));
      });
      await batch.commit();
    }
  }
}

export async function buildCarryOverDataOfDate(storeId, date) {
  date = date || new Date();
  const prevDateStr = formatDate(subDays(date, 1));
  const doc = await storeDocRef(storeId, 'carry-over-amounts', prevDateStr).get();
  const {balance: carryOver = 0} = doc.data() ?? {};
  console.log(`prevDateStr: ${prevDateStr}, carryOver: ${carryOver}`);
  const salesRef = storeColRef(storeId, 'sales');
  const paymentsRef = storeColRef(storeId, 'payments');
  const dateStr = formatDate(date);
  console.log(`dateStr: ${dateStr}`);
  const salesDocs = await salesRef.where('dateStr', '==', dateStr).get();
  const salesTotal = {net: 0, gst: 0, roomTax: 0, gross: 0};
  salesDocs.forEach((doc) => {
    if (doc.exists) {
      const {net, gst, roomTax, gross} = doc.data();
      salesTotal.net += net;
      salesTotal.gst += gst;
      salesTotal.roomTax += roomTax;
      salesTotal.gross += gross;
    }
  });
  const paymentDocs = await paymentsRef.where('dateStr', '==', dateStr).get();
  const paymentTotal = {amount: 0, creditAmount: 0, debitAmount: 0, cashAmount: 0, chequeAmount: 0};
  paymentDocs.forEach((doc) => {
    if (doc.exists) {
      const {itemCode, amount} = doc.data();
      paymentTotal.amount += amount;
      if (itemCode.endsWith('Credit')) {
        paymentTotal.creditAmount += amount;
      } else if (itemCode.endsWith('Debit')) {
        paymentTotal.debitAmount += amount;
      } else if (itemCode.endsWith('Cash')) {
        paymentTotal.cashAmount += amount;
      } else if (itemCode.endsWith('Cheque')) {
        paymentTotal.chequeAmount += amount;
      }
    }
  });
  const salesAmount = salesTotal.gross;
  const paymentAmount = paymentTotal.amount;
  const dayBalance = salesAmount - paymentAmount;
  const balance = dayBalance + carryOver;
  const data = {dateStr, carryOver, salesAmount, paymentAmount, dayBalance, balance};
  const docRef = storeDocRef(storeId, 'carry-over-amounts', dateStr);
  console.log(`store: ${storeId}, date: ${dateStr}, openBalance: ${carryOver}`);
  console.log(JSON.stringify({storeId, ...data}));
  await docRef.set(data);
}

export async function buildAllCarryOverData(fromDate, initialCarryOverAmount = 0) {
  const salesRef = fire.storeCol('sales');
  const paymentsRef = fire.storeCol('payments');
  const fromDateStr = formatDate(fromDate);
  const salesTotalList = await getSalesTotalList(fromDateStr, salesRef);
  const paymentTotalList = await getPaymentTotalList(fromDateStr, paymentsRef);
  let curDate = startOfDay(fromDate);
  const todayStr = formatDate(Date.now());
  let carryOverAmount = initialCarryOverAmount;
  const res = {};
  while (true) {
    const curDateStr = formatDate(curDate);
    const {gross: salesAmount = 0} = salesTotalList[curDateStr] ?? {};
    const {amount: paymentAmount = 0} = paymentTotalList[curDateStr] ?? {};
    const dayBalance = salesAmount - paymentAmount;
    const carryOver = carryOverAmount;
    const balance = dayBalance + carryOver;
    res[curDateStr] = {dateStr: curDateStr, carryOver, salesAmount, paymentAmount, dayBalance, balance};
    if (curDateStr === todayStr) {
      break;
    }
    carryOverAmount += salesAmount - paymentAmount;
    curDate = addDays(curDate, 1);
  }
  const batch = fire.db.batch();
  const coAmountsRef = fire.storeCol('carry-over-amounts');
  for (const [dateStr, item] of Object.entries(res)) {
    const data = {dateStr, ...item};
    batch.set(coAmountsRef.doc(dateStr), data);
    // const {carryOver, salesAmount, paymentAmount, dayBalance, balance} = data;
    // console.log(`dateStr:${dateStr}, CO:${carryOver.toFixed(2)}, S:${salesAmount.toFixed(2)}, P:${paymentAmount.toFixed(2)}, DB:${dayBalance.toFixed(2)}, B:${balance.toFixed(2)}`);
  }
  await batch.commit();
}


async function getSalesTotalList(fromDateStr) {
  const docs = await fire.storeCol('sales')
    .where('dateStr', '>=', fromDateStr)
    .get();
  const totals = {};
  docs.forEach((doc) => {
    if (doc.exists) {
      const {dateStr, net, gst, roomTax, gross} = doc.data();
      if (!totals[dateStr]) {
        totals[dateStr] = {net: 0, gst: 0, roomTax: 0, gross: 0};
      }
      totals[dateStr].net += net;
      totals[dateStr].gst += gst;
      totals[dateStr].roomTax += roomTax;
      totals[dateStr].gross += gross;
    }
  });
  return totals;
}

async function getPaymentTotalList(fromDateStr) {
  const docs = await fire.storeCol('payments')
    .where('dateStr', '>=', fromDateStr)
    .get();
  const totals = {};
  docs.forEach((doc) => {
    if (doc.exists) {
      const {dateStr, itemCode, amount} = doc.data();
      if (!totals[dateStr]) {
        totals[dateStr] = {amount: 0, creditAmount: 0, debitAmount: 0, cashAmount: 0, chequeAmount: 0};
      }
      totals[dateStr].amount += amount;
      if (itemCode.endsWith('Credit')) {
        totals[dateStr].creditAmount += amount;
      } else if (itemCode.endsWith('Debit')) {
        totals[dateStr].debitAmount += amount;
      } else if (itemCode.endsWith('Cash')) {
        totals[dateStr].cashAmount += amount;
      } else if (itemCode.endsWith('Cheque')) {
        totals[dateStr].chequeAmount += amount;
      }
    }
  });
  return totals;
}
