import React from 'react';
import PageContainer from '../../components/PageContainer';
import PaymentListTable from '../../data-tables/PaymentListTable';

export default function PaymentList() {
  return (
    <PageContainer title="Payment List">
      <PaymentListTable />
    </PageContainer>
  );
}
