import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import { startOfDay } from 'date-fns';
import { useCollectionData } from 'react-firebase-hooks/firestore';

/** @typedef {firebase.firestore.CollectionReference<firebase.firestore.DocumentData>} ColRef */
/** @typedef {firebase.firestore.DocumentReference<firebase.firestore.DocumentData>} DocRef */

class FireV8 {
  /** @type {string | null} */
  #storeId = null;
  /** @type {firebase.firestore.WriteBatch | null} */
  #batch = null;
  /**
   * @param {string | null | undefined} value
   */
  set storeId(value) {
    this.#storeId = Boolean(value) ? value : null;
  }
  get storeId() {
    return this.#storeId;
  }
  get user() {
    return firebase.auth().currentUser;
  }
  get signedIn() {
    return this.user !== null;
  }
  get db() {
    return firebase.firestore();
  }
  get serverTS() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }
  /**
   * @param {...string} path
   * @return {ColRef}
   */
  col(...path) {
    return this.db.collection(path.join('/'));
  }
  /**
   * @param {...string} path
   * @return {DocRef}
   */
  doc(...path) {
    if (path.length % 2 === 1) {
      return this.col(path.join('/')).doc();
    }
    return this.db.doc(path.join('/'));
  }
  /**
   * @return {DocRef | null}
   */
  storeRef() {
    if (!this.#storeId) {
      return null;
    }
    return this.doc('stores', this.#storeId);
  }
  /**
   * @param {...string} path
   * @return {ColRef | null}
   */
  storeCol(...path) {
    if (!this.#storeId) {
      return null;
    }
    return this.col('stores', this.#storeId, ...path);
  }
  /**
   * @param {string | string[]} path
   * @param {function(colRef: firebase.firestore.CollectionReference): firebase.firestore.Query} [onQuery]
   * @param {number} [limit]
   * @return {firebase.firestore.Query | null}
   */
  storeQuery(path, onQuery, limit) {
    const isArray = Array.isArray(path);
    if (isArray && !path.every(value => Boolean(value))) {
      return null;
    }
    const colRef = isArray ? this.storeCol(...path) : this.storeCol(path);
    if (colRef === null) {
      return null;
    }
    let query = onQuery?.(colRef) ?? colRef;
    if (limit) {
      query = query.limit(limit);
    }
    return query;
  }
  checkInQuery(status, paymentStatus, search, selectedRoom, companyId, limit = 1500) {
    if (!this.#storeId) {
      return null;
    }
    let query = this.storeCol('check-ins')
      .where('status', '==', status)
      .where('paymentStatus', '==', paymentStatus)
      .orderBy('checkOut', 'desc')
      .limit(limit);
    if (search.length > 2) {
      query = query.where('tags', 'array-contains', search.toLowerCase());
    }
    if (selectedRoom) {
      query = query.where('roomNo', '==', selectedRoom);
    }
    if (companyId) {
      query = query.where('companyId', '==', companyId);
    }
    return query;
  }
  bookingQuery(search, todayOnly, status) {
    if (!this.#storeId) {
      return null;
    }
    let query = this.storeCol('bookings').where('status', '==', status).orderBy('checkIn', 'asc');
    if (search.length > 2) {
      query = query.where('tags', 'array-contains', search.toLowerCase());
    }
    if (todayOnly) {
      const today = startOfDay(Date.now()).getTime();
      query = query.where('checkIn', '==', today);
    }
    return query;
  }
  simpleBookingQuery(status) {
    if (!this.#storeId) {
      return null;
    }
    return this.storeCol('bookings').where('status', '==', status);
  }
  companyQuery() {
    if (!this.#storeId) {
      return null;
    }
    return this.storeCol('companies').orderBy('company', 'asc');
  }
  /**
   * @param {...string} path
   * @return {DocRef | null}
   */
  storeDoc(...path) {
    if (!this.#storeId) {
      return null;
    }
    return this.doc('stores', this.#storeId, ...path);
  }
  /**
   * @param {DocRef} docRef
   * @param {firebase.firestore.DocumentData} data
   * @return {Promise<void>}
   */
  async set(docRef, data) {
    return docRef.set(this.#buildData(data, true));
  }
  /**
   * @param {DocRef} docRef
   * @param {firebase.firestore.DocumentData} data
   * @return {Promise<void>}
   */
  async update(docRef, data) {
    return docRef.update(this.#buildData(data, false));
  }
  /**
   * @param {DocRef} docRef
   * @return {Promise<void>}
   */
  async delete(docRef) {
    return docRef.delete();
  }
  /**
   * @param {DocRef} docRef
   * @param {firebase.firestore.DocumentData} data
   * @param {string} [docId]
   * @return {Promise<void>}
   */
  async upsert(docRef, data, docId) {
    if (docId) {
      return docRef.update(this.#buildData(data, false));
    } else {
      return docRef.set(this.#buildData(data, true));
    }
  }
  beginBatch() {
    this.#batch = this.db.batch();
  }
  /**
   * @param {DocRef} docRef
   * @param {firebase.firestore.DocumentData} data
   */
  setBatch(docRef, data) {
    this.#batch?.set(docRef, this.#buildData(data, true));
  }
  /**
   * @param {DocRef} docRef
   * @param {firebase.firestore.DocumentData} data
   */
  updateBatch(docRef, data) {
    this.#batch?.update(docRef, this.#buildData(data, false));
  }
  /**
   * @param {DocRef} docRef
   */
  delBatch(docRef) {
    this.#batch?.delete(docRef);
  }
  async commitBatch() {
    await this.#batch?.commit();
    this.#batch = null;
  }
  async saveCheckIn(checkInData, checkInId) {

  }
  /**
   * @param {firebase.firestore.DocumentData} data
   * @param {boolean} isCreate
   */
  #buildData(data, isCreate) {
    const {uid, displayName, email} = this.user;
    const docData = {};
    const updateInfo = {
      updatedAt: this.serverTS,
      updatedBy: uid,
      updatedName: displayName ?? email,
    };
    let createInfo = {};
    if (isCreate) {
      createInfo = {
        createdAt: this.serverTS,
        createdBy: uid,
        createdName: displayName ?? email,
      };
    }
    for (const [name, value] of Object.entries(data)) {
      if (value !== undefined) {
        docData[name] = value;
      }
    }
    return {
      ...docData,
      ...createInfo,
      ...updateInfo,
    };
  }
}

const fire = new FireV8();

const options = {idField: 'id'};

/**
 * @param {string | string[]} path
 * @param {function(colRef: firebase.firestore.CollectionReference): firebase.firestore.Query} [onQuery]
 * @param {number} [limit]
 */
export function useStoreCol(path, onQuery, limit) {
  const query = fire.storeQuery(path, onQuery, limit);
  return useCollectionData(query, options);
}

export default fire;
