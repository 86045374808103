import React from 'react';
import PageContainer from '../../components/PageContainer';
import CustomerCRUDTable from '../../data-tables/CustomerCRUDTable';

export default function CustomerList() {
  return (
    <PageContainer title="Customer List">
      <CustomerCRUDTable />
    </PageContainer>
  );
}
