import React from 'react';
import FirestoreCRUDTable from './FirestoreCRUDTable';
import useStoreId from '../hooks/useStoreId';
import RoomTypeForm from '../forms/RoomTypeForm';
import { gridCurrencyFormatter } from '../utils/utils';

const RoomTypeCRUDTable = () => {
  const storeId = useStoreId();
  return (
    <FirestoreCRUDTable
      path={storeId ? `stores/${storeId}/room-types` : null}
      columns={[
        { field: 'value', headerName: 'Code' },
        { field: 'label', headerName: 'Label' },
        { field: 'standardRate', headerName: 'Standard Rate', valueGetter: gridCurrencyFormatter, align: 'center', headerAlign: 'center' },
        { field: 'weeklyRate', headerName: 'Weekly Rate', valueGetter: gridCurrencyFormatter, align: 'center', headerAlign: 'center' },
        { field: 'monthlyRate', headerName: 'Monthly Rate', valueGetter: gridCurrencyFormatter, align: 'center', headerAlign: 'center' },
        { field: 'corpRate', headerName: 'Corp. Rate', valueGetter: gridCurrencyFormatter, align: 'center', headerAlign: 'center' },
      ]}
      title="ROOM TYPE"
      labelKey="label"
      deleteKeys={['id', 'value']}
      deleteFunctionName="deleteRoomType"
      Form={RoomTypeForm}
    />
  );
};

export default RoomTypeCRUDTable;
