import React from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import useStoreId from './useStoreId';
import fire from '../utils/fire-v8';

export default function useBookingList(dateFrom, dateTo, checkInOut = false) {
  const storeId = useStoreId();
  const db = firebase.firestore();
  let query = storeId ? db.collection(`stores/${storeId}/bookings`).where('status', '==', 'pending') : null;
  if (query) {
    if (dateFrom) {
      if (checkInOut === true) {
        query = query.where('checkIn', '>=', dateFrom.getTime());
      } else {
        query = query.where('createdAt', '>=', dateFrom).orderBy('createdAt', 'desc');
      }
    }
    if (dateTo) {
      if (checkInOut === true) {
        query = query.where('checkIn', '<=', dateTo.getTime());
      } else {
        query = query.where('createdAt', '<=', dateTo).orderBy('createdAt', 'desc');;
      }
    }
  }
  const [snapshot, loading, error] = useCollection(query);
  return [snapshot?.docs.map(doc => ({...doc.data(), id: doc.id})) ?? [], loading, error];
}

export function useAllBookingsWithRoomNo() {
  const query = fire.simpleBookingQuery('pending');
  const [snapshot, loading, error] = useCollection(query);
  return React.useMemo(() => {
    if (!snapshot) {
      return [[], loading, error];
    }
    const res = snapshot.docs
      .map(doc => ({...doc.data(), id: doc.id}))
      .filter(({roomNo, bookingType, roomCount, groupRooms}) => {
        if (bookingType !== 'group') {
          return !!roomNo;
        }
        let count = 0;
        for (let i = 0; i < roomCount; ++i) {
          const [, roomNo] = groupRooms?.[i] ?? ['', ''];
          if (roomNo) ++count;
        }
        return count > 0;
      });
    return [res, loading, error];
  }, [snapshot, loading, error]);
}

export function useAllBookingsWithRoomNoExceptBookingId(bookingId) {
  const query = fire.simpleBookingQuery('pending');
  const [snapshot, loading, error] = useCollection(query);
  return React.useMemo(() => {
    if (!snapshot) {
      return [[], loading, error];
    }
    const res = snapshot.docs
      .map(doc => ({...doc.data(), id: doc.id}))
      .filter(({id}) => id !== bookingId) // 패러미터(bookingId)로 넘어온 부킹 정보는 제외함
      .filter(({roomNo, bookingType, roomCount, groupRooms}) => {
        if (bookingType !== 'group') {
          return !!roomNo;
        }
        let count = 0;
        for (let i = 0; i < roomCount; ++i) {
          const [, roomNo] = groupRooms?.[i] ?? ['', ''];
          if (roomNo) ++count;
        }
        return count > 0;
      });
    return [res, loading, error];
  }, [snapshot, loading, error]);
}
