import { useTheme } from '@mui/material/styles';

export function useThemeColor(type, subType = 'main') {
  const theme = useTheme();
  return theme.palette[type][subType];
}

export function useThemeErrorColor(type = 'main') {
  const theme = useTheme();
  return theme.palette.error[type];
}
