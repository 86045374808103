/* eslint react/prop-types: 0 */
import React from 'react';
import { subMonths, subWeeks } from 'date-fns';
import { DateRangePicker } from '@mui/lab';
import { Box, Stack, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import deleteFill from '@iconify/icons-eva/trash-2-outline';
import MIconButton from './MIconButton';

export function useDateRange(rangeType, onChangeParam) {
  const [dateRange, setDateRange] = React.useState(getRange(rangeType));
  const onChange = (range) => {
    setDateRange(range);
    onChangeParam?.(range);
  }
  return { dateRange, setDateRange, onChange };
}

export function DateRange({
  labels = ['From', 'To'],
  dateRange,
  setDateRange,
  onChange,
  size,
  error,
  variant,
  showClearButton,
  ...others
}) {
  const theme = useTheme();
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <DateRangePicker
        startText={labels[0]}
        endText={labels[1]}
        value={dateRange}
        onChange={onChange}
        inputFormat={'yyyy-MM-dd'}
        mask={'____-__-__'}
        renderInput={(startProps, endProps) => (
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={1} alignItems="center">
            <TextField variant={variant} size={size} {...startProps} error={error} />
            <Box sx={{ display: { xs: 'none', md: 'initial' } }}>~</Box>
            <TextField variant={variant} size={size} {...endProps} error={error} />
          </Stack>
        )}
        {...others}
      />
      {showClearButton && (
        <MIconButton onClick={() => setDateRange([null, null])}>
          <Icon icon={deleteFill} width={20} height={20} color={theme.palette.error.main} />
        </MIconButton>
      )}
    </Stack>
  );
}

function getRange(type) {
  if (Array.isArray(type)) {
    return type;
  }
  switch (type) {
    case 'week':
      return [subWeeks(Date.now(), 1), new Date()];
    case 'month':
      return [subMonths(Date.now(), 1), new Date()];
    default:
      return [null, null];
  }
}
