import React from 'react';
import { Button, Stack } from '@mui/material';
import * as yup from 'yup';
import InputField from './InputField';
import useForm from '../hooks/useForm';
import { devLog, getOption, setInvoiceItem } from '../utils/utils';
import useStoreId from '../hooks/useStoreId';
import { itemsForAdjustment, transactionOptions } from '../utils/options';
import { useStoreCol } from '../utils/fire-v8';

const transactionSchema = yup.object().shape({
  itemId: yup.string(),
  itemTitle: yup.string(),
  itemCode: yup.string().required('Please select the item.'),
  amount: yup.number().positive('Please enter the positive number.').required('Please enter the amount.').typeError('Please enter a number'),
});

const defTransactionData = {
  itemId: '',
  itemCode: '',
  itemTitle: '',
  amount: '',
  note: '',
};

const TransactionForm = ({ checkInId }) => {
  const storeId = useStoreId();
  const [trList] = useStoreCol(['check-ins', checkInId, 'invoices'])
  const formik = useForm({
    editData: defTransactionData,
    editMode: false,
    validationSchema: transactionSchema,
    onSubmit: async (values) => {
      devLog('TransactionForm.onSubmit()', values);
      const {taxable, gstOnly, ...data} = values;
      if (itemsForAdjustment.includes(data.itemCode)) {
        data.taxable = trList.find(i => i.itemCode === 'roomFee').taxable;
      } else {
        data.taxable = gstOnly === true ? 'GST' : taxable;
      }
      const {label, shouldBeNegative = false} = getOption(transactionOptions, data.itemCode);
      data.itemTitle = label;
      if (shouldBeNegative && data.amount > 0) {
        data.amount = `-${data.amount}`;
      }
      devLog('TransactionForm.onSubmit()', data);
      if (isNaN(parseFloat(data.amount))) {
        alert('Please enter a valid number!');
        return;
      }
      await setInvoiceItem(storeId, checkInId, data);
    },
    message: (values) => ({ success: `Invoice item (${values.itemTitle}) has been added successfully.` }),
  });
  const {itemCode} = formik.values;
  React.useEffect(() => {
    // devLog({itemCode, trList});
    if (itemCode === 'complimentary') {
      let complAmount = 0;
      for (const {itemCode, amount} of trList ?? []) {
        if (itemCode === 'roomFee') {
          complAmount += parseFloat(amount);
        }
      }
      formik.setValues({...formik.values, amount: complAmount});
    }
    // eslint-disable-next-line
  }, [itemCode, trList]);
  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack direction='row' spacing={2} pt={2}>
        {/*<InputField name='itemType' label='Type' formik={formik} />*/}
        <InputField name='itemCode' label='Item' formik={formik} options={transactionOptions} />
        <InputField name='amount' label='Amount' formik={formik} width={100} />
        <InputField name='note' label='Note' formik={formik} />
        <InputField name='taxable' label='Tax' formik={formik} checkbox />
        <InputField name='gstOnly' label='GST Only' formik={formik} checkbox />
        <Button type='submit' variant='contained' disabled={formik.isSubmitting}>
          ADD
        </Button>
      </Stack>
    </form>
  );
};

export default TransactionForm;
