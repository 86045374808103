import { styled } from '@mui/material/styles';
import { Container, Stack, Typography } from '@mui/material';
import Page from '../components/Page';
import logoImage from '../media/one-book-192.png';
import LoginForm from '../components/LoginForm';

export default function Login() {
  // const { translate: _t } = useLocales();
  return (
    <RootStyle title={`Login | TechCube OneBook`}>
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction={'row'} spacing={2} justifyContent={'center'} mb={4}>
            <img src={logoImage} height={76} alt="TechCube OneBook" style={{marginTop: 4}} />
            <Stack direction={'column'}>
              <Typography variant="h4">TECHCUBE</Typography>
              <Typography variant="h2" sx={{mt: '-10px', color: '#EE4E34', fontWeight: 900}}>ONEBOOK</Typography>
            </Stack>
            {/*<Box sx={{ mb: 1 }}>*/}
            {/*  <LanguagePopover />*/}
            {/*</Box>*/}
          </Stack>
          {/*<AuthFirebaseSocials />*/}
          <LoginForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'start',
  padding: theme.spacing(12, 0),
}));
