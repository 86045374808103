import React from 'react';
import PageContainer from '../../components/PageContainer';
import ExpediaMailListTable from '../../data-tables/ExpediaMailListTable';

export default function ExpediaMails() {
  return (
    <PageContainer title="Expedia Mails">
      <ExpediaMailListTable />
    </PageContainer>
  );
}
