import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from '../layout/dashboard';
import LogoOnlyLayout from '../layout/LogoOnlyLayout';
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import PaymentList from '../pages/property-management/PaymentList';
import BookingList from '../pages/property-management/BookingList';
import CalendarView from '../pages/property-management/CalendarView';
import RoomList from '../pages/settings/RoomList';
import RoomTypeList from '../pages/settings/RoomTypeList';
import CheckInList from '../pages/property-management/CheckInList';
import Dashboard from '../pages/property-management/Dashboard';
import Inquiries from '../pages/customer-service/Inquiries';
import BookingRequests from '../pages/customer-service/BookingRequests';
import Page500 from '../pages/Page500';
import Login from '../pages/Login';
import Page404 from '../pages/Page404';
import HouseKeeping from '../pages/property-management/HouseKeeping';
import InvoiceItems from '../pages/settings/InvoiceItems';
import CustomerList from '../pages/customer-service/CustomerList';
import InvoiceSearch from '../pages/property-management/InvoiceSearch';
import CompanyList from '../pages/customer-service/CompanyList';
import BillingList from '../pages/property-management/BillingList';
import useAuth from '../hooks/useAuth';
import MonthlyReport from '../pages/reports/MonthlyReport';
import BookingStatus from '../pages/property-management/BookingStatus';
import Reconciliation from '../pages/reports/Reconciliation';
import ExpediaMails from '../pages/customer-service/ExpediaMails';
import BalanceList from '../pages/reports/BalanceList';

export default function Router() {
  const {user: {role}} = useAuth();
  let defaultPath;
  if (role === 'super' || role === 'owner') {
    defaultPath = '/property-management/dashboard';
  } else {
    defaultPath = '/property-management/room-status';
  }
  return useRoutes([
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ element: <Navigate to={defaultPath} replace /> }],
    },
    {
      path: '/login',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    {
      path: '/property-management',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'room-status', element: <HouseKeeping /> },
        { path: 'booking-status', element: <BookingStatus /> },
        { path: 'booking-list', element: <BookingList /> },
        { path: 'calendar-view', element: <CalendarView /> },
        { path: 'check-in-list', element: <CheckInList /> },
        { path: 'billing-list', element: <BillingList /> },
        { path: 'invoice-search', element: <InvoiceSearch /> },
        { path: 'payment-list', element: <PaymentList /> },
        // { path: 'onsite-booking', element: <OnsiteBooking /> },
      ],
    },
    {
      path: '/customer-service',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/customer-service/booking-requests" replace /> },
        { path: 'customer-list', element: <CustomerList /> },
        { path: 'company-list', element: <CompanyList /> },
        { path: 'booking-requests', element: <BookingRequests /> },
        { path: 'inquiries', element: <Inquiries /> },
        { path: 'expedia-mails', element: <ExpediaMails /> },
      ],
    },
    {
      path: '/reports',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/reports/daily-report" replace /> },
        { path: 'reconciliation', element: <Reconciliation /> },
        { path: 'balance-list', element: <BalanceList /> },
        { path: 'monthly-report', element: <MonthlyReport /> },
      ],
    },
    {
      path: '/settings',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/settings/room-list" replace /> },
        { path: 'invoice-items', element: <InvoiceItems /> },
        { path: 'room-type-list', element: <RoomTypeList /> },
        { path: 'room-list', element: <RoomList /> },
        // { path: 'staff-list', element: <RoomList /> }, // TODO
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
