import React from 'react';
import { Icon } from '@iconify/react';
import deleteFill from '@iconify/icons-eva/trash-2-outline';
import { useThemeColor } from '../hooks/useThemeColor';
import MIconButton from '../components/MIconButton';

export default function ClearFieldsButton({ formik, fields, onClear }) {
  return (
    <MIconButton
      onClick={() => {
        for (const field of fields) {
          formik.setFieldValue(field, '');
          formik.setFieldError(field, '');
          formik.setFieldTouched(field, false);
          onClear?.();
        }
      }}
    >
      <Icon icon={deleteFill} width={20} height={20} color={useThemeColor('error')} />
    </MIconButton>
  );
}
