import React from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { useCollection, useDocument } from 'react-firebase-hooks/firestore';
import useStoreId from './useStoreId';

export default function useCompanyList() {
  const storeId = useStoreId();
  const query = storeId ? firebase.firestore().collection(`stores/${storeId}/companies`).orderBy('company') : null;
  const [snapshot, loading, error] = useCollection(query);
  return React.useMemo(() => {
    return [snapshot?.docs.map((doc) => ({...doc.data(), id: doc.id})) ?? [], loading, error];
  }, [snapshot, loading, error]);
}

export function useCompany(companyId) {
  const storeId = useStoreId();
  let docRef = null;
  if (storeId && companyId) {
    docRef = firebase.firestore().doc(`stores/${storeId}/companies/${companyId}`);
  }
  const [snapshot, loading, error] = useDocument(docRef);
  return React.useMemo(() => {
    return [{id: snapshot.id, ...snapshot?.data()}, loading, error];
  }, [snapshot, loading, error]);
}
