import React from 'react';
import { Button, Stack, Tooltip } from '@mui/material';
import useStoreId from '../hooks/useStoreId';
import { createTags, formatBookingInfo, formatDateRange, formatDateTime, setStoreDoc } from '../utils/utils';
import { DateRange, useDateRange } from '../components/DateRange';
import Confirm, { useConfirm } from '../components/Confirm';
import { DeleteButton } from '../components/icon-buttons';
import { startOfDay } from 'date-fns';
import BasicTable from './BasicTable';
import { useStoreCol } from '../utils/fire-v8';
import Label from '../components/Label';

const BookingRequestListTable = () => {
  const storeId = useStoreId();
  const dateRangeProps = useDateRange('month');
  const {confirmProps, openConfirm} = useConfirm();
  const columns = [
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: ({row}) => {
        function onBook() {
          openConfirm(
            'Are you sure you want to book this booking request?',
            async () => {
              await setStoreDoc(storeId, 'booking-requests', {status: 'done'}, row.id);
              const {name, email, phone, adults, checkIn, checkOut, children, infants, bookingType = 'single', singleCount, queenCount, roomCount, roomType: bedType, message: note } = row;
              const tags = createTags(row);
              await setStoreDoc(storeId, 'bookings', {
                name, email, phoneNo: phone,
                adults: toPositiveInt(adults),
                children: toPositiveInt(children),
                infants: toPositiveInt(infants),
                status: 'pending', source: 'on-site', countCheckedIn: 0,
                bookingType, singleCount, queenCount, roomCount, bedType, note, tags,
                checkIn: startOfDay(checkIn.toDate()).getTime(),
                checkOut: startOfDay(checkOut.toDate()).getTime(),
              });
            },
            'Booking data has been created successfully!'
          );
        }
        function onCancel() {
          openConfirm(
            'Are you sure you want to cancel this booking request?',
            async () => setStoreDoc(storeId, 'booking-requests', {status: 'cancelled'}, row.id),
            'Booking request cancelled!'
          );
        }
        return (
          <Stack direction="row" alignItems="center">
            <DeleteButton onClick={onCancel} />
            <Button size="small" onClick={onBook}>BOOK</Button>
          </Stack>
        );
      },
    },
    { field: 'bookingType', headerName: 'Type', renderCell: renderType },
    { field: 'name', headerName: 'Booker', flex: 2, renderCell: renderBooker },
    { field: 'checkInOut', headerName: 'Check In / Check Out', flex: 1.5, valueGetter: ({ row }) => formatDateRange(row.checkIn, row.checkOut) },
    { field: 'guests', headerName: 'Info', flex: 2, valueGetter: ({row}) => formatBookingInfo(row) },
    { field: 'createdAt', headerName: 'Posted At', valueGetter: ({ value }) => formatDateTime(value) },
  ];
  const [dateFrom, dateTo] = dateRangeProps.dateRange;
  const [rows = [], loading, error] = useStoreCol('booking-requests', (colRef) => {
    let query = colRef.orderBy('createdAt', 'desc');
    query = query.where('status', '==', 'pending');
    if (dateFrom) {
      query = query.where('createdAt', '>=', dateFrom);
    }
    if (dateTo) {
      query = query.where('createdAt', '<=', dateTo);
    }
    return query;
  });
  return (
    <BasicTable
      rows={rows}
      loading={loading}
      error={error}
      columns={columns}
      renderLeftHeader={() => <DateRange {...dateRangeProps} size="small" />}
      // renderRightHeader={() => <StatusSelect status={status} setStatus={setStatus} />}
      renderFooter={() => <Confirm {...confirmProps} />}
      containerSx={{p: 2}}
    />
  );
};

function toPositiveInt(s) {
  const n = parseInt(s);
  return (isNaN(n) || n <= 0) ? '' : n;
}

function getBooker({row}) {
  const {name, email, phone} = row;
  const info = [];
  email && (info.push(email));
  phone && (info.push(phone));
  return `${name} (${info.join(', ')})`;
}

function renderBooker({row}) {
  if (row.message) {
    const booker = getBooker({row});
    return (
      <Tooltip title={row.message} arrow>
        <div>{booker}</div>
      </Tooltip>
    );
  }
  return getBooker({row});
}

function renderType({value}) {
  value = value ? value : 'single';
  if (value === 'group') {
    return <Label color={'info'}>{value}</Label>;
  }
  return <Label color={'warning'}>{value}</Label>;
}

export default BookingRequestListTable;
