import { createContext, useEffect, useReducer, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/app-check';
import { firebaseConfig } from '../../config';
import { devLog, fetchStoreData } from '../utils/utils';
import fire from '../utils/fire-v8';

const allowedRoles = ['super', 'owner', 'manager', 'staff', 'housekeeper'];

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  devLog('Firebase initialized!', firebaseConfig);
  if (process.env.NODE_ENV !== 'production') {
    firebase.auth().useEmulator('http://localhost:9099');
    firebase.firestore().useEmulator('localhost', 8080);
    firebase.functions().useEmulator('localhost', 5001);
  } else {
    firebase.appCheck().activate(process.env.REACT_APP_RECAPTCHA_KEY, true);
  }
}

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }

  return state;
};

const AuthContext = createContext({
  ...initialState,
  method: 'firebase',
  login: () => Promise.resolve(),
  loginWithGoogle: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  updateStoreId: () => {},
});

function AuthProvider({ children }) {
  const [profile, setProfile] = useState(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    const dispatchAuth = (user, userData) => {
      const {storeId} = userData ?? {};
      fire.storeId = storeId;
      dispatch({ type: 'INITIALISE', payload: { isAuthenticated: user !== null, user } });
      setProfile(userData);
      if (user === null) {
        firebase.auth().signOut().catch(console.error);
      }
    };
    return firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const adminInfo = await getAdminInfo(user);
          if (hasAdminRole(adminInfo.role, adminInfo.storeId)) {
            dispatchAuth(user, adminInfo);
          } else {
            dispatchAuth(null, null);
          }
        } catch (error) {
          dispatchAuth(null, null);
          console.error(error);
        }
      } else {
        dispatchAuth(null, null);
      }
    });
  }, [dispatch]);
  const login = async (email, password) => {
    const res = await firebase.auth().signInWithEmailAndPassword(email, password);
    return processSignIn(res);
  };
  const loginWithGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    const res = await firebase.auth().signInWithPopup(provider);
    return processSignIn(res);
  };
  const logout = async () => {
    await firebase.auth().signOut();
  };
  const updateStoreId = (storeData) => {
    const {storeId} = storeData;
    fire.storeId = storeId;
    setProfile({ ...profile, ...storeData });
  };
  const auth = { ...state.user };
  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        user: {
          id: auth.uid,
          email: auth.email,
          photoURL: auth.photoURL,
          displayName: auth.displayName,
          ...profile,
          // role: profile?.role,
          // storeId: profile?.storeId,
          // storeTitle: profile?.storeTitle,
          // merchantId: profile?.merchantId,
        },
        login,
        loginWithGoogle,
        logout,
        updateStoreId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function hasAdminRole(role, storeId) {
  return role === 'super' || (allowedRoles.includes(role) && !!storeId);
}

async function getAdminInfo(user) {
  const {claims: {role, storeId}} = await user.getIdTokenResult();
  if (role === 'super') {
    return {role};
  }
  const storeData = await fetchStoreData(storeId);
  return {...storeData, role};
}

async function processSignIn(res) {
  const { role, storeId } = await getAdminInfo(res.user);
  const isAdmin = hasAdminRole(role, storeId);
  if (!isAdmin) {
    await firebase.auth().signOut();
    throw new Error();
  }
  return res;
}

export { AuthContext, AuthProvider };
