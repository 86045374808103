import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import * as yup from 'yup';
import useForm from '../hooks/useForm';
import InputField from './InputField';
import { devLog, formatDateTime, setStoreDoc, storeDocRef } from '../utils/utils';
import useStoreId from '../hooks/useStoreId';
import { houseKeepingOptions } from '../utils/options';
import useAuth from '../hooks/useAuth';
import { DeleteButton } from '../components/icon-buttons';
import Confirm, { useConfirm } from '../components/Confirm';

const houseKeepingFormSchema = yup.object().shape({
  statusCode: yup.string().required(),
});

const defHouseKeepingData = {
  id: '',
  statusCode: '',
  note: '',
};

const HouseKeepingForm = ({ editData, editMode, onCloseDialog }) => {
  const [messageList, setMessageList] = React.useState(() => {
    const {note} = editData;
    return Array.isArray(note) ? note : note ? [note] : [];
  });
  const storeId = useStoreId();
  const {user} = useAuth();
  const {confirmProps, openConfirm} = useConfirm();
  const formik = useForm({
    editData: {...defHouseKeepingData, ...editData},
    editMode,
    validationSchema: houseKeepingFormSchema,
    onSubmit: async (values) => {
      devLog('HouseKeepingForm.onSubmit()', values);
    },
    onReset: () => {
      // TODO:
    },
    message: 'House keeping information has been saved successfully.',
  });
  const {id, statusCode} = formik.values;
  React.useEffect(() => {
    if (!(storeId && id && statusCode)) {
      return;
    }
    const statusLabel = houseKeepingOptions.find(i => i.value === statusCode)['label'] ?? '';
    setStoreDoc(storeId, 'house-keeping', {statusCode, statusLabel}, id).catch(console.error);
  }, [storeId, id, statusCode]);
  async function onAddMessage() {
    const {id, message} = formik.values;
    if (id && message) {
      const newMessageList = [...messageList, {name: user.displayName ?? user.email, message, date: Date.now()}];
      await setStoreDoc(storeId, 'house-keeping', {note: newMessageList}, id);
      const snapshot = await storeDocRef(storeId, 'house-keeping', id).get();
      await formik.setFieldValue('message', '');
      const {note} = snapshot.data();
      setMessageList(note);
    }
  }
  function onDeleteMessage(index) {
    openConfirm(
      'Are you sure you want delete the message?',
      async () => {
        const newMessageList = messageList.filter((item, i) => i !== index);
        await setStoreDoc(storeId, 'house-keeping', {note: newMessageList}, id);
        const snapshot = await storeDocRef(storeId, 'house-keeping', id).get();
        const {note} = snapshot.data();
        setMessageList(note);
      },
      'The message has been deleted successfully!'
    )
  }
  function onDeleteAll() {
    openConfirm(
      'Are you sure you want delete all the messages?',
      async () => {
        const newMessageList = [];
        await setStoreDoc(storeId, 'house-keeping', {note: newMessageList}, id);
        const snapshot = await storeDocRef(storeId, 'house-keeping', id).get();
        const {note} = snapshot.data();
        setMessageList(note);
      },
      'All messages have been deleted successfully!'
    )
  }
  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack direction="column" spacing={2} pt={2} mb={2}>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="flex-start">
          <InputField name="statusCode" label="Status" formik={formik} options={houseKeepingOptions} />
        </Stack>
        <Stack direction={'column'} mt={2}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant={'overline'} color={'text.disabled'} sx={{mb:1}}>MESSAGE LOGS</Typography>
            {messageList.length > 1 && <Button size={'small'} variant={'outlined'} color={'success'} onClick={onDeleteAll}>DELETE ALL MESSAGES</Button>}
          </Stack>
          {messageList.length <= 0 && (
            <Typography sx={{color: 'text.disabled', fontSize: '.8rem'}}>
              <i>Please add the message.</i>
            </Typography>
          )}
          {messageList.map(({name, date, message}, index) => {
            return (
              <Stack direction={'row'} alignItems={'center'} key={`${index}-${date}`} spacing={2}>
                <Stack direction={'column'}>
                  {name && <Typography sx={{color: 'text.disabled', fontSize: '.7rem'}}>{name}</Typography>}
                  {date && <Typography sx={{color: 'text.disabled', fontSize: '.7rem'}}>{formatDateTime(date)}</Typography>}
                </Stack>
                <Typography sx={{fontSize: '.8rem', flex: 1}}>{message}</Typography>
                <DeleteButton onClick={() => onDeleteMessage(index)} />
              </Stack>
            );
          })}
        </Stack>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems={'center'}>
          <InputField name="message" label="Message" formik={formik} />
          <Button variant={'contained'} onClick={onAddMessage}>ADD</Button>
        </Stack>
      </Stack>
      <Button variant="contained" color="error" disabled={formik.isSubmitting} onClick={onCloseDialog}>
        CLOSE
      </Button>
      <Confirm {...confirmProps} />
    </form>
  );
};

export default HouseKeepingForm;
