import React from 'react';
import { Button } from '@mui/material';
import ResponsiveDialog, { useResponsiveDialog } from './ResponsiveDialog';
import CustomerListTable from '../data-tables/CustomerListTable';

const CustomerSearchDialog = ({open, onClose, maxWidth, fullScreen, onSelect}) => (
  <ResponsiveDialog {...{open, onClose, maxWidth, fullScreen}} title={'CUSTOMER SEARCH'}>
    <CustomerListTable doNotQueryIfEmpty simpleList onSelect={(row) => {onSelect?.(row); onClose()}} doNotUseCard />
    <Button variant='contained' color='error' onClick={onClose}>
      CLOSE
    </Button>
  </ResponsiveDialog>
);

export function useCustomerSearchDialog(onSelect) {
  const {props, open, close} = useResponsiveDialog();
  return {
    open, close, props: {...props, onSelect}
  };
}

export default CustomerSearchDialog;
