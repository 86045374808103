import useAsyncStatus from './useAsyncStatus';
import { useFormik } from 'formik';

export default function useForm({ editData, editMode, validationSchema, onSubmit, onReset, message, onBeforeSubmit }) {
  const execWithFeedback = useAsyncStatus();
  return useFormik({
    enableReinitialize: true,
    initialValues: editData,
    validationSchema,
    onSubmit: async (values, helpers) => {
      if (typeof onBeforeSubmit === 'function') {
        const res = onBeforeSubmit();
        if (!res) {
          return; // onBeforeSubmit 이 false 를 반환하면 더이상 서밋 프로세스를 진행하지 않음!
        }
      }
      function getMessage() {
        if (typeof message === 'function') {
          return message(values);
        }
        if (typeof message === 'string') {
          return {success: message};
        }
        return message;
      }
      const res = await execWithFeedback(() => onSubmit(values, helpers), getMessage());
      // if res is null, something went wrong...
      if (!editMode && res != null) {
        helpers.resetForm();
      }
      return res;
    },
    onReset: (values, helpers) => {
      onReset?.(values, helpers);
    },
  });
}
