import React from 'react';
import {Box, Button, Stack} from '@mui/material';
import {formatCurrency, formatDate} from '../utils/utils';
import {DateRange, useDateRange} from '../components/DateRange';
import {useStoreCol} from '../utils/fire-v8';
import BasicTable from './BasicTable';
import {StandaloneInputField} from '../forms/InputField';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import jsPDF from 'jspdf';
import {format} from 'date-fns';
import useAuth from '../hooks/useAuth';
import dfn from '../utils/dfn';

const BalanceListTable = () => {
  const {user} = useAuth();
  const dateStartedStr = dfn.format(user['dateStarted']);
  const dateRangeProps = useDateRange('month');
  const [dateFrom, dateTo] = dateRangeProps.dateRange;
  const [ascending, setAscending] = React.useState(false);
  const [rows = [], loading, error] = useStoreCol('carry-over-amounts', (colRef) => {
    let query = colRef.orderBy('dateStr', ascending ? 'asc' : 'desc');
    if (dateFrom) {
      query = query.where('dateStr', '>=', formatDate(dateFrom));
    }
    if (dateTo) {
      query = query.where('dateStr', '<=', formatDate(dateTo));
    }
    return query;
  });
  const columns = [
    { field: 'dateStr', headerName: 'Date', headerAlign: 'center', align: 'center' },
    { field: 'salesAmount', headerName: 'Total Sales', headerAlign: 'center', align: 'right', renderCell: renderCurrency },
    { field: 'paymentAmount', headerName: 'Total Payment', headerAlign: 'center', align: 'right', renderCell: renderCurrency },
    { field: 'dayBalance', headerName: 'Balance Of Day', headerAlign: 'center', align: 'right', renderCell: renderCurrency },
    { field: 'carryOver', headerName: 'Carry Over', headerAlign: 'center', align: 'right', renderCell: renderCurrency },
    { field: 'balance', headerName: 'Balance', headerAlign: 'center', align: 'right', renderCell: renderCurrency },
  ];
  return (
    <BasicTable
      rows={rows.filter(({dateStr}) => dateStr >= dateStartedStr)}
      loading={loading}
      error={error}
      columns={columns}
      renderLeftHeader={() => {
        return (
          <Stack direction={'row'} spacing={2}>
            <DateRange {...dateRangeProps} size="small" />
            <StandaloneInputField label={'Ascending'} checkbox value={ascending} onChange={(e) => setAscending(e.target.checked)} />
            <Button variant={'contained'} startIcon={<FileDownloadOutlinedIcon />} onClick={() => exportAsPDF(user, dateFrom, dateTo, rows)}>PDF DOWNLOAD</Button>
          </Stack>
        );
      }}
      containerSx={{p: 2}}
    />
  );
};

function renderCurrency({field, value}) {
  if (field === 'balance') {
    if (value < 0) {
      return <Box component={'span'} sx={{color: 'warning.main'}}>{formatCurrency(value)}</Box>;
    }
    return formatCurrency(value);
  }
  if (value < 0) {
    return <Box component={'span'} sx={{color: 'warning.main'}}>{formatCurrency(value)}</Box>;
  }
  return <Box component={'span'} sx={{color: 'text.disabled'}}>{formatCurrency(value)}</Box>;
}

async function exportAsPDF(user, dateFrom, dateTo, rows) {
  if (!(user?.storeId && dateFrom && dateTo)) {
    return;
  }
  const fontSize = 8.5;
  const headStyles = {halign: 'center', valign: 'middle', fontSize};
  const titleStyles = {halign: 'center', valign: 'middle', fontSize};
  const valueStyles = {halign: 'right', valign: 'middle', fontSize};
  const balanceStyles = {halign: 'right', valign: 'middle', fontSize: 11};
  const head = ['Date', 'Total Sales', 'Total Payment', 'Carry Over', 'Balance'].map((content) => ({content, styles: headStyles}));
  const body = [];
  for (const {dateStr, salesAmount, paymentAmount, carryOver, balance} of rows) {
    const row = [dateStr, salesAmount, paymentAmount, carryOver, balance].map((content, index) => {
      if (index === 0) {
        return {content, styles: titleStyles};
      } else if (index === 4) {
        return {content: formatCurrency(content), styles: balanceStyles};
      }
      return {content: formatCurrency(content), styles: valueStyles};
    });
    body.push(row);
  }
  const doc = new jsPDF({orientation: 'p'});
  let startY = 20;
  // let startX = 14;
  const pageCenterX = doc.internal.pageSize.getWidth() / 2;
  // const pageRightX = doc.internal.pageSize.getWidth() - startX - 2;
  doc.setFontSize(15);
  doc.text(`${user.title} Balance List`, pageCenterX, startY, {align: 'center'});
  startY += 5.5;
  doc.setFontSize(12);
  doc.text(`${format(dateFrom, 'LLLL d, yyyy')} ~ ${format(dateTo, 'LLLL d, yyyy')}`, pageCenterX, startY, {align: 'center'});
  startY += 5;
  const tableOptions = {startY, head: [head], body};
  doc.autoTable(tableOptions);
  doc.save(`${user.title}-balance-list-${format(dateFrom, 'yyyyMMdd')}-${format(dateTo, 'yyyyMMdd')}.pdf`);
}

export default BalanceListTable;
