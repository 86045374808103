import React from 'react';
import FirestoreListTable from './FirestoreListTable';
import { formatDateTime } from '../utils/utils';
import { DateRange, useDateRange } from '../components/DateRange';
import { usePaymentListQuery } from '../hooks/usePaymentList';

const PaymentListTable = () => {
  const dateRangeProps = useDateRange('month');
  const query = usePaymentListQuery(dateRangeProps.dateRange);
  const columns = [
    { field: 'source', headerName: 'Source' },
    { field: 'cardInfo', headerName: 'Info', flex: 2 },
    { field: 'status', headerName: 'Status' },
    { field: 'amount', headerName: 'Amount' },
    {
      field: 'createdAt',
      headerName: 'Date',
      valueGetter: ({ row }) => formatDateTime(row.updatedAt ?? row.createdAt),
    },
  ];
  return (
    <FirestoreListTable
      query={query}
      columns={columns}
      mapRows={(snapshot) => snapshot?.docs.map(getPaymentData) ?? []}
      renderLeftHeader={() => <DateRange {...dateRangeProps} size="small" />}
    />
  );
};

function getPaymentData(doc) {
  const {
    type,
    data: {
      object: {
        payment: {
          id,
          location_id: locationId,
          order_id: orderId,
          employee_id: employeeId,
          status,
          source_type: sourceType,
          total_money: { amount, currency },
          created_at: createdAt,
          updated_at: updatedAt,
          card_details: cardDetails,
          note,
          receipt_number: receiptNumber,
          receipt_url: receiptUrl,
        },
      },
    },
  } = doc.data();
  const res = {
    type,
    id,
    locationId,
    orderId,
    employeeId,
    status,
    sourceType,
    amount,
    currency,
    createdAt,
    updatedAt,
    note,
    receiptNumber,
    receiptUrl,
    cardInfo: note,
  };
  if (cardDetails) {
    const {
      card: { card_brand: cardBrand, card_type: cardType, last_4: last4, exp_month: expMonth, exp_year: expYear },
    } = cardDetails;
    return {
      ...res,
      cardInfo: !note ? (
        `${cardBrand} ${cardType} (****${last4}, ${expYear}/${expMonth})`
      ) : (
        <>
          {note}
          <br />
          {cardBrand} {cardType} (****{last4}, {expYear}/{expMonth})
        </>
      ),
    };
  }
  return res;
}

export default PaymentListTable;
