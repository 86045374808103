import React from 'react';
import { IconButton as MuiIconButton, Tooltip } from '@mui/material';
import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/edit-fill';
import deleteFill from '@iconify/icons-eva/trash-2-outline';
import printerTowTone from '@iconify/icons-ant-design/profile-twotone';
import copyFill from '@iconify/icons-eva/copy-fill';
import checkOutline from '@iconify/icons-ant-design/check-circle-outline';
import minusOutline from '@iconify/icons-ant-design/minus-circle-outline';
import searchFill from '@iconify/icons-eva/search-fill';
import nextArrow from '@iconify/icons-eva/arrow-forward-fill';
import prevArrow from '@iconify/icons-eva/arrow-back-fill';

export const IconButton = ({onClick, tooltip, icon, color = 'primary', size = 20, disabled}) => {
  if (!tooltip) {
    return (
      <MuiIconButton color={color} onClick={onClick} disabled={disabled}>
        <Icon icon={icon} width={size} height={size} />
      </MuiIconButton>
    );
  }
  return (
    <Tooltip title={tooltip} arrow>
      <MuiIconButton color={color} onClick={onClick} disabled={disabled}>
        <Icon icon={icon} width={size} height={size} />
      </MuiIconButton>
    </Tooltip>
  );
};

export const EditButton = ({onClick, tooltip = 'Edit', disabled}) => {
  return <IconButton onClick={onClick} tooltip={tooltip} icon={editFill} disabled={disabled} />;
};

export const DeleteButton = ({onClick, tooltip = 'Delete', disabled}) => {
  return <IconButton onClick={onClick} tooltip={tooltip} icon={deleteFill} color={'error'} disabled={disabled} />;
};

export const InvoiceButton = ({onClick, tooltip = 'Invoice', disabled}) => {
  return <IconButton onClick={onClick} tooltip={tooltip} icon={printerTowTone} color={'success'} disabled={disabled} />;
};

export const CopyButton = ({onClick, tooltip = 'Copy', disabled}) => {
  return <IconButton onClick={onClick} tooltip={tooltip} icon={copyFill} color={'success'} disabled={disabled} />;
};

export const CheckInButton = ({onClick, tooltip = 'Check In', disabled}) => {
  return <IconButton onClick={onClick} tooltip={tooltip} icon={checkOutline} color={'info'} disabled={disabled} />;
};

export const CheckOutButton = ({onClick, tooltip = 'Check Out', disabled}) => {
  return <IconButton onClick={onClick} tooltip={tooltip} icon={minusOutline} color={'info'} disabled={disabled} />;
};

export const SearchButton = ({onClick, tooltip = 'Search', disabled}) => {
  return <IconButton onClick={onClick} tooltip={tooltip} icon={searchFill} disabled={disabled} />;
};

export const NextButton = ({onClick, tooltip, disabled}) => {
  return <IconButton onClick={onClick} tooltip={tooltip} size={28} icon={nextArrow} disabled={disabled} />;
};

export const PrevButton = ({onClick, tooltip, disabled}) => {
  return <IconButton onClick={onClick} tooltip={tooltip} size={28} icon={prevArrow} disabled={disabled} />;
};
