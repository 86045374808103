import firebase from 'firebase/app';
import 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import useStoreId from './useStoreId';

export default function useInvoiceItemTypeList() {
  const storeId = useStoreId();
  const db = firebase.firestore();
  let query = storeId ? db.collection(`stores/${storeId}/invoice-items`) : null;
  const [snapshot, loading, error] = useCollection(query);
  return [snapshot?.docs.map(doc => ({...doc.data(), id: doc.id})) ?? [], loading, error];
}

export function useInvoiceItems(checkInId) {
  const storeId = useStoreId();
  const db = firebase.firestore();
  let query = (storeId && checkInId) ? db.collection(`stores/${storeId}/check-ins/${checkInId}/invoices`) : null;
  const [snapshot, loading, error] = useCollection(query);
  return [snapshot?.docs.map(doc => ({...doc.data(), id: doc.id})) ?? [], loading, error];
}
