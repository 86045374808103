export const PATH_AUTH = {
  login: '/login',
  resetPassword: '/reset-password',
};

export const PATH_DASHBOARD = {
  root: '/dashboard',
};

export const PATH_PAGE = {};

export const PATH_DOCS = {};
