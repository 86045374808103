import React from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import useAuth from './useAuth';
import useStoreId from './useStoreId';

export default function useVacantRoomList() {
  const storeId = useStoreId();
  const query = storeId ? firebase.firestore().collection(`stores/${storeId}/house-keeping`).where('statusCode', '==', 'V') : null;
  const [snapshot, loading, error] = useCollection(query);
  return React.useMemo(() => {
    return [snapshot?.docs.map((doc) => ({ ...doc.data(), id: doc.id })) ?? [], loading, error];
  }, [snapshot, loading, error]);
}
