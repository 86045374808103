import React from 'react';
import { Link } from '@mui/material';
import { getCheckInConsoleURL } from '../utils/utils';
import useStoreId from '../hooks/useStoreId';
// import LaunchIcon from '@mui/icons-material/Launch';
// import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export function CheckInLink({checkInId}) {
  const storeId = useStoreId();
  return (
    <Link
      target={'_blank'}
      rel="noreferrer"
      href={getCheckInConsoleURL(storeId, checkInId)}
    >
      {checkInId.slice(0, 4) + '...' + checkInId.slice(-4)}
    </Link>
  );
}
