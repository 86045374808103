import React from 'react';
import { Button, Card, CardHeader, CircularProgress, Divider, Grid, Stack, Typography } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import PageContainer from '../../components/PageContainer';
import { devLog, formatCurrency, formatDate } from '../../utils/utils';
import {
  endOfMonth,
  endOfQuarter,
  endOfYear,
  format,
  startOfDay,
  startOfQuarter,
  startOfYear,
  subMonths,
  subQuarters,
  subYears
} from 'date-fns';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { ExportToCsv } from 'export-to-csv';
import useAuth from '../../hooks/useAuth';
import fire, { useStoreCol } from '../../utils/fire-v8';
import { itemsForAdjustment, monthOptions, yearOptions } from '../../utils/options';
import { StandaloneInputField } from '../../forms/InputField';
import dfn from '../../utils/dfn';

function Heading({label, subtitle, from, to}) {
  const {user} = useAuth();
  const isSuper = user.role === 'super';
  return (
    <Typography
      variant={'overline'}
      sx={{
        textAlign: 'center',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      }}
    >
      {label}
      <br/>
      <small>{subtitle}</small>
      {isSuper && <br/>}
      {isSuper && <small>{from ? `F: ${from}` : '--'}</small>}
      {isSuper && <br/>}
      {isSuper && <small>{to ? `T: ${to}` : '--'}</small>}
    </Typography>
  );
}

function RowTitle({label}) {
  return (
    <Typography
      variant={'caption'}
      sx={{textAlign: 'right', pr: 1}}
    >
      {label}
    </Typography>
  );
}

function Value({value, color}) {
  return (
    <Typography
      variant={'caption'}
      color={color}
      sx={{textAlign: 'right', pr: 1}}
    >
      {formatCurrency(value)}
    </Typography>
  );
}

const MonthlyReport = () => {
  const {user} = useAuth();
  const [year, setYear] = React.useState(() => (new Date()).getFullYear());
  const [month, setMonth] = React.useState(() => (new Date()).getMonth() + 1);
  const [reportData, reportLoading] = useMonthlyReportData(year, month);
  const [arAmount, arAmountLoading] = useARAmount();
  const loading = reportLoading || arAmountLoading;
  const reportDate = dateFromYearMonth(year, month)
  return (
    <PageContainer title={'Monthly Report'}>
      <Card sx={{px: 1, pb: 4}}>
        <CardHeader
          title={`Report For : ${format(reportDate, 'LLLL, yyyy')}`}
          sx={{pb: 4}}
          action={(
            <Stack direction={'row'} spacing={1}>
              <Button variant={'contained'} startIcon={<FileDownloadOutlinedIcon />} onClick={() => exportAsCSV(user, reportData, arAmount, reportDate)}>EXCEL DOWNLOAD</Button>
              <Button variant={'contained'} startIcon={<FileDownloadOutlinedIcon />} onClick={() => exportAsPDF(user, reportData, arAmount, reportDate)}>PDF DOWNLOAD</Button>
            </Stack>
          )}
        />
        <Stack direction={'row'} spacing={2} pl={2} mb={4}>
          <StandaloneInputField label={'Select Year'} width={'150px'} options={yearOptions} value={year} onChange={({target: {value}}) => setYear(value)} />
          <StandaloneInputField label={'Select Month'} width={'150px'} options={monthOptions} value={month} onChange={({target: {value}}) => setMonth(value)} />
        </Stack>
        {loading && (
          <Stack p={4} direction={'row'} justifyContent={'center'} alignItems={'center'}>
            <CircularProgress />
          </Stack>
        )}
        {!loading && (
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <Stack direction={'column'} spacing={2}>
                <Heading label={'Category'} subtitle={'Items'} />
                <Divider />
                <RowTitle label={'Net Sales'} />
                <Divider />
                <RowTitle label={'GST (5%)'} />
                <RowTitle label={'Room Tax (8%)'} />
                <RowTitle label={'Tax Total'} />
                <Divider />
                <RowTitle label={'Gross Sales'} />
                <Divider />
                <RowTitle label={'Credit'} />
                <RowTitle label={'Debit'} />
                <RowTitle label={'Cash'} />
                <RowTitle label={'Cheque'} />
                <Divider />
                <RowTitle label={'Payment Total'} />
                <Divider />
                <RowTitle label={'Carry Over'} />
                <RowTitle label={'A.R.'} />
                <RowTitle label={'Employee Amount'} />
                <RowTitle label={'Balance'} />
                <Divider />
              </Stack>
            </Grid>
            {reportData.map((item) => {
              const {
                title, subtitle,
                from, to,
                net,
                gst,
                roomTax,
                gross,
                payment,
                // adjustment,
                paymentCredit,
                paymentDebit,
                paymentCash,
                paymentCheque,
                carryOver,
                balance,
                employeeAmount,
              } = item;
              const xs = title === 'YEAR' || title === 'PRV YEAR' ? 2 : 1;
              return (
                <Grid item xs={xs} key={title}>
                  <Stack direction={'column'} spacing={2}>
                    <Heading label={title} subtitle={subtitle} from={from} to={to} />
                    <Divider />
                    <Value value={net} color={'warning.main'} />
                    <Divider />
                    <Value value={gst} color={'text.disabled'} />
                    <Value value={roomTax} color={'text.disabled'} />
                    <Value value={gst + roomTax} color={'primary.main'} />
                    <Divider />
                    <Value value={gross} color={'success.main'} />
                    <Divider />
                    <Value value={paymentCredit} color={'text.disabled'} />
                    <Value value={paymentDebit} color={'text.disabled'} />
                    <Value value={paymentCash} color={'text.disabled'} />
                    <Value value={paymentCheque} color={'text.disabled'} />
                    <Divider />
                    <Value value={payment} color={'info.main'} />
                    <Divider />
                    <Value value={carryOver} color={'text.disabled'} />
                    <Value value={arAmount} color={'text.disabled'} />
                    <Value value={employeeAmount} color={'text.disabled'} />
                    <Value value={balance} color={'error.main'} />
                    <Divider />
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Card>
    </PageContainer>
  );
};

function dateFromYearMonth(year, month) {
  year = typeof year !== 'number' ? parseInt(year) : year;
  month = typeof month !== 'number' ? parseInt(month) : month;
  return new Date(year, month - 1, 1, 0, 0, 0, 0);
}

export function useDashboardReportData() {
  const [year, month] = React.useMemo(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    return [year, month];
  }, []);
  return useMonthlyReportData(year, month);
}

function useARAmount() {
  const [amount, setAmount] = React.useState(0);
  const [data, loading, error] = useStoreCol('check-ins', (colRef) => {
    return colRef.where('status', '==', 'checked-out').where('paymentStatus', '==', 'unpaid');
  });
  React.useEffect(() => {
    if (loading || error) {
      return;
    }
    (async () => {
      const rows = [];
      for (const {id} of data ?? []) {
        const salesDocs = await fire.storeCol('sales').where('checkInId', '==', id).get();
        salesDocs.forEach((doc) => rows.push({...doc.data(), id: doc.id}))
        const paymentDocs = await fire.storeCol('payments').where('checkInId', '==', id).get();
        paymentDocs.forEach((doc) => rows.push({...doc.data(), id: doc.id}))
      }
      devLog(rows);
      const amount = rows.reduce((p, {amount, gross}) => p + (amount ?? gross), 0);
      setAmount(amount);
    })();
  }, [data, loading, error]);
  return [amount, loading, error];
}

const emptyArray = [];

function useMonthlyReportData(year, month) {
  const {user} = useAuth();
  const dateStartedStr = dfn.format(user['dateStarted']);
  const fromDate = startOfYear(subYears(dateFromYearMonth(year, month), 1));
  const fromDateStr = formatDate(fromDate);
  const dateStr = dateStartedStr > fromDateStr ? dateStartedStr : fromDateStr;
  const [employeeRateData = emptyArray, checkInsLoading, checkInsError] = useStoreCol('check-ins', (colRef) => colRef.where('createdAt', '>=', fromDate).where('isEmployeeRate', '==', true));
  const [salesData = emptyArray, salesLoading, salesError] = useStoreCol('sales', (colRef) => colRef.where('dateStr', '>=', dateStr));
  const [paymentData = emptyArray, paymentLoading, paymentError] = useStoreCol('payments', (colRef) => colRef.where('dateStr', '>=', dateStr));
  const [carryOverData = emptyArray, carryOverLoading, carryOverError] = useStoreCol('carry-over-amounts', (colRef) => colRef.where('dateStr', '>=', dateStr));
  const [reportData, setReportData] = React.useState([]);
  const isLoading = checkInsLoading || salesLoading || paymentLoading || carryOverLoading;
  const hasError = checkInsError || salesError || paymentError || carryOverError;
  React.useEffect(() => {
    if (isLoading || hasError) {
      return;
    }
    const {
      monthStart, monthEnd,
      prevMonthStart, prevMonthEnd,
      lyrMonthStart, lyrMonthEnd,
      qtrStart, qtrEnd,
      prevQtrStart, prevQtrEnd,
      lyrQtrStart, lyrQtrEnd,
      yearStart, yearEnd,
      prevYearStart, prevYearEnd,
    } = getMonthlyReportDates(year, month);
    const reportData = [
      calculateReportData(employeeRateData, salesData, paymentData, carryOverData, 'MTH', format(monthStart, 'yyyy LLL'), [monthStart, monthEnd]),
      calculateReportData(employeeRateData, salesData, paymentData, carryOverData, 'PRV MTH', format(prevMonthStart, 'yyyy LLL'), [prevMonthStart, prevMonthEnd]),
      calculateReportData(employeeRateData, salesData, paymentData, carryOverData, 'LYR MTH', format(lyrMonthStart, 'yyyy LLL'), [lyrMonthStart, lyrMonthEnd]),
      calculateReportData(employeeRateData, salesData, paymentData, carryOverData, 'QTR', format(qtrStart, 'yyyy qqq'), [qtrStart, qtrEnd]),
      calculateReportData(employeeRateData, salesData, paymentData, carryOverData, 'PRV QTR', format(prevQtrStart, 'yyyy qqq'), [prevQtrStart, prevQtrEnd]),
      calculateReportData(employeeRateData, salesData, paymentData, carryOverData, 'LYR QTR', format(lyrQtrStart, 'yyyy qqq'), [lyrQtrStart, lyrQtrEnd]),
      calculateReportData(employeeRateData, salesData, paymentData, carryOverData, 'YEAR', format(yearStart, 'yyyy'), [yearStart, yearEnd]),
      calculateReportData(employeeRateData, salesData, paymentData, carryOverData, 'PRV YEAR', format(prevYearStart, 'yyyy'), [prevYearStart, prevYearEnd]),
    ];
    setReportData(reportData);
  }, [year, month, isLoading, hasError, employeeRateData, salesData, paymentData, carryOverData]);
  return [reportData, isLoading, salesData, paymentData, carryOverData];
}

/**
 * @param {number | string} year
 * @param {number | string} month
 * @return {{
 *   monthEnd: Date,
 *   lyrMonthEnd: Date,
 *   prevYearStart: Date,
 *   prevMonthEnd: Date,
 *   monthStart: Date,
 *   qtrEnd: Date,
 *   yearEnd: Date,
 *   lyrMonthStart: Date,
 *   prevMonthStart: Date,
 *   prevQtrStart: Date,
 *   lyrQtrEnd: Date,
 *   qtrStart: Date,
 *   lyrQtrStart: Date,
 *   prevYearEnd: Date,
 *   prevQtrEnd: Date,
 *   yearStart: Date
 * }}
 */
function getMonthlyReportDates(year, month) {
  year = typeof year === 'string' ? parseInt(year) : year;
  month = typeof month === 'string' ? parseInt(month) : month;
  const today = startOfDay(new Date());
  const startDay = new Date(year, month - 1, 1, 0, 0, 0, 0);
  const endDayTemp = endOfMonth(startDay);
  const endDay = today < endDayTemp ? today : endDayTemp;

  const monthStart = startDay;
  const monthEnd = endDay;
  const prevMonthStart = subMonths(monthStart, 1);
  const prevMonthEnd = endOfMonth(prevMonthStart);
  const lyrMonthStart = subYears(monthStart, 1);
  const lyrMonthEnd = endOfMonth(lyrMonthStart);

  const qtrStart = startOfQuarter(monthStart);
  const qtrEndTemp = endOfQuarter(qtrStart);
  const qtrEnd = qtrEndTemp > monthEnd ? monthEnd : qtrEndTemp;
  const prevQtrStart = subQuarters(qtrStart, 1);
  const prevQtrEnd = endOfQuarter(prevQtrStart);
  const lyrQtrStart = subYears(qtrStart, 1);
  const lyrQtrEnd = endOfQuarter(lyrQtrStart);

  const yearStart = startOfYear(monthStart);
  const yearEnd = endDay;
  const prevYearStart = subYears(yearStart, 1);
  const prevYearEnd = endOfYear(prevYearStart);

  // devLog({
  //   CurMonth: `${formatDate(monthStart)} ~ ${formatDate(monthEnd)}`,
  //   PrevMonth: `${formatDate(prevMonthStart)} ~ ${formatDate(prevMonthEnd)}`,
  //   LyrMonth: `${formatDate(lyrMonthStart)} ~ ${formatDate(lyrMonthEnd)}`,
  //   CurQtr: `${formatDate(qtrStart)} ~ ${formatDate(qtrEnd)}`,
  //   PrevQtr: `${formatDate(prevQtrStart)} ~ ${formatDate(prevQtrEnd)}`,
  //   LyrQtr: `${formatDate(lyrQtrStart)} ~ ${formatDate(lyrQtrEnd)}`,
  //   CurYear: `${formatDate(yearStart)} ~ ${formatDate(yearEnd)}`,
  //   PrevYear: `${formatDate(prevYearStart)} ~ ${formatDate(prevYearEnd)}`,
  // });

  return {
    monthStart, monthEnd,
    prevMonthStart, prevMonthEnd,
    lyrMonthStart, lyrMonthEnd,
    qtrStart, qtrEnd,
    prevQtrStart, prevQtrEnd,
    lyrQtrStart, lyrQtrEnd,
    yearStart, yearEnd,
    prevYearStart, prevYearEnd,
  };
}

/**
 * @typedef {{
 *   title: string;
 *   subtitle: string;
 *   from: string;
 *   to: string;
 *   net: number;
 *   gst: number;
 *   roomTax: number;
 *   adjustment: number;
 *   gross: number;
 *   payment: number;
 *   paymentCredit: number;
 *   paymentDebit: number;
 *   paymentCash: number;
 *   paymentCheque: number;
 *   carryOver: number;
 *   balance: number;
 * }} ReportData
 */

/**
 * @param {object[]} salesData
 * @param {object[]} paymentData
 * @param {object[]} carryOverData
 * @param {string} title
 * @param {string} subtitle
 * @param {string | Date} from
 * @param {string | Date} to
 * @return {ReportData}
 */
function calculateReportData(employeeRateData, salesData, paymentData, carryOverData, title, subtitle, [from, to]) {
  function isEmployeeRate(checkInId) {
    return employeeRateData.find(({id, isEmployeeRate}) => id === checkInId && isEmployeeRate === true)?.isEmployeeRate;
  }
  from = from instanceof Date ? formatDate(from) : from;
  to = to instanceof Date ? formatDate(to) : to;
  const res = {
    title, subtitle,
    from, to,
    net: 0,
    gst: 0,
    roomTax: 0,
    gross: 0,
    adjustment: 0,
    payment: 0,
    paymentCredit: 0,
    paymentDebit: 0,
    paymentCash: 0,
    paymentCheque: 0,
    carryOver: carryOverData.find((item) => item.dateStr === from)?.carryOver ?? 0,
    balance: 0,
    employeeAmount: 0

  };
  for (const {dateStr, itemCode, net, gst, roomTax, gross, checkInId} of salesData) {
    if (isEmployeeRate(checkInId)) {
      if (itemsForAdjustment.includes(itemCode)) {
        res.employeeAmount += gross;
      }
      continue;
    }
    if (dateStr >= from && dateStr <= to) {
      res.net += net;
      res.gst += gst;
      res.roomTax += roomTax;
      res.gross += gross;
      if (itemsForAdjustment.includes(itemCode)) {
        res.adjustment += gross;
      }
    }
  }
  for (const {dateStr, itemCode, amount, checkInId} of paymentData) {
    if (isEmployeeRate(checkInId)) {
      continue;
    }
    if (dateStr >= from && dateStr <= to) {
      res.payment += amount;
      if (itemCode.endsWith('Credit')) {
        res.paymentCredit += amount;
      } else if (itemCode.endsWith('Debit')) {
        res.paymentDebit += amount;
      } else if (itemCode.endsWith('Cash')) {
        res.paymentCash += amount;
      } else if (itemCode.endsWith('Cheque')) {
        res.paymentCheque += amount;
      }
    }
  }
  res.balance = res.gross - res.payment + res.carryOver;
  return res;
}

function getTableHead(reportData, addLineBreak, onMap) {
  const tableHead = [addLineBreak === true ? `Category\nItems` : 'Category Items'];
  for (const {title, subtitle} of reportData) {
    tableHead.push(addLineBreak === true ? `${title}\n${subtitle}` : `${title} (${subtitle})`);
  }
  return onMap ? tableHead.map(onMap) : tableHead;
}

function getTableBody(reportData, onMapRow, arAmount) {
  const tableBody = [];
  const rowData = [
    ['Net Sales', ['net']],
    ['GST (5%)', ['gst']],
    ['Room Tax (8%)', ['roomTax']],
    ['Tax Total', ['gst', 'roomTax']],
    ['Gross Sales', ['gross']],
    ['Credit', ['paymentCredit']],
    ['Debit', ['paymentDebit']],
    ['Cash', ['paymentCash']],
    ['Cheque', ['paymentCheque']],
    ['Settlement Total', ['payment']],
    ['Carry Over', ['carryOver']],
    ['A.R.', []],
    ['Balance', ['balance']],
  ];
  for (const [colTitle, keys, negative = false] of rowData) {
    if (colTitle === 'A.R.') {
      const row = [colTitle];
      // for (const item of reportData) {
      //   row.push(formatCurrency(arAmount));
      // }
      reportData.forEach(() => row.push(formatCurrency(arAmount)));
      tableBody.push(onMapRow ? row.map(onMapRow) : row);
    } else {
      const row = [colTitle];
      for (const item of reportData) {
        const value = keys.reduce((p, key) => p += item[key], 0);
        row.push(formatCurrency(negative ? -value : value));
      }
      tableBody.push(onMapRow ? row.map(onMapRow) : row);
    }
  }
  return tableBody;
}

function exportAsCSV(user, reportData, arAmount) {
  const headers = getTableHead(reportData, false);
  const tableBody = getTableBody(reportData, undefined, arAmount);
  const filename = `${user.title}-daily-report-${format(Date.now(), 'yyyyMMddhhmmss')}.csv`;
  const doc = new ExportToCsv({filename, headers, showLabels: true});
  doc.generateCsv(tableBody);
}

function exportAsPDF(user, reportData, arAmount, reportDate) {
  const fontSize = 8.5;
  const cellWidth = 26;
  const headStyles = {halign: 'center', valign: 'middle', fontSize};
  const titleStyles = {halign: 'right', valign: 'middle', fontSize};
  const valueStyles = {halign: 'right', valign: 'middle', cellWidth, fontSize};
  const tableHead = getTableHead(reportData, true, (content) => ({content, styles: headStyles}));
  const tableBody = getTableBody(reportData, (content, index) => ({content, styles: index === 0 ? titleStyles : valueStyles}), arAmount);
  const doc = new jsPDF({orientation: 'l'});
  let startY = 20;
  let startX = 14;
  const pageCenterX = doc.internal.pageSize.getWidth() / 2;
  doc.setFontSize(15);
  doc.text(`${user.title} Report`, pageCenterX, startY, {align: 'center'});
  startY += 5.5;
  doc.setFontSize(12);
  doc.text(format(reportDate, 'LLLL, yyyy'), pageCenterX, startY, {align: 'center'});
  startY += 4;
  doc.setFontSize(10);
  doc.text(`Issued Date : ${format(Date.now(), 'yyyy-MM-dd hh:mm:ss')}`, startX, startY);
  startY += 4.5;
  doc.text(`Address: ${user.address}`, startX, startY);
  startY += 4.5;
  doc.text(`Phone: ${user.phoneNo}`, startX, startY);
  startY += 4.5;
  doc.text(`Website: ${user.website}`, startX, startY);
  startY += 4.5;
  const tableOptions = {
    startY,
    head: [tableHead],
    body: tableBody,
  };
  doc.autoTable(tableOptions);
  doc.save(`${user.title}-report-${format(reportDate, 'yyyyMM')}.pdf`);
}

export default MonthlyReport;
