import React from 'react';
import { Button, Stack } from '@mui/material';
import { postRoomType } from '../utils/utils';
import InputField from './InputField';
import useStoreId from '../hooks/useStoreId';
import useForm from '../hooks/useForm';
import * as yup from 'yup';

const roomTypeSchema = yup.object({
  id: yup.string().nullable(),
  value: yup.string().required('Please enter the room type.'),
  label: yup.string().required('Please enter the room type label.'),
  standardRate: yup.number().required('Please enter the price.').typeError('Please enter a number'),
  weeklyRate: yup.number().required('Please enter the price.').typeError('Please enter a number'),
  monthlyRate: yup.number().required('Please enter the price.').typeError('Please enter a number'),
  corpRate: yup.number().required('Please enter the price.').typeError('Please enter a number'),
});

const defRoomTypeData = {
  id: '',
  value: '',
  label: '',
  standardRate: '',
  weeklyRate: '',
  monthlyRate: '',
  corpRate: '',
};

const RoomTypeForm = ({ editData, editMode, onCloseDialog }) => {
  const storeId = useStoreId();
  const formik = useForm({
    editData: {...defRoomTypeData, ...editData},
    editMode,
    validationSchema: roomTypeSchema,
    onSubmit: async (values) => {
      const res = await postRoomType(storeId, values);
      onCloseDialog?.();
      return res;
    },
    message: (values) => `RoomType (${values.label}) has been saved successfully.`,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack direction="column" alignItems="flex-start" spacing={2} pt={2}>
        <InputField name="value" label="Type Code" formik={formik} disabled={editMode} />
        <InputField name="label" label="Type Label" formik={formik} />
        <InputField name="standardRate" label="Standard Rate" formik={formik} />
        <InputField name="weeklyRate" label="Weekly Rate" formik={formik} />
        <InputField name="monthlyRate" label="Monthly Rate" formik={formik} />
        <InputField name="corpRate" label="Corp. Rate" formik={formik} />
      </Stack>
      <Stack direction="row" spacing={2} mt={4} justifyContent="center">
        <Button type="submit" variant="contained" disabled={formik.isSubmitting}>
          {editMode ? 'EDIT' : 'ADD'} ROOM TYPE
        </Button>
        {onCloseDialog && (
          <Button variant="contained" color="error" disabled={formik.isSubmitting} onClick={onCloseDialog}>
            CLOSE
          </Button>
        )}
      </Stack>
    </form>
  );
};

export default RoomTypeForm;
