import React from 'react';
import RoomTypeCRUDTable from '../../data-tables/RoomTypeCRUDTable';
import PageContainer from '../../components/PageContainer';

export default function RoomTypeList() {
  return (
    <PageContainer title="Room Type List">
      <RoomTypeCRUDTable />
    </PageContainer>
  );
}
