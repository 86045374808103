import React from 'react';
import {Button, IconButton, Stack, Tab, Tabs, Typography} from '@mui/material';
import useInvoiceItemTypeList, {useInvoiceItems} from '../hooks/useInvoiceItemTypeList';
import {CheckInCustomerForm, useCheckInCustomerForm} from './CustomerForm';
import {CheckInCompanyForm, useCheckInCompanyForm} from './CompanyForm';
import StayAndRoomForm, {useStayAndRoomForm} from './StayAndRoomForm';
import {
  deleteDailyRoomSalesBatch,
  devLog,
  fetchCheckInData,
  getBalance,
  getBatch,
  getDocId,
  getInvoiceNo,
  setCheckInDocBatch,
  setDailyRoomSalesBatch,
  setInvoiceItem,
  setStoreDoc,
  setStoreDocBatch,
  updateHousekeepingStatus,
  fetchBookingData,
} from '../utils/utils';
import useStoreId from '../hooks/useStoreId';
import useAsyncStatus from '../hooks/useAsyncStatus';
import TransactionListTable from '../data-tables/TransactionListTable';
import {Icon} from '@iconify/react';
import minusOutline from '@iconify/icons-ant-design/minus-circle-outline';
import useTaxList from '../hooks/useTaxList';
import Confirm, {useConfirm} from '../components/Confirm';
import {CopyButton, NextButton, PrevButton} from '../components/icon-buttons';
import cws from '../utils/cws-client';
import {Backdrop, useBackdrop} from '../hooks/useBackdrop';
import useAuth from '../hooks/useAuth';
import {checkDoubleBooking} from './BookingForm';
import Alert, { useAlert } from '../components/Alert';
import {useAllBookingsWithRoomNoExceptBookingId} from '../hooks/useBookingList';
import {useCheckInListExceptCheckInId} from '../hooks/useCheckInList';

const defCheckInData = {
  id: '',
  checkInId: '',
  customerId: '',
  companyId: '',
  company: '',
  name: '',
  email: '',
  phoneNo: '',
  sex: '',
  dob: '',
  city: '',
  adults: '',
  checkIn: '',
  checkOut: '',
  checkedOutAt: '',
  paidOffAt: '',
  children: '',
  infants: '',
  licenseNo: '',
  passportNo: '',
  otherIdNo: '',
  plateNo: '',
  carInfo: '',
  carColor: '',
  note: '',
  roomNo: '',
  roomType: '',
  roomRate: '',
  roomRateOrg: '',
  roomRateDaily: '',
  totalDays: '',
  rateType: '',
  addPetFee: '',
  addLaundryFee: '',
  addRVFee: '',
  noTax: '',
  paymentType: '',
  cardNo: '',
  cardHolder: '',
  cardExp: '',
  cardCvv: '',
  paymentStatus: 'pending',
  amountPaid: '',
  paymentNote: '',
  source: 'on-site',
  status: 'checked-in',
  isEmployeeRate: false,
  rvRoomNo: '',
};

const TAB_CUSTOMER = `CUSTOMER`;
const TAB_COMPANY = `COMPANY`;
const TAB_STAY_ROOM = `STAY & ROOM`;
const TAB_TRANSACTIONS = `TRANSACTIONS`;

let prevCustomerData = {};

const CheckInForm = ({ editData: initialData, editMode: initialMode, onCloseDialog, readOnly, isBilling }) => {
  // const snackbar = useSnackbar();
  const {user} = useAuth();
  const backdrop = useBackdrop();
  const {
    checkInState, setCheckInState,
    // curCheckInCount, setCurCheckInCount,
    curCheckInIndex, setCurCheckInIndex,
    isCurCheckInIndexDone,
    curGroupCheckInFlags, setCurGroupCheckInFlags,
    invoiceItemTypes, invoiceItems, /*taxList,*/ storeId,
    saving, setSaving,
    execWithFeedback,
  } = useCheckInForm(initialData, initialMode);
  const {editData, editMode, isGroup, bookingId, roomCount, groupCheckInFlags, depositAmount, depositType} = checkInState;
  const {roomType, roomNo, rateType} = getCurrentRoomInfoIfGroupBooking(initialData, initialMode, curCheckInIndex);
  const editDataWithRoomInfoIfGroupBooking = {...editData};
  roomType && (editDataWithRoomInfoIfGroupBooking.roomType = roomType);
  roomNo && (editDataWithRoomInfoIfGroupBooking.roomNo = roomNo);
  rateType && (editDataWithRoomInfoIfGroupBooking.rateType = rateType);
  const useTabs = useCheckInTabs(editDataWithRoomInfoIfGroupBooking, editMode, editData.id);
  const {confirmProps, openConfirm} = useConfirm();
  const [bookingsWithRoomNo] = useAllBookingsWithRoomNoExceptBookingId(bookingId);
  const [checkIns = []] = useCheckInListExceptCheckInId(getDocId(editData, 'checkInId'));
  const {alertProps, openAlert} = useAlert();
  const {values: {checkIn: from, checkOut: to, roomNo: roomNumber, id, isEmployeeRate}} =  useTabs.stayAndRoomFormik;
  async function saveCheckIn() {
    try {
      setSaving(true);
      const docId = editData.id;
      const data = await useTabs.getCheckInData();
      if (editMode && !docId) {
        devLog('saveCheckIn', {editData, editMode, docId, data});
        // 체크인을 수정하는 경우 반드시 문서아이디가 있어야 함!
        throw new Error(`Something went wrong! please contact admins to fix this error.`);
      }
      if (editMode) {
        // 편집 모드인 경우 아래의 두 필드값을 유지해 주어야함!!!
        const {status, paymentStatus} = editData;
        data['status'] = status;
        data['paymentStatus'] = paymentStatus;
      }
      // checking double booking
      const roomCnt = roomCount || 1;
      for(let i=0; i<roomCnt; i++) {
        const res = checkDoubleBooking(openAlert, [new Date(from), new Date(to)], checkIns, bookingsWithRoomNo, roomNumber);
        if (!res) {
          throw new Error(`Cannot add / edit!`);
        }
      }
      if (!editMode) {
        // 새로 데이터를 생성하는 경우 인보이스 번호를 새로 부여함
        data['invoiceNo'] = await getInvoiceNo(storeId);
      }
      data['isEmployeeRate'] = isEmployeeRate;
      const batch = getBatch();
      const savedCheckInId = setCheckInDocBatch(batch, storeId, data, docId, invoiceItemTypes, invoiceItems, depositAmount, depositType);
      await deleteDailyRoomSalesBatch(batch, storeId, savedCheckInId);
      setDailyRoomSalesBatch(batch, storeId, savedCheckInId, data);
      let newGroupCheckInFlags;
      let curCount;
      if (isGroup) {
        newGroupCheckInFlags = {...curGroupCheckInFlags, [curCheckInIndex]: true};
        curCount = getGroupCheckInDoneCount(newGroupCheckInFlags);
        const newIndex = getFirstGroupCheckInIndex({groupCheckInFlags: newGroupCheckInFlags});
        setCurCheckInIndex(newIndex);
        const bookingData = {
          countCheckedIn: curCount,
          groupCheckInFlags: newGroupCheckInFlags,
        };
        if (curCount >= roomCount) {
          bookingData['status'] = 'done';
        } else {
          useTabs.customerFormik.resetForm();
          useTabs.stayAndRoomFormik.resetForm();
          const {name, email, phoneNo} = data;
          await useTabs.customerFormik.setValues({name, email, phoneNo});
        }
        setStoreDocBatch(batch, storeId, 'bookings', bookingData, bookingId);
      } else {
        if (bookingId) {
          setStoreDocBatch(batch, storeId, 'bookings', {status: 'done'}, bookingId);
        }
      }
      await batch.commit();
      if (!isGroup) {
        const checkInEditData = await fetchCheckInData(storeId, savedCheckInId);
        setCheckInState({...checkInState, editData: checkInEditData, editMode: true});
        useTabs.setTab(TAB_TRANSACTIONS);
      } else {
        setCurGroupCheckInFlags(newGroupCheckInFlags);
        if (curCount >= roomCount) {
          onCloseDialog?.();
        } else {
          useTabs.setTab(TAB_CUSTOMER);
        }
      }
      if (isGroup) {
        onDeleteBooking(bookingId);
      }
    } catch (error) {
      throw error;
    } finally {
      setSaving(false);
    }
  }
  // 그룹 부킹에서 체크인 시 해당 부킹 삭제
  async function onDeleteBooking(groupId) {
    const bookingData = await fetchBookingData(storeId, groupId);
    const {groupRooms, roomCount} = bookingData;
    let resGroupRooms = [];
    for (let i=0; i<roomCount; i++) {
      if (i !== parseInt(curCheckInIndex)) {
        resGroupRooms.push(groupRooms?.[i]);
      }
    }
    let newGrouprooms = {};
    for (let i=0; i<resGroupRooms.length; i++) {
      newGrouprooms = {...newGrouprooms, [i]: resGroupRooms[i]};
    }
    const groupCheckInFlags = {};
    for (const key of Object.keys(newGrouprooms)) {
      groupCheckInFlags[key] = false;
    }
    bookingData.groupCheckInFlags = groupCheckInFlags;
    bookingData.roomCount = (roomCount - 1).toString();
    bookingData.groupRooms = newGrouprooms;
    setStoreDoc(storeId, 'bookings', bookingData, groupId);
  }
  async function processCheckOut(isPayOff) {
    try {
      setSaving(true);
      const docId = getDocId(editData, 'checkInId');
      if (!docId) {
        throw new Error(`Something went wrong! please contact admins to fix this error.`);
      }
      const {balance, salesTotal, paymentTotal} = getBalance(invoiceItems);
      devLog('processCheckOut', {balance, salesTotal, paymentTotal});
      const data = {
        status: 'checked-out',
        paymentStatus: balance <= 0 ? 'paid' : 'unpaid',
      };
      if (isPayOff) {
        data['paidOffAt'] = Date.now();
      } else {
        data['checkedOutAt'] = Date.now();
      }
      await setStoreDoc(storeId, 'check-ins', data, docId);
      if (!isPayOff) {
        // 체크아웃인 경우 자동으로 하우스 키핑으로 상태를 변경함!
        await updateHousekeepingStatus(storeId, editData.roomNo, 'H');
      }
      onCloseDialog?.();
    } catch (error) {
      throw error;
    } finally {
      setSaving(false);
    }
  }
  async function processElavonPayment() {
    const checkInId = getDocId(editData, 'checkInId');
    if (!checkInId) {
      throw new Error(`Something went wrong! please contact admins to fix this error.`);
    }
    try {
      backdrop.open();
      const {invoiceNo} = editData;
      const {balance, salesTotal, paymentTotal} = getBalance(invoiceItems);
      let result, errors, cardType, cardScheme, authCode, maskedPan, firstName, lastName, paymentId;
      if (paymentTotal === 0) {
        [result, errors, cardType, cardScheme, authCode, maskedPan, firstName, lastName, paymentId] = await cws.startPayment(checkInId, invoiceNo, salesTotal.gross, salesTotal.gst + salesTotal.roomTax);
      } else {
        [result, errors, cardType, cardScheme, authCode, maskedPan, firstName, lastName, paymentId] = await cws.startPayment(checkInId, invoiceNo, balance, 0);
      }
      if (result === 'APPROVED') {
        await setInvoiceItem(storeId, checkInId, {
          itemId: '',
          itemCode: cardType === 'CREDIT' ? 'paymentCredit' : 'paymentDebit',
          itemTitle: cardType === 'CREDIT' ? 'Payment (Credit)' : 'Payment (Debit)',
          amount: -balance,
          note: `${cardScheme} ${maskedPan} (${firstName} ${lastName}, ${authCode})`,
        });
      } else {
        throw new Error(`${result}: ${errors.join(', ')}`);
      }
      // 여기서 매이먼트 정보를 자동으로 입력해줌!
      // 그리고 난 후에 체크아웃 프로세스를 진행함!
      // await processCheckOut(isPayOff);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      backdrop.close();
    }
  }
  function onCopyCustomerInfo() {
    useTabs.customerFormik.setValues({...useTabs.customerFormik.values, ...prevCustomerData});
  }
  function renderGroupCheckInInfo() {
    return (
      <Stack direction={'row'} spacing={2} alignItems={'center'}>
        <PrevButton onClick={() => {
          if (curCheckInIndex > 0) {
            setCurCheckInIndex(curCheckInIndex - 1);
          }
        }} />
        <Typography variant={'overline'} sx={{my: 2, color: 'text.disabled'}}>{`GROUP CHECK-IN (${curCheckInIndex + 1} / ${roomCount}) - #${roomNo} (${roomType})`}</Typography>
        <NextButton onClick={() => {
          if (curCheckInIndex < roomCount - 1) {
            setCurCheckInIndex(curCheckInIndex + 1);
          }
        }} />
      </Stack>
    );
  }
  if (isGroup && isCurCheckInIndexDone) {
    return (
      <Stack direction={'column'} spacing={2}>
        {renderGroupCheckInInfo()}
        <Typography sx={{minHeight: 250}}>Room #{roomNo} ({roomType}) has been checked in.</Typography>
        <Stack direction={'row'} spacing={2}>
          {onCloseDialog && (
            <Button variant="contained" color="error" disabled={saving} onClick={onCloseDialog}>
              CLOSE
            </Button>
          )}
        </Stack>
      </Stack>
    );
  }
  return (
    <Stack direction={'column'}>
      {isGroup && renderGroupCheckInInfo()}
      <Tabs {...useTabs.tabsProps}>
        {useTabs.tabs.map((tab) => <Tab key={tab} label={tab} value={tab} />)}
      </Tabs>
      <Alert {...alertProps} />
      <CheckInCustomerForm formik={useTabs.customerFormik} hide={useTabs.tab !== TAB_CUSTOMER} />
      <CheckInCompanyForm formik={useTabs.companyFormik} hide={useTabs.tab !== TAB_COMPANY} />
      <StayAndRoomForm formik={useTabs.stayAndRoomFormik} hide={useTabs.tab !== TAB_STAY_ROOM} />
      <Stack direction="column" spacing={3} pt={2} minHeight={400} display={useTabs.tab === TAB_TRANSACTIONS ? 'flex' : 'none'}>
        <TransactionListTable checkInId={editData.id} readOnly={readOnly} isBilling={isBilling} />
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center" pt={4}>
        {/*{(!readOnly || isBilling) && useTabs.tab === TAB_TRANSACTIONS && (*/}
        {/*  <Button variant="contained" color="primary" disabled={saving} onClick={() => {*/}
        {/*    openConfirm('Are you sure you want to start payment? (credit or debit only)', () => processElavonPayment(), 'Payment has been approved!');*/}
        {/*  }} startIcon={<CreditCardIcon />}>*/}
        {/*    CREDIT/DEBIT PAYMENT*/}
        {/*  </Button>*/}
        {/*)}*/}
        {!readOnly && useTabs.tab === TAB_TRANSACTIONS && (
          <Button variant="contained" color="primary" disabled={saving} onClick={() => {
            openConfirm('Are you sure you want to check out?', () => processCheckOut(false), 'Check-out has been processed successfully!');
          }} startIcon={<Icon icon={minusOutline} />}>
            CHECK-OUT
          </Button>
        )}
        {isBilling && useTabs.tab === TAB_TRANSACTIONS && (
          <Button variant="contained" color="primary" disabled={saving} onClick={() => {
            openConfirm('Are you sure you want to pay off?', () => processCheckOut(true), 'Pay off has been processed successfully!');
          }} startIcon={<Icon icon={minusOutline} />}>
            PAY OFF
          </Button>
        )}
        {!readOnly && useTabs.tab === TAB_CUSTOMER && (
          <Button variant="contained" color="primary" disabled={saving} onClick={() => useTabs.setTab(TAB_STAY_ROOM)}>
            NEXT TAB
          </Button>
        )}
        {!readOnly && useTabs.tab === TAB_COMPANY && (
          <Button variant="contained" color="primary" disabled={saving} onClick={() => useTabs.setTab(TAB_STAY_ROOM)}>
            NEXT TAB
          </Button>
        )}
        {(!readOnly || user.role === 'super') && useTabs.tab === TAB_STAY_ROOM && (
          <Button variant="contained" color="primary" disabled={saving} onClick={() => {
            return execWithFeedback(() => saveCheckIn(), 'Check-in data has been saved successfully!');
          }}>
            {`${editMode ? 'EDIT' : 'ADD'} CHECK-IN`}
          </Button>
        )}
        {onCloseDialog && (
          <Button variant="contained" color="error" disabled={saving} onClick={onCloseDialog}>
            CLOSE
          </Button>
        )}
        {!editMode && <CopyButton onClick={onCopyCustomerInfo} />}
      </Stack>
      <Confirm {...confirmProps} />
      <Backdrop {...backdrop.props} />
    </Stack>
  );
};

const tabs = [TAB_CUSTOMER, TAB_COMPANY, TAB_STAY_ROOM, TAB_TRANSACTIONS];

function getCurrentRoomInfoIfGroupBooking(initialData, initialMode, curCheckInIndex) {
  if (initialMode === true) {
    return {};
  }
  const {bookingId, bookingType, groupRooms} = initialData ?? {};
  if (bookingId && bookingType !== 'group') {
    return {};
  }
  const [roomType, roomNo] = groupRooms?.[curCheckInIndex] ?? [];
  return {roomType, roomNo, rateType: 'Standard'};
}

function getInitialCheckInState(initialData, initialMode) {
  let editData;
  const {
    bookingId, bookingType, queenCount, singleCount, roomCount = 0, groupRooms, groupCheckInFlags, bedType, note, countCheckedIn = 0,
    paymentType: depositType, amountPaid: depositAmount,
    name: nameParam = '', email: emailParam = '', phoneNo = '',
    adults = '', children = '', infants = '',
    ...realEditData
  } = initialData ?? {};
  const isGroup = !initialMode && bookingType === 'group';
  if (isGroup) {
    editData = {...defCheckInData, name: nameParam, email: emailParam, phoneNo, ...realEditData};
  } else {
    editData = {...defCheckInData, name: nameParam, email: emailParam, phoneNo, adults, children, infants, note, ...realEditData};
  }
  return {editData, editMode: initialMode, bookingId, isGroup, roomCount, groupRooms, groupCheckInFlags, countCheckedIn, depositType, depositAmount};
}

// 그룹 부킹에서 체크인이 되지 않은 첫번째 인덱스를 반환함
function getFirstGroupCheckInIndex({groupCheckInFlags}) {
  let curIndex = 0;
  for (const [index, flag] of Object.entries(groupCheckInFlags ?? {})) {
    if (!flag) {
      curIndex = parseInt(index);
      break;
    }
  }
  return curIndex;
}

function getGroupCheckInDoneCount(groupCheckInFlags) {
  let count = 0;
  for (const [, flag] of Object.entries(groupCheckInFlags ?? {})) {
    if (flag) {
      ++count;
    }
  }
  return count;
}

function useCheckInForm(initialData, initialMode) {
  const [checkInState, setCheckInState] = React.useState(getInitialCheckInState(initialData, initialMode));
  const [saving, setSaving] = React.useState(false);
  const [curCheckInIndex, setCurCheckInIndex] = React.useState(getFirstGroupCheckInIndex(checkInState));
  const [curGroupCheckInFlags, setCurGroupCheckInFlags] = React.useState(checkInState.groupCheckInFlags ?? {});
  const [invoiceItemTypes] = useInvoiceItemTypeList();
  const [invoiceItems] = useInvoiceItems(checkInState.editData.id);
  const [taxList] = useTaxList();
  const storeId = useStoreId();
  const execWithFeedback = useAsyncStatus();
  return {
    checkInState, setCheckInState,
    curCheckInIndex, setCurCheckInIndex,
    isCurCheckInIndexDone: curGroupCheckInFlags[curCheckInIndex] ?? false, // 그룹부킹에서 현재 순번의 체크인인 완료되었는지 여부!
    curGroupCheckInFlags, setCurGroupCheckInFlags,
    invoiceItemTypes, invoiceItems, taxList, storeId,
    saving, setSaving,
    execWithFeedback,
  };
}

function useCheckInTabs(editData, editMode, checkInId) {
  const [tab, setTab] = React.useState(editMode ? tabs[tabs.length - 1] : tabs[0]);
  const {formik: customerFormik, getData: getCustomerData} = useCheckInCustomerForm(editData, editMode);
  const {formik: companyFormik, getData: getCompanyData} = useCheckInCompanyForm(editData);
  const {formik: stayAndRoomFormik, getData: getStayAndRoomData} = useStayAndRoomForm(editData);
  return {
    tabsProps: {
      value: tab,
      onChange: (e, value) => {
        switch (tab) {
          case TAB_CUSTOMER:
            getCustomerData().then((data) => { if (data) setTab(value) });
            break;
          case TAB_COMPANY:
            getCompanyData().then((data) => { if (data) setTab(value) });
            break;
          case TAB_STAY_ROOM:
            getStayAndRoomData().then((data) => { if (data) setTab(value) });
            break;
          default:
            setTab(value);
            break;
        }
      },
    },
    tabs: tabs.filter(i => (editMode || checkInId) ? true : i !== TAB_TRANSACTIONS),
    tab, setTab,
    customerFormik,
    companyFormik,
    stayAndRoomFormik,
    getCheckInData: async () => {
      const customerData = await getCustomerData();
      const companyData = await getCompanyData();
      const stayAndRoomData = await getStayAndRoomData();
      if (!(customerData && companyData && stayAndRoomData)) {
        throw new Error();
      }
      const {id: customerId, ...cData} = customerData;
      const {id: companyId, company} = companyData;
      const data = {
        ...defCheckInData,
        customerId,
        ...cData,
        companyId,
        company,
        companyData,
        ...stayAndRoomData,
      };
      devLog('CheckInForm.getCheckInData()', {data, companyData, customerData, stayAndRoomData});
      prevCustomerData = {...customerData};
      return data;
    },
  };
}

export default CheckInForm;
