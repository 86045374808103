import React from 'react';
import { Button, Stack } from '@mui/material';
import FirestoreCRUDTable from './FirestoreCRUDTable';
import BookingForm from '../forms/BookingForm';
import { formatBookingInfo, formatDateRange, formatDateTime, renderBooker } from '../utils/utils';
import Confirm, { useConfirm } from '../components/Confirm';
import FormDialog, { useFormDialog } from '../forms/FormDialog';
import CheckInForm from '../forms/CheckInForm';
import { CheckInButton, DeleteButton } from '../components/icon-buttons';
import useAuth from '../hooks/useAuth';
import { StandaloneInputField } from '../forms/InputField';
import fire from '../utils/fire-v8';
import Label from '../components/Label';

const BookingCRUDTable = () => {
  const [search, setSearch] = React.useState('');
  const {user} = useAuth();
  const {confirmProps, openConfirm} = useConfirm();
  const {props: checkInFormProps, open: openCheckInFormDialog} = useFormDialog();
  const {props: groupBookingFormProps, open: openGroupBookingFormDialog} = useFormDialog();
  const query = fire.bookingQuery(search, false, 'pending');
  function onCheckIn(row) {
    const {id: bookingId} = row;
    // 부킹 정보를 체크인 정보에 넘겨줌!
    const checkInData = {
      ...row,
      id: '',
      status: 'checked-in',
      bookingId
    };
    openCheckInFormDialog(checkInData, false);
  }
  function onCancel(row) {
    const docRef = fire.storeDoc('bookings', row.id);
    const data = {status: 'cancelled'};
    openConfirm(
      'Are you sure you want to cancel this booking?',
      () => fire.update(docRef, data),
      'Booking has been cancelled!'
    );
  }
  function getColumns() {
    const columns = [
      { field: 'source', headerName: 'Source', flex: 0, width: 100, minWidth: 100, align: 'center', headerAlign: 'center', renderCell: renderSource },
      { field: 'bookingType', headerName: 'Type', flex: 0, width: 100, minWidth: 100, align: 'center', headerAlign: 'center', renderCell: renderType },
      { field: 'name', headerName: 'Booker', renderCell: renderBooker },
      { field: 'checkIn', headerName: 'Check In / Check Out', flex: 2, valueGetter: ({value, row}) => formatDateRange(value, row.checkOut) },
      { field: 'guests', headerName: 'Info', valueGetter: ({row}) => formatBookingInfo(row) },
      { field: 'createdAt', headerName: 'Booked At', valueGetter: ({value}) => formatDateTime(value) },
    ];
    const idColumn = { field: 'id', headerName: 'ID' };
    return (user.role === 'super' && user.storeId !== 'demo') ? [idColumn, ...columns] : columns;
  }
  // async function repair() {
  //   const roomTypes = (await storeColRef(user.storeId, 'room-types').get()).docs.map(doc => doc.data());
  //   const snapshot = await fire.simpleBookingQuery('pending').get();
  //   for (const doc of snapshot.docs) {
  //     const bookingId = doc.id;
  //     const bookingData = doc.data();
  //     const {checkIn, checkOut, roomType, rateType, adults, applyCorpRate, noTax} = bookingData;
  //     const roomTypeData = roomTypes.find(i => i.value === roomType);
  //     const isCorp = rateType === RATE_CORPORATE;
  //     const {roomRateInfo} = getRoomRateInfo(checkIn, checkOut, roomTypeData, isCorp, adults, applyCorpRate, noTax);
  //     console.log({bookingId, bookingData, params: {checkIn, checkOut, roomType, rateType, adults, applyCorpRate, noTax, roomTypeData, isCorp}, roomRateInfo});
  //     await doc.ref.update({roomRateInfo});
  //   }
  // }
  return (
    <FirestoreCRUDTable
      query={query}
      columns={getColumns()}
      title="BOOKING"
      Form={BookingForm}
      renderLeftHeader={() => {
        return (
          <Stack direction={'row'} spacing={2}>
            <StandaloneInputField
              label={'Search...'}
              value={search}
              onChange={({target: {value}}) => setSearch(value)}
            />
            {/*<StandaloneInputField*/}
            {/*  value={todayOnly}*/}
            {/*  onChange={({target: {checked: value}}) => setTodayOnly(value)}*/}
            {/*  checkbox*/}
            {/*  label={'Today Only'}*/}
            {/*/>*/}
            {/*{user.role === 'super' && <Button onClick={repair}>REPAIR</Button>}*/}
          </Stack>
        );
      }}
      renderRightHeader={() => {
        return (
          <Button
            onClick={() => {
              openGroupBookingFormDialog(undefined, false, {isGroupBooking: true});
            }}
            variant={'contained'}
          >
            ADD GROUP BOOKING
          </Button>
        );
      }}
      hideDeleteButton
      // hideEditButton
      formDialogMaxWidth={'md'}
      renderActionButtons={(row) => {
        return [
          <DeleteButton key={'cancel'} onClick={() => onCancel(row)} />,
          <CheckInButton key={'checkIn'} onClick={() => onCheckIn(row)} />,
          // <Button key={'checkIn'} size="small" onClick={() => onCheckIn(row)}>CHECK-IN</Button>,
        ];
      }}
      renderFooter={() => {
        return (
          <>
            <Confirm {...confirmProps} />
            <FormDialog {...checkInFormProps} maxWidth={'md'} title={'CHECK-IN'} Form={CheckInForm} />
            <FormDialog {...groupBookingFormProps} maxWidth={'md'} title={'GROUP BOOKING'} Form={BookingForm} />
          </>
        );
      }}
      placeActionColumnFirst
      actionColDef={{minWidth: 160}}
    />
  );
};

function renderSource({value}) {
  return <Label color={'success'}>{value}</Label>;
}

function renderType({value}) {
  if (value === 'group') {
    return <Label color={'info'}>{value}</Label>;
  }
  return <Label color={'warning'}>{value}</Label>;
}

export default BookingCRUDTable;
