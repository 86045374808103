import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
import { styled } from '@mui/material/styles';
import { Card, Stack, Typography } from '@mui/material';
import { fCurrency } from '../../../utils/formatNumber';
import { BaseOptionChart } from '../charts';
//

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  padding: theme.spacing(3),
  color: theme.palette.mode === 'dark' ? theme.palette.success.lighter : theme.palette.success.darker,
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.success.darker : theme.palette.success.lighter,
}));

// ----------------------------------------------------------------------

// const TOTAL = 18765;
// const PERCENT = 2.6;
// const CHART_DATA = [{ data: [111, 136, 76, 108, 74, 54, 57, 84] }];

export default function BookingTotalIncomes({totalAmount, range, lastAmount, chartData}) {
  const chartOptions = merge(BaseOptionChart(), {
    chart: { sparkline: { enabled: true } },
    xaxis: { labels: { show: false } },
    yaxis: { labels: { show: false } },
    stroke: { width: 4 },
    legend: { show: false },
    grid: { show: false },
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (seriesName) => fCurrency(seriesName),
        title: {
          formatter: () => '',
        },
      },
    },
    fill: { gradient: { opacityFrom: 0, opacityTo: 0 } },
  });

  return (
    <RootStyle>
      <Stack direction="row" justifyContent="space-between" sx={{ mb: 3 }}>
        <div>
          <Typography sx={{ mb: 2, typography: 'subtitle2' }}>SALES <small>({range})</small></Typography>
          <Typography sx={{ typography: 'h3' }}>{fCurrency(totalAmount)}</Typography>
        </div>
        <div>
          <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ mb: 0.6 }}>
            {/*<Icon width={20} height={20} icon={percent >= 0 ? trendingUpFill : trendingDownFill} />*/}
            <Typography variant="subtitle2" component="span" sx={{ ml: 0.5 }}>
              {/*{percent > 0 && '+'}*/}
              {fCurrency(lastAmount)}
            </Typography>
          </Stack>
          <Typography variant="body2" component="span" sx={{ opacity: 0.72 }}>
            &nbsp;last month
          </Typography>
        </div>
      </Stack>
      <ReactApexChart type="area" series={chartData} options={chartOptions} height={132} />
    </RootStyle>
  );
}
