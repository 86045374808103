import React from 'react';
import {Box, Stack, Typography} from '@mui/material';
import * as yup from 'yup';
import InputField from './InputField';
import {devLog, getData, getFormikValues} from '../utils/utils';
import ClearFieldsButton from './ClearFieldsButton';
import useForm from '../hooks/useForm';
import {DateRange, useDateRange} from '../components/DateRange';
import useRoomTypeList from '../hooks/useRoomTypeList';
import useRoomList from '../hooks/useRoomList';
import useCheckInList from '../hooks/useCheckInList';
import useRoomRateCalculator from '../hooks/useRoomRateCalculator';
import {rateTypeOptions} from '../utils/options';
import {useRoomOptions, useRVRoomOptions, useVacantRoomNos} from './BookingForm';
import {useAllBookingsWithRoomNo} from '../hooks/useBookingList';
import useRVRoomList from '../hooks/useRVRoomList';
import useVacantRoomList from '../hooks/useVacantRoomList';

const stayAndRoomSchema = yup.object().shape({
  checkIn: yup.number().required('Please enter the check-in date.'),
  checkOut: yup.number().required('Please enter the check-out date.'),
  adults: yup.number().integer().positive().required('Please enter the number of adults.'),
  roomNo: yup.string().required(),
  roomRate: yup.number().required(),
});

const defStayAndRoomData = {
  checkIn: '',
  checkOut: '',
  adults: '',
  children: '',
  infants: '',
  roomNo: '',
  roomType: '',
  roomRate: '',
  roomRateOrg: '',
  roomRateDaily: '',
  totalDays: '',
  rateType: '',
  addPetFee: '',
  addLaundryFee: '',
  addRVFee: '',
  isEmployeeRate: '',
  noTax: '',
  applyCorpRate: '',
  roomRateInfo: '',
  rvRoomNo: '',
};

const stayAndRoomKeys = Object.keys(defStayAndRoomData);
const displayedStatusList = ['H', 'M', 'B'];

const StayAndRoomForm = ({formik, minHeight = 400, hide = false}) => {
  const {checkIn, checkOut, roomType, addRVFee, rvRoomNo} = formik.values;
  const dateRangeProps = useDateRange(
    [checkIn ? new Date(checkIn) : null, checkOut ? new Date(checkOut) : null],
    ([from, to]) => formik.setValues({...formik.values, checkIn: from?.getTime() ?? null, checkOut: to?.getTime() ?? null})
  );
  const [roomTypes] = useRoomTypeList();
  const [rooms = []] = useRoomList();
  const [rvRooms = []] = useRVRoomList();
  const [vacantRooms = []] = useVacantRoomList();
  const [checkIns = []] = useCheckInList();
  const [bookingsWithRoomNo] = useAllBookingsWithRoomNo();
  // const [housekeepingList = []] = useHouseKeepingList();
  // const occupiedRooms = getOccupiedRooms(checkIns);
  // const roomOptions = rooms.filter(r => r.roomType === roomType).map(({roomNo}) => {
  //   if (occupiedRooms.includes(roomNo)) {
  //     return {value: roomNo, label: `${roomNo} (Occupied)`, disabled: true};
  //   }
  //   const {statusLabel, statusCode} = housekeepingList.find((i) => i.roomNo === roomNo) ?? {};
  //   if (displayedStatusList.includes(statusCode)) {
  //     return {value: roomNo, label: `${roomNo} (${statusLabel})`};
  //   }
  //   return {value: roomNo, label: roomNo};
  // });
  const roomOptions = useRoomOptions(dateRangeProps.dateRange, roomType, rooms, bookingsWithRoomNo, checkIns);
  const vacnatRoomNos = useVacantRoomNos(vacantRooms);
  const rvRoomOptions = useRVRoomOptions(rvRooms, vacnatRoomNos);
  const [calcHint, showApplyCorpRate] = useRoomRateCalculator(false, dateRangeProps, formik, roomTypes);

  React.useEffect(() => {
    if (roomType === 'RV') {
      formik.setValues({
        ...formik.values,
        rvRoomNo: '',
      });
    }
  }, [roomType]);

  return (
    <Stack direction="column" spacing={3} pt={2} minHeight={minHeight} display={hide ? 'none' : 'flex'}>
      <DateRange labels={['Check In', 'Check Out']} size="small" width={250} showClearButton {...dateRangeProps} />
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="flex-start">
        <InputField name="adults" label="Adults" formik={formik} />
        <InputField name="children" label="Children" formik={formik} />
        <InputField name="infants" label="Infants" formik={formik} />
        <ClearFieldsButton formik={formik} fields={['adults', 'children', 'infants']} />
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="flex-start">
        <InputField name="roomType" label="Room Type" formik={formik} width={'250px'} options={roomTypes} />
        <InputField name="roomNo" label="Room No." formik={formik} width={'150px'} options={roomType === 'RV' ? rvRoomOptions : roomOptions} />
        <InputField name="rateType" label="Rate Type" formik={formik} width={'150px'} options={rateTypeOptions} />
        <InputField name="noTax" label="No Tax" formik={formik} checkbox />
        {showApplyCorpRate && <InputField name="applyCorpRate" label="Corp. Rate" formik={formik} checkbox />}
        <ClearFieldsButton formik={formik} fields={['roomType', 'roomNo', 'rateType', 'roomRate']} />
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="flex-start">
        <InputField name="roomRate" label="Total Rate" formik={formik} width={'150px'} />
        <InputField name="roomRateDaily" label="Daily Rate" formik={formik} width={'150px'} />
        <InputField name="isEmployeeRate" label="Employee rate" formik={formik} checkbox />
        <InputField name="addPetFee" label="Pet" formik={formik} checkbox />
        {roomType !== 'RV' && <InputField name="addRVFee" label="RV" formik={formik} checkbox />}
        {roomType !== 'RV' && addRVFee && <InputField name="rvRoomNo" label="RV Room No" formik={formik} width={'150px'} options={rvRoomOptions} />}
        <ClearFieldsButton formik={formik} fields={['roomType', 'roomNo', 'rateType', 'roomRate']} />
      </Stack>
      <Box>
        <Typography variant={'body2'} color={'warning.main'} sx={{ml: 1}}>{calcHint}</Typography>
      </Box>
    </Stack>
  );
};

function getStayAndRoomData(values) {
  return getData(values, stayAndRoomKeys);
}

export function useStayAndRoomForm(editData) {
  // console.log('hello', editData);
  const formik = useForm({
    editData: {...defStayAndRoomData, ...getStayAndRoomData(editData)},
    editMode: false,
    validationSchema: stayAndRoomSchema,
    onSubmit: async (values) => {
      const stayAndRoomData = getStayAndRoomData(values);
      devLog('StayAndRoomForm.onSubmit()', stayAndRoomData);
    },
    message: 'Stay & room information has been saved successfully.',
  });
  return {
    formik,
    getData: () => getFormikValues(formik, defStayAndRoomData, false),
  };
}

export default StayAndRoomForm;
