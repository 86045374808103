import React from 'react';
import PageContainer from '../../components/PageContainer';
import InvoiceItemCRUDTable from '../../data-tables/InvoiceItemCRUDTable';

export default function InvoiceItems() {
  return (
    <PageContainer title="Invoice Items">
      <InvoiceItemCRUDTable />
    </PageContainer>
  );
}
