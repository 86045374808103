import React from 'react';
import { Stack } from '@mui/material';
import * as yup from 'yup';
import useForm from '../hooks/useForm';
import InputField, { CompanySelect } from './InputField';
import FormButtons from './FormButtons';
import ClearFieldsButton from './ClearFieldsButton';
import { devLog, getData, getDocId, getFormikValues, setStoreDoc } from '../utils/utils';
import useStoreId from '../hooks/useStoreId';

const companyFormSchema = yup.object().shape({
  company: yup.string().required('Please enter the company name.'),
  email: yup.string().email('Please enter the valid email.'),
});

const checkInCompanyFormSchema = yup.object().shape({
  email: yup.string().email('Please enter the valid email.'),
});

const defCompanyData = {
  id: '',
  company: '',
  phoneNo: '',
  email: '',
  pic: '',
  address: '',
  city: '',
  province: '',
  postalCode: '',
  poNo: '',
  cardNo: '',
  cardHolder: '',
  cardExp: '',
  cardCvv: '',
  note: '',
};

const companyKeys = Object.keys(defCompanyData);

const CompanyForm = ({editData, editMode, onCloseDialog}) => {
  const storeId = useStoreId();
  const formik = useForm({
    editData: {...defCompanyData, ...getCompanyData(editData, false)},
    editMode,
    validationSchema: companyFormSchema,
    onSubmit: async (values) => {
      devLog('CompanyForm.onSubmit()', values);
      const docId = getDocId(editData, 'companyId');
      const data = getCompanyData(values, false);
      if (editMode && !docId) {
        throw new Error();
      }
      await setStoreDoc(storeId, 'companies', data, docId);
      if (editMode) {
        onCloseDialog?.();
      }
    },
    message: 'Company information has been saved successfully.',
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <CompanyFields formik={formik} minHeight={250} />
      <FormButtons formik={formik} label={'COMPANY'} editMode={editMode} onCloseDialog={onCloseDialog} />
    </form>
  );
};

export const CheckInCompanyForm = ({formik, minHeight = 400, hide = false}) => {
  return <CompanyFields formik={formik} minHeight={minHeight} useCompanySelector hide={hide} />
};

export function useCheckInCompanyForm(checkInData) {
  const {companyData = {}} = checkInData ?? {};
  const {id: companyId} = companyData;
  const editMode = Boolean(companyId);
  const formik = useForm({
    editData: {...defCompanyData, ...companyData},
    editMode,
    validationSchema: checkInCompanyFormSchema,
    onSubmit: async (values) => {
      devLog('CheckInCompanyForm.onSubmit()', companyId, companyData, values);
    },
    message: 'Company information has been saved successfully.',
  });
  return {
    formik,
    getData: () => getFormikValues(formik, defCompanyData, false),
  };
}

function getCompanyData(values, includeDocId) {
  const data = getData(values, companyKeys);
  if (!includeDocId) {
    return data;
  }
  const docId = getDocId(values, 'companyId');
  return [docId, data];
}

function resetCompanyFields(formik) {
  const touched = {};
  for (const key of companyKeys) {
    touched[key] = false;
  }
  formik.setTouched(touched, false);
  formik.setValues(defCompanyData);
}

const CompanyFields = ({formik, minHeight = 400, useCompanySelector = false, hide = false}) => {
  return (
    <Stack direction="column" spacing={2} pt={2} minHeight={minHeight} display={hide ? 'none' : 'flex'}>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="flex-start">
        {useCompanySelector && <CompanySelect  initialId={formik.values.id} onChange={(id, company) => {
          // console.log(company);
          if (!id || id === 'n/a') {
            resetCompanyFields(formik);
          } else {
            formik.setValues({...getCompanyData(company, false), companyId: id});
          }
        }}/>}
        {!useCompanySelector && <InputField name="company" label="Company" formik={formik} />}
        <InputField name="phoneNo" label="Phone No." formik={formik} />
        <InputField name="email" label="Email" formik={formik} />
        <ClearFieldsButton formik={formik} fields={['company', 'phoneNo', 'email']} />
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="flex-start">
        <InputField name="pic" label="Person In Charge" formik={formik} width={200} />
        <InputField name="poNo" label="PO No." formik={formik} width={200} />
        <ClearFieldsButton formik={formik} fields={['pic', 'poNo']} />
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="flex-start">
        <InputField name="address" label="Address" formik={formik} />
        <InputField name="city" label="City" formik={formik} width={150} />
        <InputField name="province" label="Province" formik={formik} width={100} />
        <InputField name="postalCode" label="Postal Code" formik={formik} width={100} />
        <ClearFieldsButton formik={formik} fields={['address', 'city', 'province', 'postalCode']} />
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="flex-start" mt={2}>
        <InputField name="cardNo" label="Card No." formik={formik} />
        <InputField name="cardHolder" label="Card Holder" formik={formik} />
        <InputField name="cardExp" label="Card Exp." formik={formik} width={100} />
        <InputField name="cardCvv" label="Card CVV" formik={formik} width={100} />
        <ClearFieldsButton formik={formik} fields={['cardNo', 'cardHolder', 'cardExp', 'cardCvv']} />
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="flex-start">
        <InputField name="note" label="Note" formik={formik} />
        <ClearFieldsButton formik={formik} fields={['note']} />
      </Stack>
    </Stack>
  );
};

export default CompanyForm;
