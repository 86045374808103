import Router from './techcube/routes';
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import useAuth from './techcube/hooks/useAuth';
import Settings from './techcube/components/settings';
import RtlLayout from './techcube/components/RtlLayout';
import ScrollToTop from './techcube/components/ScrollToTop';
import NotistackProvider from './techcube/components/NotistackProvider';
import ThemePrimaryColor from './techcube/components/ThemePrimaryColor';
import ThemeLocalization from './techcube/components/ThemeLocalization';
import { BaseOptionChartStyle } from './techcube/components/charts/BaseOptionChart';
import LoadingScreen, { ProgressBarStyle } from './techcube/components/LoadingScreen';

export default function App() {
  const { isInitialized, user } = useAuth();
  let loading;
  if (!isInitialized) {
    loading = true;
  } else {
    if (user?.id) {
      loading = !user.role;
    } else {
      loading = false;
    }
  }
  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <GlobalStyles />
              <ProgressBarStyle />
              <BaseOptionChartStyle />
              <Settings />
              <ScrollToTop />
              {!loading ? <Router /> : <LoadingScreen />}
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
