export const PET_FEE = 20;
export const RV_FEE = 50;
export const GST_RATE = 0.05;
export const ROOM_TAX_RATE = 0.08;

export const RATE_STANDARD = 'Standard';
export const RATE_WEEKLY = 'Weekly';
export const RATE_MONTHLY = 'Monthly';
export const RATE_CORPORATE = 'Corporate';

export const rateTypeOptions = [RATE_STANDARD, RATE_WEEKLY, RATE_MONTHLY, RATE_CORPORATE];

// export const paymentTypeOptions = [
//   { value: 'CARD', label: 'Credit Card' },
//   { value: 'DEBIT', label: 'Debit Card' },
//   { value: 'CASH', label: 'Cash' },
//   { value: 'CHEQUE', label: 'Cheque' },
// ];
//
// export const paymentStatusOptions = [
//   { value: 'pending', label: 'Pending' },
//   { value: 'paid', label: 'Paid' },
//   { value: 'unpaid', label: 'Unpaid' },
// ];

export const sexOptions = ['Male', 'Female'];

export const depositOptions = [
  { value: 'depositCredit', label: 'Credit' },
  { value: 'depositDebit', label: 'Debit' },
  { value: 'depositCash', label: 'Cash' },
  { value: 'depositCheque', label: 'Cheque' },
];

export const transactionOptions = [
  { value: 'paymentCredit', label: 'Payment (Credit)', shouldBeNegative: true, isPayment: true },
  { value: 'paymentDebit', label: 'Payment (Debit)', shouldBeNegative: true, isPayment: true },
  { value: 'paymentCash', label: 'Payment (Cash)', shouldBeNegative: true, isPayment: true },
  { value: 'paymentCheque', label: 'Payment (Cheque)', shouldBeNegative: true, isPayment: true },
  { value: 'depositCredit', label: 'Deposit (Credit)', shouldBeNegative: true, isPayment: true },
  { value: 'depositDebit', label: 'Deposit (Debit)', shouldBeNegative: true, isPayment: true },
  { value: 'depositCash', label: 'Deposit (Cash)', shouldBeNegative: true, isPayment: true },
  { value: 'depositCheque', label: 'Deposit (Cheque)', shouldBeNegative: true, isPayment: true },
  { value: 'priceAdjustment', label: 'Price Adjustment', shouldBeNegative: true },
  { value: 'discount', label: 'Discount', shouldBeNegative: true },
  { value: 'complimentary', label: 'Complimentary Sales', shouldBeNegative: true },
  { value: 'miscCharge', label: 'Misc. Charge' },
  { value: 'miscFee', label: 'Misc. Fee' },
];

export const salesOptions = [
  { value: 'roomFee', label: 'Room Fee' },
  { value: 'petFee', label: 'Pet Fee' },
  { value: 'rvFee', label: 'RV Fee' },
  { value: 'priceAdjustment', label: 'Price Adjustment', shouldBeNegative: true },
  { value: 'discount', label: 'Discount', shouldBeNegative: true },
  { value: 'complimentary', label: 'Complimentary Sales', shouldBeNegative: true },
  { value: 'miscCharge', label: 'Misc. Charge' },
  { value: 'miscFee', label: 'Misc. Fee' },
];

export const itemsCannotBeDeleted = ['roomFee', 'petFee', 'rvFee'];

export const itemsForAdjustment = ['priceAdjustment', 'discount', 'complimentary'];

export const statusOptions = [
  { value: 'pending', label: 'Pending' },
  { value: 'done', label: 'Done' },
  { value: 'cancelled', label: 'Cancelled' },
];

export const statusColors = {
  pending: 'warning',
  done: 'success',
  cancelled: 'error',
};

export const bedTypeOptions = [
  { value: 'Single', label: 'Single Bed' },
  { value: 'Queen', label: 'Queen Bed' },
];

export const houseKeepingOptions = [
  { value: 'V', label: 'Vacant' },
  { value: 'H', label: 'Housekeeping' },
  { value: 'M', label: 'Maintenance' },
  { value: 'O', label: 'Occupied' },
  { value: 'D', label: 'Due Out' },
  { value: 'B', label: 'Blocked' },
];

export const houseKeepingTextColors = {
  V: 'info.main',
  H: 'warning.main',
  M: 'error.main',
  O: 'success.main',
  D: 'primary.main',
  B: 'text.disabled',
  U: 'text.disabled',
};

export const houseKeepingButtonColors = {
  V: 'info',
  H: 'warning',
  M: 'error',
  O: 'success',
  D: 'primary',
  B: 'btnGrey',
  U: 'btnGrey',
};

export const salesReportOptions = [
  'Daily', 'Monthly', 'Quarterly', 'YTD', 'Annually'
];

export const monthOptions = [
  { value: 1, label: 'January' },
  { value: 2, label: 'February' },
  { value: 3, label: 'March' },
  { value: 4, label: 'April' },
  { value: 5, label: 'May' },
  { value: 6, label: 'June' },
  { value: 7, label: 'July' },
  { value: 8, label: 'August' },
  { value: 9, label: 'September' },
  { value: 10, label: 'October' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' },
];

export const yearOptions = (() => {
  const year = (new Date()).getFullYear();
  const options = [];
  for (let i = year; i >= 2020; --i) {
    options.push(i + '');
  }
  return options;
})();
