import useAuth from './useAuth';

export default function useStoreId() {
  const { user } = useAuth();
  return user?.storeId ?? '';
}

export function useMerchantId() {
  const { user } = useAuth();
  return user?.merchantId ?? '';
}
