import React from 'react';
import PageContainer from '../../components/PageContainer';
import CheckInListTable from '../../data-tables/CheckInListTable';

export default function BillingList() {
  return (
    <PageContainer title="Billing List">
      <CheckInListTable status={'checked-out'} paymentStatus={'unpaid'} />
    </PageContainer>
  );
}
