import React from 'react';
import PageContainer from '../../components/PageContainer';
import CheckInCRUDTable from '../../data-tables/CheckInCRUDTable';

export default function CheckInList() {
  return (
    <PageContainer title="Check-In List">
      <CheckInCRUDTable />
    </PageContainer>
  );
}
