import React from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';

export default function useStores() {
  const query = firebase.firestore().collection(`stores`).orderBy('title');
  const [snapshot, loading, error] = useCollection(query);
  if (error) {
    console.error(error);
  }
  const data = React.useMemo(() => {
    return snapshot?.docs.map(doc => ({...doc.data(), id: doc.id})) ?? [];
  }, [snapshot]);
  return [data, loading, error];
}

export function useStoreOptions() {
  const query = firebase.firestore().collection(`stores`).orderBy('title');
  const [snapshot, loading, error] = useCollection(query);
  if (error) {
    console.error(error);
  }
  const options = React.useMemo(() => {
    return snapshot?.docs.map((doc) => {
      return doc.data();
    }) ?? [];
  }, [snapshot]);
  return [options, loading, error];
}
