import React from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useMerchantId } from './useStoreId';

export default function usePaymentList(storeId, dateFrom, dateTo) {
  const [merchantId, setMerchantId] = React.useState();
  const db = firebase.firestore();
  let query =
    storeId && merchantId
      ? db.collection(`square-webhooks/${merchantId}/payments`).orderBy('created_at', 'desc')
      : null;
  if (query) {
    if (dateFrom) {
      query = query.where('created_at', '>=', dateFrom.toISOString());
    }
    if (dateTo) {
      query = query.where('created_at', '<=', dateTo.toISOString());
    }
  }
  React.useEffect(() => {
    if (storeId) {
      firebase
        .firestore()
        .doc(`stores/${storeId}`)
        .get()
        .then((doc) => {
          const { merchantId } = doc.data();
          setMerchantId(merchantId);
        })
        .catch(console.error);
    }
  }, [storeId]);
  return useCollection(query);
}

export function usePaymentListQuery([dateFrom, dateTo]) {
  const merchantId = useMerchantId();
  if (!merchantId) {
    return null;
  }
  const db = firebase.firestore();
  let query = db.collection(`square-webhooks/${merchantId}/payments`).orderBy('created_at', 'desc');
  if (dateFrom) {
    query = query.where('created_at', '>=', dateFrom.toISOString());
  }
  if (dateTo) {
    query = query.where('created_at', '<=', dateTo.toISOString());
  }
  return query;
}
