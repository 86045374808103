import React from 'react';
import { Button, Card, Stack } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useCollection } from 'react-firebase-hooks/firestore';
import firebase from 'firebase/app';
import 'firebase/functions';
import Confirm, { useConfirm } from '../components/Confirm';
import FormDialog, { useFormDialog } from '../forms/FormDialog';
import useStoreId from '../hooks/useStoreId';
import { mapDefColDef } from '../utils/utils';
import { DeleteButton, EditButton } from '../components/icon-buttons';

const FirestoreCRUDTable = React.forwardRef((props, ref) => {
  const {
    path,
    query,
    columns,
    title,
    labelKey,
    deleteKeys,
    deleteFunctionName,
    Form,
    hideAddButton,
    hideActionColumn,
    hideEditButton,
    hideDeleteButton,
    renderLeftHeader,
    renderRightHeader,
    renderActionButtons,
    formDialogMaxWidth = 'sm',
    renderFooter,
    placeActionColumnFirst,
    onEditOpen, // function(row: object): object
    onDeleteRow, // function(row: object): Promise
    actionColDef,
  } = props;
  const qry = path ? firebase.firestore().collection(path) : query ?? null;
  const [snapshot, loading, error] = useCollection(qry);
  const { confirmProps, openConfirm } = useConfirm();
  const { props: dialogProps, open: openDialog } = useFormDialog();
  const dataGridProps = useDataGridProps({
    columns,
    snapshot,
    loading,
    error,
    openConfirm,
    openDialog,
    title,
    labelKey,
    deleteKeys,
    deleteFunctionName,
    hideActionColumn,
    hideEditButton,
    hideDeleteButton,
    renderActionButtons,
    placeActionColumnFirst,
    onEditOpen,
    onDeleteRow,
    actionColDef,
  });
  React.useImperativeHandle(ref, () => {
    return {
      openFormDialog: openDialog,
    };
  }, [openDialog]);
  return (
    <Card sx={{ p: 1 }}>
      <Stack direction="row" justifyContent="space-between" spacing={2} p={1}>
        <Stack direction="row">{renderLeftHeader?.()}</Stack>
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          {renderRightHeader?.()}
          {!hideAddButton && (
            <Button variant="contained" onClick={() => openDialog()}>
              {typeof title === 'function' ? title({}, false) : `ADD ${title}`}
            </Button>
          )}
        </Stack>
      </Stack>
      <DataGrid {...dataGridProps} />
      <Confirm {...confirmProps} />
      <FormDialog {...dialogProps} maxWidth={formDialogMaxWidth} title={title} Form={Form} />
      {renderFooter?.()}
    </Card>
  );
});

function useDataGridProps({
  columns,
  snapshot,
  loading,
  error,
  openConfirm,
  openDialog,
  title,
  labelKey,
  deleteKeys,
  deleteFunctionName,
  hideActionColumn,
  hideEditButton,
  hideDeleteButton,
  renderActionButtons,
  placeActionColumnFirst,
  onDeleteRow,
  actionColDef,
}) {
  const storeId = useStoreId();
  function getColumns() {
    if (hideActionColumn) {
      return columns.map(mapDefColDef);
    }
    const actionColumn = (() => {
      return {
        field: 'actions',
        headerName: 'Actions',
        renderCell: ({ row }) => {
          const onEdit = () => {
            openDialog(row, true);
          };
          const onDelete = () => {
            openConfirm(
              `Are you sure you want to delete ${title} (${row[labelKey]})`,
              async () => {
                if (deleteFunctionName && deleteKeys) {
                  const data = { storeId };
                  // eslint-disable-next-line no-restricted-syntax
                  for (const key of deleteKeys) {
                    data[key] = row[key];
                  }
                  return firebase.functions().httpsCallable(deleteFunctionName)(data);
                }
                if (onDeleteRow) {
                  await onDeleteRow(row);
                }
                throw new Error('onDelete() not implemented!');
              },
              `${title} (${row[labelKey]}) has been deleted successfully!`
            );
          };
          return (
            <Stack direction="row" alignItems="center">
              {!hideEditButton && <EditButton onClick={onEdit} />}
              {!hideDeleteButton && <DeleteButton onClick={onDelete} />}
              {renderActionButtons?.(row)}
            </Stack>
          );
        },
        ...actionColDef,
      };
    })();
    if (placeActionColumnFirst) {
      return [actionColumn, ...columns].map(mapDefColDef);
    }
    return [...columns, actionColumn].map(mapDefColDef);
  }
  const rows = snapshot?.docs.map((doc) => ({...doc.data(), id: doc.id})) ?? [];
  if (error) {
    console.error(error);
  }
  return {
    columns: getColumns(),
    rows,
    loading,
    error,
    density: 'compact',
    autoHeight: true,
    rowsPerPageOptions: [60],
    pageSize: 60,
  };
}

export default FirestoreCRUDTable;
