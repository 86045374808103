const koLocales = {
  // ==========================================================================
  techcubePMS: 'TechCube PMS',
  pmsTitle: 'TechCube PMS - 자산 관리 시스템',
  // ==========================================================================
  login: '로그인',
  email: '이메일 주소',
  password: '패스워드',
  or: '또는',
  loginToPMS: 'TechCube PMS 로그인',
  loginWithGoogle: '구글 계정으로 로그인',
  loginSuccess: '로그인 되었습니다!',
  failedToLogin: 'TechCube PMS에 로그인할수 없습니다!',
  needValidEmail: '올바른 형식의 이메일 주소를 입력하세요',
  needEmail: '이메일 주소를 입력하세요',
  needPassword: '패스워드를 입력하세요',
  // ==========================================================================
  settings: '설정',
  mode: '모드',
  color: '색상',
  stretch: '화면에 채우기',
  fullscreen: '전체화면',
  exitFullscreen: '전체화면 나가기',
  // ==========================================================================
  last: '',
};

export default koLocales;
