import React from 'react';
import { Container } from '@mui/material';
import Page from './Page';
import useSettings from '../hooks/useSettings';

export default function PageContainer({ title, children }) {
  const { themeStretch } = useSettings();
  return (
    <Page title={`${title} | TechCube OneBook - Property Management System`}>
      <Container maxWidth={themeStretch ? false : 'xl'}>{children}</Container>
    </Page>
  );
}
