import React from 'react';
import {Box, Button, Card, Grid, Stack, Typography} from '@mui/material';
import PageContainer from '../../components/PageContainer';
import {StandaloneDateField} from '../../forms/DateField';
import dfn from '../../utils/dfn';
import SalesListTable from '../../data-tables/SalesListTable';
import PaymentsListTable from '../../data-tables/PaymentsListTable';
import useAuth from '../../hooks/useAuth';
import {buildAllCarryOverData, formatCurrency, storeColRef, storeDocRef} from '../../utils/utils';
import {NextButton, PrevButton} from '../../components/icon-buttons';
import {addDays, format, subDays, subYears} from 'date-fns';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {salesOptions, transactionOptions} from '../../utils/options';

export default function Reconciliation() {
  const {user} = useAuth();
  const dateStartedStr = dfn.format(user['dateStarted']);
  const [date, setDate] = React.useState(new Date());
  const [processing, setProcessing] = React.useState(false);
  const [coData, setCoData] = React.useState();
  const dateStr = dfn.format(date);
  React.useEffect(() => {
    if (user.storeId) {
      (async () => {
        const doc = await storeDocRef(user.storeId, 'carry-over-amounts', dateStr).get();
        setCoData(doc.data());
      })();
    }
  }, [user.storeId, dateStr]);
  // async function calcBalance() {
  //   await buildCarryOverDataOfDate(user.storeId);
  //   const doc = await storeDocRef(user.storeId, 'carry-over-amounts', dateStr).get();
  //   setCoData(doc.data());
  // }
  async function processDailyClosing() {
    try {
      setProcessing(true);
      const lastYearDate = subYears(new Date(), 1);
      const dateStarted = user['dateStarted']?.toDate() ?? lastYearDate;
      const fromDate = dateStarted > lastYearDate ? dateStarted : lastYearDate;
      await buildAllCarryOverData(fromDate, user['initialCarryOverAmount'] ?? 0);
    } finally {
      setProcessing(false);
    }
  }
  return (
    <PageContainer title="Booking Status">
      <Grid container spacing={2}>
        <Grid item sm={9}>
          <Stack direction={'row'} spacing={2} flexWrap={'wrap'}>
            <StandaloneDateField label={'Date'} value={date} onChange={value => setDate(value)} width={250}/>
            <PrevButton onClick={() => setDate(subDays(date, 1))}/>
            <Button onClick={() => setDate(new Date())}>Today</Button>
            <NextButton onClick={() => setDate(addDays(date, 1))}/>
            <Button variant={'contained'} startIcon={<FileDownloadOutlinedIcon />} onClick={() => exportAsPDF(user, dateStr, coData)}>PDF DOWNLOAD</Button>
          </Stack>
        </Grid>
        <Grid item sm={3} sx={{justifyContent: 'flex-end'}}>
          {/*{user.role === 'super' && <Button variant={'contained'} fullWidth onClick={() => buildAllRoomRateInfo(user.storeId)}>BUILD ALL SALES / PAYMENTS DATA</Button>}*/}
          {/*{user.role === 'super' && <Button variant={'contained'} fullWidth onClick={() => buildAllCarryOverData(user.storeId)}>BUILD ALL CARRY OVER DATA</Button>}*/}
        </Grid>
        <Grid item sm={12} md={7}>
          <Card sx={{p: 2}}>
            <Typography variant={'h5'} color={'success.main'} sx={{p: 2}}>
              SALES ({dateStr})
            </Typography>
            <SalesListTable dateStr={dateStr} />
          </Card>
        </Grid>
        <Grid item sm={12} md={5}>
          <Stack direction={'column'} spacing={2}>
            <Card sx={{p: 2}}>
              <Typography variant={'h5'} color={'warning.main'} sx={{p: 2}}>
                PAYMENTS ({dateStr})
              </Typography>
              <PaymentsListTable dateStr={dateStr} />
            </Card>
            <Card sx={{p: 2}}>
              <Typography variant={'h5'} color={'primary.main'} sx={{p: 2}}>
                BALANCE ({dateStr})
                {dateStr >= dateStartedStr && coData && (
                  <Stack direction={'column'} alignItems={'flex-end'} spacing={1} mt={2}>
                    <Typography variant={'overline'} sx={{color: 'text.disabled'}}>Total Sales: <CurrencyLabel amount={coData['salesAmount']} /></Typography>
                    <Typography variant={'overline'} sx={{color: 'text.disabled'}}>Total Payment: <CurrencyLabel amount={coData['paymentAmount']} /></Typography>
                    <Typography variant={'overline'} sx={{color: 'text.disabled'}}>Balance Of Day: <CurrencyLabel amount={coData['dayBalance']} /></Typography>
                    <Typography variant={'overline'} sx={{color: 'text.disabled'}}>Carry Over: <CurrencyLabel amount={coData['carryOver']} /></Typography>
                    <Typography variant={'overline'} sx={{ fontSize: '1rem', color: 'primary.main'}}>Balance: <CurrencyLabel amount={coData['balance']} /></Typography>
                  </Stack>
                )}
                {(user.role === 'super' || user.role === 'owner') && (
                  <Stack direction={'column'} alignItems={'center'} justifyContent={'center'} spacing={1} mt={2} p={2}>
                    <Typography variant={'body2'} sx={{color: 'text.disabled', textAlign: 'center', mb: 2}}>
                      Daily closing process will be done automatically at 01:30 AM every day. However, it can be done manually by clicking the button below. Feel free to process daily closing by clicking the button down below whenever sales or payment data is changed.
                    </Typography>
                    <Button variant={'contained'} onClick={() => processDailyClosing()} size={'large'} disabled={processing}>DAILY CLOSING</Button>
                  </Stack>
                )}
              </Typography>
            </Card>
          </Stack>
        </Grid>
      </Grid>
    </PageContainer>
  );
}

function CurrencyLabel({amount}) {
  return <Box display={'inline-block'} textAlign={'right'} width={'120px'}>{formatCurrency(amount)}</Box>;
}

async function exportAsPDF(user, dateStr, coData) {
  if (!(user?.storeId && dateStr)) {
    return;
  }
  const reportDate = Date.parse(dateStr + 'T00:00:00');
  const fontSize = 8.5;
  const cellWidth = 26;
  const headStyles = {halign: 'center', valign: 'middle', fontSize};
  const titleStyles = {halign: 'center', valign: 'middle', fontSize};
  const valueStyles = {halign: 'right', valign: 'middle', cellWidth, fontSize};
  const paymentValueStyles = {halign: 'right', valign: 'middle', fontSize};
  const salesDocs = await storeColRef(user.storeId, 'sales')?.where('dateStr', '==', dateStr).orderBy('roomNo').get();
  const paymentsDocs = await storeColRef(user.storeId, 'payments')?.where('dateStr', '==', dateStr).orderBy('roomNo').get();
  const salesHead = ['Room No', 'Item', 'Net', 'GST', 'Room Tax', 'Gross'].map((content) => ({content, styles: headStyles}));
  const salesBody = [];
  const salesTotal = {net: 0, gst: 0, roomTax: 0, gross: 0};
  salesDocs.forEach((doc) => {
    const {roomNo, itemCode, net, gst, roomTax, gross} = doc.data();
    const row = [roomNo, itemCode, net, gst, roomTax, gross].map((content, index) => {
      if (index === 1) {
        content = salesOptions.find((i) => i.value === content).label;
      }
      return {content: index > 1 ? formatCurrency(content) : content, styles: index <= 1 ? titleStyles : valueStyles};
    });
    salesTotal.net += net;
    salesTotal.gst += gst;
    salesTotal.roomTax += roomTax;
    salesTotal.gross += gross;
    salesBody.push(row);
  });
  const paymentsHead = ['Room No', 'Item', 'Amount'].map((content) => ({content, styles: headStyles}));
  const paymentsBody = [];
  let credit = 0, debit = 0, cash = 0, cheque = 0, paymentTotal = 0;
  paymentsDocs.forEach((doc) => {
    const {roomNo, itemCode, amount} = doc.data();
    const row = [roomNo, itemCode, amount].map((content, index) => {
      if (index === 1) {
        content = transactionOptions.find((i) => i.value === content).label;
      }
      return {content: index > 1 ? formatCurrency(content) : content, styles: index <= 1 ? titleStyles : paymentValueStyles};
    });
    if (itemCode.endsWith('Credit')) {
      credit += amount;
    } else if (itemCode.endsWith('Debit')) {
      debit += amount;
    } else if (itemCode.endsWith('Cash')) {
      cash += amount;
    } else if (itemCode.endsWith('Cheque')) {
      cheque += amount;
    }
    paymentTotal += amount;
    paymentsBody.push(row);
  });
  const paymentsTotal = {credit, debit, cash, cheque, total: paymentTotal};
  const doc = new jsPDF({orientation: 'p'});
  let startY = 20;
  let startX = 14;
  const pageCenterX = doc.internal.pageSize.getWidth() / 2;
  const pageRightX = doc.internal.pageSize.getWidth() - startX - 2;
  doc.setFontSize(15);
  doc.text(`${user.title} Reconciliation`, pageCenterX, startY, {align: 'center'});
  startY += 5.5;
  doc.setFontSize(12);
  doc.text(format(reportDate, 'LLLL d, yyyy'), pageCenterX, startY, {align: 'center'});
  startY += 10;
  doc.setFontSize(11);
  doc.text(`Total Sales : ${formatCurrency(coData?.salesAmount??0)}`, pageRightX, startY, {align: 'right'});
  startY += 6;
  doc.text(`Total Payment : ${formatCurrency(coData?.paymentAmount??0)}`, pageRightX, startY, {align: 'right'});
  startY += 6;
  doc.text(`Carry Over : ${formatCurrency(coData?.carryOver??0)}`, pageRightX, startY, {align: 'right'});
  startY += 6;
  doc.text(`Balance : ${formatCurrency(coData?.balance??0)}`, pageRightX, startY, {align: 'right'});
  startY += 6;
  doc.setFontSize(11);
  doc.text(`Sales List (${format(reportDate, 'yyyy-MM-dd')})`, startX, startY);
  startY += 3;
  const salesTableOptions = {
    startY,
    head: [salesHead],
    body: salesBody,
  };
  doc.autoTable(salesTableOptions);
  startY = doc.lastAutoTable.finalY + 6;
  doc.setFontSize(9);
  doc.text(`Total Net : ${formatCurrency(salesTotal.net)}`, pageRightX, startY, {align: 'right'});
  startY += 5;
  doc.text(`Total GST : ${formatCurrency(salesTotal.gst)}`, pageRightX, startY, {align: 'right'});
  startY += 5;
  doc.text(`Total Room Tax : ${formatCurrency(salesTotal.roomTax)}`, pageRightX, startY, {align: 'right'});
  startY += 6;
  doc.setFontSize(11);
  doc.text(`Total Gross : ${formatCurrency(salesTotal.gross)}`, pageRightX, startY, {align: 'right'});
  startY += 10;
  doc.setFontSize(11);
  doc.text(`Payments List (${format(reportDate, 'yyyy-MM-dd')})`, startX, startY);
  startY += 3;
  const paymentsTableOptions = {
    startY,
    head: [paymentsHead],
    body: paymentsBody,
  };
  doc.autoTable(paymentsTableOptions);
  startY = doc.lastAutoTable.finalY + 6;
  doc.setFontSize(9);
  doc.text(`Total Credit : ${formatCurrency(paymentsTotal.credit)}`, pageRightX, startY, {align: 'right'});
  startY += 5;
  doc.text(`Total Debit : ${formatCurrency(paymentsTotal.debit)}`, pageRightX, startY, {align: 'right'});
  startY += 5;
  doc.text(`Total Cash : ${formatCurrency(paymentsTotal.cash)}`, pageRightX, startY, {align: 'right'});
  startY += 5;
  doc.text(`Total Cheque : ${formatCurrency(paymentsTotal.cheque)}`, pageRightX, startY, {align: 'right'});
  startY += 6;
  doc.setFontSize(11);
  doc.text(`Total Payment : ${formatCurrency(paymentsTotal.total)}`, pageRightX, startY, {align: 'right'});
  doc.save(`${user.title}-reconciliation-${format(reportDate, 'yyyyMMdd')}.pdf`);
}
