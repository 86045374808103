import React from 'react';
import { Box, Card, Stack } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import { defMapRows, mapDefColDef } from '../utils/utils';

const FirestoreListTable = (
  {
    path,
    query,
    columns,
    mapRows = defMapRows,
    renderLeftHeader,
    renderRightHeader,
    renderFooter,
    doNotUseCard,
    containerSx,
    hideFooter,
    disableSelectionOnClick,
    ...otherGridProps
  }
) => {
  query = path ? firebase.firestore().collection(path) : query ?? null;
  const [snapshot, loading, error] = useCollection(query);
  const rows = mapRows(snapshot);
  const gridProps = {
    columns: columns.map(mapDefColDef),
    rows,
    loading,
    error,
    density: 'compact',
    autoHeight: true,
    rowsPerPageOptions: [60],
    pageSize: 60,
    hideFooter,
    disableSelectionOnClick,
    ...otherGridProps,
  };
  if (error) {
    console.error(error);
  }
  if (doNotUseCard) {
    return (
      <Box sx={containerSx}>
        {(renderLeftHeader || renderRightHeader) && (
          <Stack direction="row" justifyContent="space-between" spacing={2} p={1}>
            <Stack direction="row">{renderLeftHeader?.(rows)}</Stack>
            <Stack direction="row" justifyContent="flex-end">{renderRightHeader?.(rows)}</Stack>
          </Stack>
        )}
        <DataGrid {...gridProps} />
        {renderFooter?.(rows)}
      </Box>
    );
  }
  return (
    <Card sx={containerSx ?? { p: 1 }}>
      {(renderLeftHeader || renderRightHeader) && (
        <Stack direction="row" justifyContent="space-between" spacing={2} p={1}>
          <Stack direction="row">{renderLeftHeader?.(rows)}</Stack>
          <Stack direction="row" justifyContent="flex-end">{renderRightHeader?.(rows)}</Stack>
        </Stack>
      )}
      <DataGrid {...gridProps} />
      {renderFooter?.(rows)}
    </Card>
  );
};

export default FirestoreListTable;
