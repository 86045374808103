import React from 'react';
import {Box, Button, Card, CircularProgress, Grid, Stack, Tooltip, Typography, useMediaQuery} from '@mui/material';
import {Icon} from '@iconify/react';
import bellIcon from '@iconify/icons-eva/bell-fill';
import {useTheme} from '@mui/styles';
import {endOfDay, isAfter, isBefore, startOfDay} from 'date-fns';
import PageContainer from '../../components/PageContainer';
import useRoomList from '../../hooks/useRoomList';
// import useCheckInList from '../../hooks/useCheckInList';
// import {devLog, updateHousekeepingStatus} from '../../utils/utils';
import useHouseKeepingList from '../../hooks/useHouseKeepingList';
import FormDialog, {useFormDialog} from '../../forms/FormDialog';
import HouseKeepingForm from '../../forms/HouseKeepingForm';
// import useStoreId from '../../hooks/useStoreId';
import {houseKeepingButtonColors, houseKeepingOptions, houseKeepingTextColors} from '../../utils/options';
import useRoomTypeList from '../../hooks/useRoomTypeList';

export default function HouseKeeping() {
  const theme = useTheme();
  const [rooms = [], roomLoading, roomError] = useRoomList();
  const [roomTypes, /* roomTypesLoading */, roomTypesError] = useRoomTypeList();
  // const [checkIns = [], checkInLoading, checkInError] = useCheckInList();
  const [houseKeepingList, houseKeepingLoading, houseKeepingError] = useHouseKeepingList();
  const {props: houseKeepingFormProps, open: openHouseKeepingForm} = useFormDialog();
  if (roomError) { console.error(roomError) }
  // if (checkInError) { console.error(checkInError) }
  if (houseKeepingError) { console.error(houseKeepingError) }
  if (roomTypesError) { console.error(roomTypesError) }
  // const storeId = useStoreId();
  // React.useEffect(() => {
  //   const checkOutRooms = getCheckOutRooms(checkIns);
  //   if (checkOutRooms?.length > 0) {
  //     const now = new Date();
  //     let date11am = new Date();
  //     date11am.setHours(11, 0, 0, 0);
  //     const isAfter11am = now >= date11am;
  //     const status = isAfter11am ? 'H' : 'D';
  //     updateHousekeepingStatus(storeId, checkOutRooms, status).catch(console.error);
  //     devLog('housekeeping status changed!', checkOutRooms, 'status =>', status);
  //   }
  // }, [storeId, checkIns]);
  function getHouseKeepingData(roomNo) { return houseKeepingList.find(i => i.roomNo === roomNo) }
  const isLessThanSm = useMediaQuery(theme.breakpoints.down('sm'));
  const roomsListOfType = getRoomsListOfType(roomTypes, rooms, isLessThanSm ? 2 : 3);
  // const loading = roomLoading || checkInLoading || houseKeepingLoading;
  const loading = roomLoading || houseKeepingLoading;
  return (
    <PageContainer title="Room Status">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {!loading && (
            <Stack flexWrap={'wrap'} direction={'row'} justifyContent={'center'} spacing={2} mt={2} mb={1}>
              {houseKeepingOptions.map(({value, label}) => {
                return <Typography key={value} variant={'overline'} color={houseKeepingTextColors[value]}>{label}</Typography>;
              })}
            </Stack>
          )}
        </Grid>
        {roomsListOfType.map((roomsList, index) => {
          return (
            <Grid item xs={12} md={6} key={index}>
              <Card sx={{p: 2}}>
                {loading && (
                  <Stack alignItems={'center'} justifyContent={'center'} minHeight={300}>
                    <CircularProgress />
                  </Stack>
                )}
                {!loading && roomsList.map((rooms, index) => {
                  const buttonWidth = '150px';
                  return (
                    <Stack direction={'column'} key={index} sx={{mt: 1}}>
                      <Stack direction={'row'} justifyContent={'center'} flexWrap={'nowrap'} spacing={1}>
                        {rooms.map((room, index) => {
                          if (room.roomType === 'empty') {
                            return <Box key={`${room.roomType}-${index}`} sx={{width: buttonWidth, minWidth: buttonWidth, height: '80px'}} />;
                          }
                          const houseKeepingData = getHouseKeepingData(room.roomNo);
                          const latestNote = getLatestNote(houseKeepingData);
                          if (latestNote) {
                            return (
                              <Tooltip title={latestNote} arrow key={room.roomNo}>
                                <Button
                                  variant={'contained'}
                                  color={houseKeepingButtonColors[houseKeepingData?.statusCode ?? 'U']}
                                  sx={{width: buttonWidth, minWidth: buttonWidth, height: '80px'}}
                                  onClick={() => openHouseKeepingForm(houseKeepingData, true)}
                                >
                                  <Stack direction={'column'}>
                                    <span>{room.roomNo} ({room.roomType})</span>
                                    <Stack direction={'row'} spacing={1} alignItems={'center'} justifyContent={'center'}>
                                      <Icon icon={bellIcon} width={14} height={14} />
                                      <span>{houseKeepingData['statusLabel']}</span>
                                    </Stack>
                                  </Stack>
                                </Button>
                              </Tooltip>
                            );
                          } else {
                            return (
                              <Button
                                key={room.roomNo}
                                variant={'contained'}
                                color={houseKeepingButtonColors[houseKeepingData?.statusCode ?? 'U']}
                                sx={{width: buttonWidth, minWidth: buttonWidth, height: '80px'}}
                                onClick={() => openHouseKeepingForm(houseKeepingData, true)}
                              >
                                {room.roomNo} ({room.roomType})
                                {houseKeepingData && <br/>}
                                {houseKeepingData && houseKeepingData['statusLabel']}
                              </Button>
                            );
                          }
                        })}
                      </Stack>
                    </Stack>
                  );
                })}
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <FormDialog {...houseKeepingFormProps} maxWidth={'sm'} Form={HouseKeepingForm} title={({roomNo}) => `ROOM#${roomNo}`} />
    </PageContainer>
  );
}

/**
 * 오늘 체크인하는 룸 목록을 반환함
 */
// function getCheckInRooms(checkIns) {
//   // 오늘 체크인하는 룸 목록
//   const today = new Date();
//   return checkIns
//     .filter(({checkIn}) => {
//       const startDate = startOfDay(checkIn);
//       const endDate = endOfDay(checkIn);
//       return isAfter(today, startDate) && isBefore(today, endDate);
//     })
//     .map(({roomNo}) => roomNo);
// }

/**
 * 오늘 체크아웃하는 룸 목록을 반환함
 */
// function getCheckOutRooms(checkIns) {
//   // 오늘 체크인하는 룸 목록
//   const today = new Date();
//   return checkIns
//     .filter(({checkOut}) => {
//       const startDate = startOfDay(checkOut);
//       const endDate = endOfDay(checkOut);
//       return isAfter(today, startDate) && isBefore(today, endDate);
//     })
//     .map(({roomNo}) => roomNo);
// }

/**
 * [room1, room2, room3, ...]
 * =>
 * [
 *   [room1, room2, ...],
 *   [room1, room2, ...],
 *   [room1, room2, ...],
 * ]
 */
function getRoomsList(rooms, roomCount) {
  const roomsList = [];
  let items;
  for (let i = 0; i < rooms.length; ++i) {
    if (i % roomCount === 0) {
      if (items) {
        roomsList.push(items);
      }
      items = [];
    }
    items.push(rooms[i]);
  }
  if (items) {
    const emptyRooms = [];
    for (let i = 0; i < roomCount - items.length; ++i) {
      emptyRooms.push({roomType: 'empty'});
    }
    roomsList.push([...items, ...emptyRooms]);
  }
  return roomsList;
}

export function getRoomsListOfType(roomTypes, rooms, roomCount) {
  const roomsListOfType = [];
  for (const {value} of roomTypes) {
    roomsListOfType.push(getRoomsList(rooms.filter(i => i.roomType === value), roomCount));
  }
  return roomsListOfType.sort((l, r) => r.length - l.length);
}

function getLatestNote(data) {
  const {note} = data ?? {};
  if (Array.isArray(note)) {
    if (note.length <= 0) {
      return undefined;
    }
    const {name, message} = note[note.length - 1];
    return `${message}${name ? ` (${name})` : ''}`;
  }
  return note;
}
